import React, { useCallback } from 'react';
import useGetUserProgressByUserId from 'hooks/queries/useGetUserProgressByUserId';
import { getCompletedMakesCount } from 'utils/helpers';
import { canSeeUnpublishedMakes } from 'routes/auth/constants';

const Progress = ({ item, itemKey, topicRoute, children, makesAmount, userId }) => {
  //I changed the logic to completed the whole Make. Uploading a Make doesnt have an effect on the progress. Commenting out the old logic.

  const { _id: id, totalPublishedMakes = 0, totalUnPublishedMakes = 0 } = item;

  const { userProgresses } = useGetUserProgressByUserId(userId);
  const getCompletedCount = useCallback(
    (key, value) => {
      if (userProgresses) return getCompletedMakesCount(userProgresses, key, value);
      return 0;
    },
    [userProgresses]
  );
  const completedCount = getCompletedCount(itemKey, item._id);
  // const progress = completedCount ? (completedCount / makesAmount) * 100 : 0;

  const progress = completedCount
    ? (completedCount /
        (totalPublishedMakes + (canSeeUnpublishedMakes ? totalUnPublishedMakes : 0))) *
      100
    : 0;

  return children({
    progress,
  });
};

export default Progress;
