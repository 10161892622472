import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';

export const LvlUpContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  color: ${({ color }) => color};
  z-index: 999;
  ::before {
    content: '';
    position: absolute;
    background: ${({ background }) => background};
    z-index: -1;
    opacity: 0.8;
    height: 100%;
    width: 100%;
  }
`;

export const SecondaryText = styled.span`
  display: block;
  z-index: 500;
  opacity: 1;
  font-family: ${primaryFont.bold};
  font-size: 2.5rem;
  text-align: center;
`;
export const PrimaryText = styled.span`
  font-family: ${primaryFont.bold};
  font-size: 5.1rem;
  line-height: 0.75;
`;

export const MedalContainer = styled.div`
  position: relative;
  margin: ${({ isMobile }) => (isMobile ? '3.4rem 0 2.4rem 0' : '5rem 0 4rem 0')};
`;
export const MedalTag = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
`;
export const BackgroundImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  object-fit: cover;
  opacity: ${({ bgImageOpacity }) => bgImageOpacity};
  z-index: -1;
  left: 0;
`;

export const CongratsText = styled.div`
  margin-top: 5.5rem;
`;

export const Level = styled.span`
  position: absolute;
  font-size: 2.5rem;
  font-family: ${primaryFont.bold};
  left: 50%;
  top: 55.5%;
  white-space: nowrap;
  transform: translateX(-50%);
`;

export const RewardWrapper = styled.div`
  display: flex;
`;
export const RewardContainer = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PointsWon = styled.span`
  display: block;
  font-size: 3.4rem;
  font-family: ${primaryFont.bold};
  line-height: 0.75;
`;

export const Bonus = styled.span`
  display: block;
  font-size: 1.1rem;
  font-family: ${primaryFont.bold};
`;

export const ClaimButton = styled.button`
  color: ${({ color }) => color};
  width: 28.5rem;
  height: 5rem;
  background: var(--color-green);
  font-size: 2rem;
  padding: 1rem;
  border-radius: 1rem;
  font-family: ${primaryFont.bold};
  margin-top: 2.1rem;
`;

export const RankIcon = styled.img`
  width: 330px;
  height: 350px;
`;
