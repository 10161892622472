import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 1.5rem;
  @media ${device.tablet} {
    row-gap: 2.5rem;
  }
`;
