import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const TeaserWrapper = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  flex: 1;
  video {
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  @media ${device.tablet} {
    flex: 1;
    height: 100%;
  }
`;

export const Image = styled.img`
  min-height: 100%;
  width: 100%;
  aspect-ratio: 1/1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ playing }) => (playing ? -1 : 'initial')};
  object-fit: cover;
`;

export const FallBackImage = styled.img`
  min-height: 100%;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
`;
