import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const VideoGuide = styled.button`
  cursor: pointer;
  display: flex;
  column-gap: 0.5rem;
`;
export const Icon = styled.img`
  width: 3.5rem;
  height: 3.5rem;

  @media ${device.tablet} {
    width: inherit;
    height: inherit;
  }
`;
export const Text = styled.p`
  color: var(--color-white);
  background-color: var(--color-charcoal-grey);
  border-radius: 10px;
  font-size: 11px;
  display: block;
  padding: 0 1rem;
  align-self: center;
  text-align: center;
  line-height: 2;

  @media ${device.tablet} {
    font-size: 17px;
  }
`;
