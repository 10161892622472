import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';

export const Link = styled(NavLink)`
  color: var(--color-black);
  font-size: 1.4rem;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
  @media ${device.tablet} {
    font-size: 1.8rem;
  }
`;
export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  scroll-behavior: smooth;
  a {
    font-family: ${primaryFont.regular};
  }
  &::-webkit-scrollbar {
    display: none;
  }
  .selected {
    white-space: nowrap;
    ${Link} {
      color: var(--color-red);
      cursor: default;
      transition: all 0.1s ease-in-out;
    }
  }
`;
