import * as S from './ContentCreator.style';
const ContentCreator = ({ contentCreator }) => {
  return (
    <S.ContentCreator
      image={contentCreator.icon}
      src={contentCreator.icon}
      alt={contentCreator.name}
    ></S.ContentCreator>
  );
};

export default ContentCreator;
