export const GET_QUIZ_MAKES = (withTopicAndTrackData = false) =>
  `quizmake/getAllMakes?withTopicAndTrackData=${withTopicAndTrackData}`;

export const UPDATE_QUIZ_MAKE = (makeId) => `quizmake/make/${makeId}/update`;
export const GET_QUIZ_COMPONENTS_BY_MAKE = (makeId) =>
  `quizmake/make/${makeId}/components`;
export const DELETE_QUIZ_MAKE = (makeId, originalMakeId) =>
  `quizmake/make/${makeId}/originalMake/${originalMakeId}/delete`;
export const CREATE_QUIZ_COMPONENT = (makeId) => `quizmake/${makeId}/components/create`;
export const UPDATE_QUIZ_COMPONENT_INDEX = (makeId) =>
  `quizmake/make/${makeId}/updateIndex`;
export const UPDATE_QUIZ_COMPONENT = (componentId) =>
  `quizmake/components/${componentId}/update`;
export const CREATE_QUIZ_MAKE = (makeId, topicId, trackId) =>
  `quizmake/createNewMake/make/${makeId}/topic/${topicId}/track/${trackId}`;
export const DELETE_QUIZ_COMPONENT = (makeId, componentId) =>
  `quizmake/make/${makeId}/component/${componentId}/delete`;
