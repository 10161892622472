/* eslint-disable no-undef */

import React from 'react';

import { init } from 'apis/index';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'services/redux/store';
import GlobalStyle from 'styles/colors';
import * as Sentry from '@sentry/react';

import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initSentry } from './utils/helpers/errorHelper';
import './index.css';
import { ErrorProvider } from 'context/errorContext';
import OOPS_MSG from 'components/OOPS_MSG/OOPS_MSG';
// import 'swiper/swiper.min.css';
init();
initSentry();

// !enable mocking service worker in development
// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./test/server/browser');
//   worker.start();
//   worker.printHandlers();
// }
const ProfiledApp = Sentry.withProfiler(App);
ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={({ resetError, componentStack }) => {
        return <OOPS_MSG resetErrorCB={resetError} />;
      }}
    >
      <Provider store={store}>
        <ErrorProvider>
          <GlobalStyle />
          <ProfiledApp />
        </ErrorProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an actions endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
