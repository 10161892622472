import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';
import { skeletonLoader } from '../Skeleton.style';

export const NavBarContainer = styled.div`
  display: flex;
  direction: rtl;
  width: 100%;
  align-items: center;
  overflow-x: hidden;
  justify-content: unset;
  margin: 1rem 2rem 1rem 0;
  @media ${device.tablet} {
    justify-content: center;
    margin: 0;
  }
`;

export const NavBarWrapper = styled.ul`
  display: flex;
  overflow-y: hidden;
  column-gap: 1rem;

  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TrackContainer = styled.li`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const TrackName = styled.span`
  font-size: 1.1rem;
  margin-top: 0.2rem;
  @media ${device.tablet} {
    white-space: nowrap;
    margin-top: 1rem;
  }
`;
export const TrackIcon = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  visibility: hidden;
  @media ${device.tablet} {
    width: 3.2rem;
  }
`;
export const TrackIconWrapper = styled.div`
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  @media ${device.tablet} {
    width: 7rem;
    height: 7rem;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${skeletonLoader} 1s linear infinite;
`;
