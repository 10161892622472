import { Swiper } from 'swiper/react';
const Carousel = ({ children, settings, onSlideChange, setSwiper }) => {
  //TODO choose a different ewvent handler it is glitchy
  return (
    <Swiper
      onScrollBar={(swiper) => console.log('scrolling')}
      {...settings}
      id="main"
      onSlideChange={(swiper) => onSlideChange(swiper)}
      onSwiper={(swiper) => setSwiper(swiper)}
    >
      {children}
    </Swiper>
  );
};
export default Carousel;
