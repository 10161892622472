import { useErrorContext } from 'context/errorContext';
import { useEffect } from 'react';
import { useUpdateUserXpMutation } from 'services/api/makesApi/makesApi';
const useUpdateUserXp = () => {
  const [updateUserXp, { error: updateXpError }] = useUpdateUserXpMutation();

  const { handleError } = useErrorContext();
  useEffect(() => {
    if (updateXpError) handleError(updateXpError);
  }, [updateXpError]);
  return { updateUserXp };
};

export default useUpdateUserXp;
