import { useEffect } from 'react';
import { useErrorContext } from 'context/errorContext';
import { useGetStudentCompletedMakesQuery } from 'services/api/makesApi/makesApi';
const useGetStudentCompletedMake = (spaceId, studentId) => {
  const { handleError } = useErrorContext();
  const {
    data,
    error: useGetStudentCompletedMakeError,
    isLoading,
  } = useGetStudentCompletedMakesQuery(
    { spaceId, studentId },
    {
      skip: !spaceId || !studentId,
    }
  );
  useEffect(() => {
    if (useGetStudentCompletedMakeError) handleError(useGetStudentCompletedMakeError);
  }, [useGetStudentCompletedMakeError]);

  return { data, isLoading };
};

export default useGetStudentCompletedMake;
