import React, { useState, useEffect } from 'react';

export default function useShowAlert() {
  const [active, setActive] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ title: '', text: '' });
  const [alertTime, setAlertTime] = useState(3000);

  useEffect(() => {
    let timeOut;
    if (active) {
      timeOut = setTimeout(() => {
        setActive(false);
      }, alertTime);
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [active]);

  const activeAlert = React.useCallback((msg, time) => {
    if (msg) setAlertMessage(msg);
    if (time) setAlertTime(time);
    setActive(true);
  }, []);

  return { active, alertMessage, activeAlert };
}
