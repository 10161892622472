import * as S from './circularProgressBar.styles';

const CircularProgressBar = ({
  children,
  isSelected,
  sqSize,
  percentage = 0,
  strokeWidth,
  ariaValueText = `התקדמת ${percentage}%`,
}) => {
  const radius = (sqSize - strokeWidth) / 2;
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <S.CircularProgressBarWrapper
      role="progressbar"
      aria-valuenow={percentage}
      aria-valuemin="0"
      aria-valuemax="100"
      aria-valuetext={ariaValueText}
      aria-hidden="true"
    >
      <S.CircularProgressBarSvg
        isSelected={isSelected}
        width={sqSize}
        height={sqSize}
        viewBox={viewBox}
      >
        <S.CircularProgressBarBackground
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <S.CircularProgressBarProgress
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={strokeWidth}
          sqSize={sqSize}
          dashArray={dashArray}
          dashOffset={dashOffset}
        />
      </S.CircularProgressBarSvg>
      <S.ChildrenWrapper>{children}</S.ChildrenWrapper>
    </S.CircularProgressBarWrapper>
  );
};

export default CircularProgressBar;
