import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const Container = styled.div`
  background: var(--color-background);
  display: flex;
  height: 100%;
  width: 100%;
  margin: unset;
  flex-direction: column;
  overflow: hidden;
  @media ${device.tablet} {
    /* overflow-y: auto; */
    overflow: unset;
  }
`;
export const DiscoveryPageHeader = styled.div`
  position: ${({ isMobile }) => isMobile && 'sticky'};
  top: 0;
  background: var(--color-background);
  z-index: 100;
  @media ${device.tablet} {
    padding: 0 10%;

    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }
`;

export const ChildrenWrapper = styled.main`
  flex: 1;
`;

export const FooterContainer = styled.main`
  padding: 0 10%;
`;
