import styled from 'styled-components/macro';

export const CircularProgressBarWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
`;

export const CircularProgressBarSvg = styled.svg`
  transform: rotate(-90deg);
  border: ${({ isSelected }) =>
    isSelected
      ? '4px solid var(--color-charcoal-grey)'
      : '2px solid var(--color-charcoal-grey)'};
  border-radius: 50%;
`;

export const CircularProgressBarBackground = styled.circle`
  fill: none;
  stroke-width: ${({ strokeWidth }) => `${strokeWidth}px`};
`;

export const CircularProgressBarProgress = styled.circle`
  fill: none;
  stroke: var(--color-green);
  stroke-width: ${({ strokeWidth }) => `${strokeWidth}px`};
  transform-origin: ${({ sqSize }) => `${sqSize / 2}px ${sqSize / 2}px`};
  stroke-dashoffset: ${({ dashOffset }) => dashOffset}px;
  stroke-dasharray: ${({ dashArray }) => dashArray}px;
  transition: stroke-dashoffset 1.3s ease-out, stroke-dasharray 1.3s ease-out;
`;

export const ChildrenWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
