import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
`;

export const ContentBox = styled.div`
  border-radius: 40px;
  outline: solid 1px var(--color-mid-grey);
  box-shadow: 0 3px 6px 0 var(--color-grey);
`;
