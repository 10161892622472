import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';

export const TitleContainer = styled.div`
  display: flex;
`;

export const Image = styled.img`
  padding-left: 1.5rem;
`;
export const Title = styled.h2`
  font-family: ${primaryFont.bold};
  font-size: 1.8rem;

  margin: 0;
  color: var(--color-black);
  @media ${device.tablet} {
    font-size: 2.4rem;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
