import { useUpdateUserActivityMutation } from 'services/api/makesApi/makesApi';
import { useErrorContext } from 'context/errorContext';
import { useEffect } from 'react';
const useUpdateUserActivity = () => {
  const [updateUserActivity, { error: updateUserActivityError }] =
    useUpdateUserActivityMutation();

  const { handleError } = useErrorContext();

  useEffect(() => {
    if (updateUserActivityError) handleError(updateUserActivityError);
  }, [updateUserActivityError]);
  return { updateUserActivity };
};

export default useUpdateUserActivity;
