import { useEffect, useRef } from 'react';
import {
  createUserAnonymous,
  getUser,
  updateUserActiveTournament,
  getAnonymous,
  updateXp,
} from 'services/redux/auth.slice';
import {
  setMiniGamesFinished,
  setOnBoarding,
  setParticipatedDiscussionComponents,
  setVisitedTracks,
} from 'components/auth/userActivity.slice';
import { FIRST_TIME_LOGIN } from 'config/constants/xpConstants';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import RouteConfig from 'routes/RouteConfig';
import { fetchUserDataGoogle } from 'services/redux/auth.slice';

import useGetActiveTournament from 'hooks/queries/useGetActiveTournament';
import useGetUserActivity from 'hooks/userActivity/useGetUserActivity';
import useUpdateUserXp from 'hooks/xp/useUpdateUserXp';
import * as S from './App.styles';
import UseScrollTop from './hooks/useScrollTop';
import { ROLES } from 'routes/auth/constants';
import { setSpace } from 'services/redux/app.slice';
import { useGetSpacesQuery } from 'services/api/makesApi/makesApi';

const App = () => {
  const isAuthenticating = localStorage.getItem('isAuthenticating');
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const space = useSelector((state) => state.app.space);
  const pathname = window.location.pathname;

  const pathSegments = pathname.split('/');

  const spaceRoute = pathSegments[2];

  const user = useSelector((state) => state.auth.user);
  const userId = useSelector((state) => state.auth?.user?._id);
  const makeElRef = useRef(null);
  const dispatch = useDispatch();
  const { updateUserXp } = useUpdateUserXp();
  const { userActivity } = useGetUserActivity();
  const { tournament: activeTournament } = useGetActiveTournament();
  const token = window.localStorage.getItem('token');
  const anonymousId = localStorage.getItem('anonymousId');
  const unit = useSelector((state) => state.auth?.user?.lesson?.unitId);
  const isActiveUnit = useSelector((state) => state.auth?.user?.lesson?.isActive);

  const isInLesson = unit?.space && isActiveUnit;
  const currentSpaceIsMain = spaceRoute === 'main';
  const { data: exsistingSpaces } = useGetSpacesQuery(undefined, {
    skip: currentSpaceIsMain || isInLesson,
  });

  useEffect(() => {
    if (space || !user) return;

    let newSpace = 'main';
    if (isInLesson) {
      newSpace = unit.space.spaceRoute;
    } else if (!currentSpaceIsMain && exsistingSpaces) {
      const spaceExists = exsistingSpaces.find((s) => s.spaceRoute === spaceRoute);
      if (spaceExists) {
        newSpace = spaceRoute;
      }
    }

    dispatch(setSpace(newSpace));
  }, [unit, spaceRoute, isActiveUnit, space, user, exsistingSpaces]);

  useEffect(() => {
    const fetchData = () => {
      let code = '';

      if (location && location.search) {
        const urlParams = new URLSearchParams(window.location.search);
        code = urlParams.get('code');
        if (code) {
          dispatch(fetchUserDataGoogle(code));
        }
      }
      if (!code && localStorage.getItem('isAuthenticating')) {
        //if a user is in the login page goes to authenticate with google and decides to hit the back button. We need to remove the isAuthenticating flag from local storage or else the app will be stuck
        localStorage.removeItem('isAuthenticating');
        window.href = '/login';
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (isAuthenticating) return;

    if (!isLoggedIn && token) {
      return dispatch(getUser());
    }
    if (!isLoggedIn && !token && !anonymousId) {
      return dispatch(createUserAnonymous());
    }
    if (!isLoggedIn && !token && anonymousId) {
      return dispatch(getAnonymous());
    }
  }, [isAuthenticating]);

  useEffect(() => {
    if (userActivity) {
      dispatch(setVisitedTracks(userActivity.data.visitedTracks));
      dispatch(setOnBoarding(userActivity.data.isOnBoardingSeen));
      dispatch(setMiniGamesFinished(userActivity.data.miniGamesFinished));
      dispatch(
        setParticipatedDiscussionComponents(
          userActivity.data.discussionComponentsParticipated
        )
      );
    }
  }, [userActivity]);

  useEffect(() => {
    if (userId && isLoggedIn && activeTournament) {
      dispatch(updateUserActiveTournament({ activeTournament: activeTournament }));
    }
  }, [activeTournament, user]);
  useEffect(() => {
    if (user?.xp?.currentXp === 0 && user.role !== ROLES.GUEST) {
      updateUserFirstLoginXp();
    }
  }, [userId]);

  const updateUserFirstLoginXp = async () => {
    const { data } = await updateUserXp({ actionName: FIRST_TIME_LOGIN });
    dispatch(updateXp({ currentXp: data.newPoints, previousXp: data.oldPoints }));
  };

  return user ? (
    <>
      <S.App>
        <S.Container>
          {/* <AddToHome /> */}
          <Router>
            <UseScrollTop />
            <S.Makes ref={makeElRef}>
              <RouteConfig makeElRef={makeElRef} />
            </S.Makes>
          </Router>
        </S.Container>
      </S.App>
    </>
  ) : null;
};

export default App;
