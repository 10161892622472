import React from 'react';

import logo from 'assets/icons/makes-logo.svg';
import SocialLinks from 'components/SocialLinks/SocialLinks';
import { useSelector } from 'react-redux';
import { PRIVACY, TERMS } from 'routes/makes/constants';

import * as S from './Footer.styles';
import useIsMobile from 'hooks/useIsMobile';

const Footer = () => {
  const isMobile = useIsMobile();

  const copyright = '© כל הזכויות שמורות';
  const privacy = 'מדיניות הפרטיות';
  const terms = 'תנאי שימוש';

  return (
    <S.Footer id={'footer'}>
      <S.Nav>
        <S.IconsContainer isMobile={isMobile}>
          <S.SocialLinksWrapper>
            <SocialLinks />
          </S.SocialLinksWrapper>
          <S.MakesIcon aria-hidden="true" src={logo} alt="logo"></S.MakesIcon>
        </S.IconsContainer>
        <S.TermsContainer isMobile={isMobile}>
          <S.CopyRight>{copyright}</S.CopyRight>
          <S.LinksList>
            <li>
              <S.LinkText
                to={{
                  pathname: TERMS,
                  state: { type: 'terms', prevPath: location.pathname },
                }}
              >
                {terms}
              </S.LinkText>
            </li>
            <li>
              <span>|</span>
            </li>
            <li>
              <S.LinkText
                to={{
                  pathname: PRIVACY,
                  state: { type: 'privacy', prevPath: location.pathname },
                }}
              >
                {privacy}
              </S.LinkText>
            </li>
          </S.LinksList>
        </S.TermsContainer>
      </S.Nav>
    </S.Footer>
  );
};
export default Footer;
