import { triggerPushAlertAllowNotification } from 'apis/pushAlert';
import close_component from 'assets/icons/close_component.svg';
import { useErrorHandler } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { getTrackRoute } from 'routes/helper';
import { setShowMetaIframe, setShowComponentInfo } from 'services/redux/ui.slice';
import { componentCloseEvent } from 'services/segmentIO/categories/Navigation';
import * as Sentry from '@sentry/react';
import * as S from './closeIcon.styles.js';
import useIsMobile from 'hooks/useIsMobile.js';
const CloseIcon = () => {
  const { params } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const showMetaIframe = useSelector((state) => state.ui.showMetaIframe);
  const showComponentInfo = useSelector((state) => state.ui.showComponentInfo);
  const currentIndex = useSelector((state) => state.app.currentIndex);
  const currentMake = useSelector((state) => state.app.currentMake);
  const isCampaign = useSelector((state) => state.app.currentMake?.isCampaign);
  const currentTrack = useSelector((state) => state.app.currentTrack);
  const currentComponentId = currentMake.componentsId[currentIndex];
  const components = useSelector((state) => state.app.currentComponents);
  const sequences = components;
  const { type } = components[currentIndex];
  const handleOnCloseClick = () => {
    try {
      triggerPushAlertAllowNotification();

      const { trackRoute, topicRoute, makeRoute, space } = params;
      componentCloseEvent(
        { trackRoute, topicRoute, makeRoute },
        sequences,
        currentIndex,
        type,
        isCampaign
      );
      if (showMetaIframe) dispatch(setShowMetaIframe(false));
      if (showComponentInfo) dispatch(setShowComponentInfo(false));
    } catch (error) {
      Sentry.captureMessage('Makes - Error with closing component');
    } finally {
      history.push({
        pathname:
          location?.state?.from ||
          getTrackRoute(params?.trackRoute || currentTrack.trackRoute),
        state: { notTriggerSiteVisitEvent: true },
      });
    }
  };
  return (
    <S.CloseWrapper aria-label="סגור" isMobile={isMobile}>
      <img alt="close" src={close_component} onClick={handleOnCloseClick} />
      <S.TextHeader isMobile={isMobile}>סגור</S.TextHeader>
    </S.CloseWrapper>
  );
};
export default CloseIcon;
