import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';

export const Container = styled.div`
  position: relative;
  padding: 8rem;
  width: 100%;
  height: 100%;
  align-items: center;
  text-align: center;
  color: white;
  background: black;
  justify-content: space-between;
  font-family: ${primaryFont.bold};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
`;

export const ImageContainer = styled.div``;

export const Title = styled.h1`
  font-family: ${primaryFont.bold};
  font-size: 3rem;
`;

export const Paragraph = styled.h3`
  font-family: ${primaryFont.regular};
  font-weight: 500;
  font-size: 2.4rem;
  margin-bottom: 2rem;
`;

export const SubParagraph = styled.p`
  font-family: ${primaryFont.regular};
  font-size: 1.4rem;
  font-weight: 100;
  margin-bottom: 1rem;
`;

export const ButtonContainer = styled.button`
  font-family: ${primaryFont.bold};
  font-size: 2rem;
  height: 5rem;
  width: 36rem;
  border-radius: 15px;
  cursor: pointer;
  background: rgb(88, 211, 159);
  color: white;
  font-weight: bold;
  justify-self: end;
`;
