import VisuallyHidden from 'components/VisuallyHidden/VisuallyHidden';
import * as S from './spinner.styles';

const Spinner = ({ color, fontSize }) => {
  return (
    <S.Container color={color} fontSize={fontSize}>
      <VisuallyHidden>טוען...</VisuallyHidden>
    </S.Container>
  );
};
export default Spinner;
