import { lazy } from 'react';
import {
  FACILITATOR_DASHBOARD,
  FACILITATOR_TOURNAMENT,
  FACILITATOR_TOURNAMENT_CLASS,
} from './constants';
import { ROLES } from '../auth/constants';

const FacilitatorMake = lazy(() =>
  import('features/Facilitator/FacilitatorMake/FacilitatorMake')
);
const FacilitatorDashboard = lazy(() =>
  import('features/Facilitator/FacilitatorDashboard/FacilitatorDashBoard')
);
const FacilitatorTournament = lazy(() =>
  import('features/Facilitator/FacilitatorDashboard/features/Tournament/Tournament')
);
const FacilitatorTournamentClassRoom = lazy(() =>
  import(
    'features/Facilitator/FacilitatorDashboard/features/Tournament/pages/ClassroomPage/ClassroomPage'
  )
);
// !TODO;
//for some reason the route to classroom is not working
//after you checked the facilitator auth check the quiz auth

const facilitatorRoutes = [
  {
    path: `${FACILITATOR_TOURNAMENT_CLASS}/:classId`,
    component: FacilitatorTournamentClassRoom,
    private: true,
    requiredRoles: [ROLES.FACILITATOR, ROLES.SUPER_USER],
    exact: true,
  },
  {
    path: FACILITATOR_TOURNAMENT,
    component: FacilitatorTournament,
    private: true,
    requiredRoles: [ROLES.FACILITATOR, ROLES.SUPER_USER],
    exact: true,
  },

  {
    path: FACILITATOR_DASHBOARD,
    component: FacilitatorDashboard,
    private: true,
    requiredRoles: [ROLES.FACILITATOR, ROLES.SUPER_USER],
    exact: true,
  },
  {
    path: '/facilitator/:makeRoute/:makeId',
    component: FacilitatorMake,
    private: true,
    requiredRoles: [ROLES.FACILITATOR, ROLES.SUPER_USER],
    exact: true,
  },
];
export default facilitatorRoutes;
