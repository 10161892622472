import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';

export const Container = styled.div`
  display: flex;
  font-family: ${primaryFont.regular};
  height: 4rem;
  @media ${device.tablet} {
    height: 6rem;
  }
`;

export const Name = styled.h2`
  font-size: 1.8rem;
  white-space: nowrap;
  @media ${device.tablet} {
    font-size: 2.4rem;
  }
`;
export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10rem;
  z-index: 1;
  padding: 0.5rem 1.5rem 0.5rem 15%;
  background-color: #fff;
  column-gap: 1rem;
  @media ${device.tablet} {
    padding: 0.5rem 2rem 0.5rem 15%;
  }
`;
export const Avatar = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  @media ${device.tablet} {
    width: 4rem;
    height: 4rem;
  }
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  background-color: transparent;
  margin-right: -15px;
`;

export const ProgressBar = styled.div`
  width: ${({ progress }) => progress + '%'};
  min-width: 80px;
  background-color: var(--color-green);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1.5rem;
  border-radius: 10rem 0 0 10rem;
  height: 100%;
  font-size: 18px;
  color: #fff;
  transition: width 0.5s ease-in-out;
`;
