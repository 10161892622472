import NavBar from 'layouts/NavBar';
import Header from 'layouts/Header';
import Statistics from 'layouts/Statistics/Statistics';
import SubNav from 'layouts/SubNav/SubNav';
import { useDispatch, useSelector } from 'react-redux';
import Footer from 'layouts/Footer/Footer.js';
import NavbarSkeleton from 'components/SkeletonLoader/SkeletonDiscovery/NavbarSkeleton/NavbarSkeleton.js';
import SubnavSkeleton from 'components/SkeletonLoader/SkeletonDiscovery/SubnavSkeleton/SubnavSkeleton.js';
import * as S from './DiscoveryLayout.style.js';
import NavItem from 'layouts/NavBar/NavItem/NavItem.js';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { setTopSectionHeight } from 'services/redux/ui.slice.js';
import useIsMobile from 'hooks/useIsMobile.js';

const DiscoveryLayout = ({ children, tracks, topics }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isHamburgerOpen = useSelector((state) => state.ui.toggleHamburger);
  const topSectionHeight = useSelector((state) => state.ui.topSectionHeight);
  const topSectionRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isMobile && tracks && topics) {
      setLoading(false);
    }
  }, [tracks, topics]);

  // Calculate height only after loading is finished
  useLayoutEffect(() => {
    if (!isMobile && !loading && topSectionRef.current) {
      dispatch(setTopSectionHeight(topSectionRef.current.offsetHeight));
    }
  }, [loading]);

  return (
    <S.Container isHamburgerOpen={isHamburgerOpen} isMobile={isMobile}>
      <S.DiscoveryPageHeader isMobile={isMobile} ref={topSectionRef}>
        <Header type="discovery">
          <Statistics />
        </Header>
        {!tracks ? <NavbarSkeleton /> : <NavBar tracks={tracks} />}
        {!topics ? <SubnavSkeleton /> : <SubNav topics={topics} />}
      </S.DiscoveryPageHeader>
      {children ?? <S.ChildrenWrapper></S.ChildrenWrapper>}
      {!isMobile && (
        <S.FooterContainer>
          <Footer />
        </S.FooterContainer>
      )}
    </S.Container>
  );
};

export default DiscoveryLayout;
