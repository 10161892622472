import { getAllTournaments } from 'apis/tournamentsApi/tournaments.api';
import useShowAlert from 'hooks/useShowAlert';
import { createContext, useContext, useEffect, useState } from 'react';
import useGetAllFacilitatorMakes from '../../../hooks/useGetAllFacilitatorMakes';

const TournamentContext = createContext();

const useTournamentContext = () => {
  const context = useContext(TournamentContext);
  if (!context) {
    throw new Error('TournamentContext must be used within a TournamentProvider');
  }
  return context;
};

const TournamentProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [currentTournament, setCurrentTournament] = useState(null);
  const [tournaments, setTournaments] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [facilitatorMakes, setFacilitatorMakes] = useState([]);
  const { getAllFacilitatorMakes } = useGetAllFacilitatorMakes();
  const alert = useShowAlert();
  const { activeAlert } = alert;
  useEffect(() => {
    getAllTournaments().then((data) => setTournaments(data.data));
    getAllFacilitatorMakes().then((data) => setFacilitatorMakes(data.data));
  }, []);

  const value = {
    tournaments,
    isEditing,
    setIsEditing,
    facilitatorMakes,
    currentTournament,
    setTournaments,
    setCurrentTournament,
    isLoading,
    setIsLoading,
    alert,
    activeAlert,
  };
  return <TournamentContext.Provider value={value} {...props} />;
};
export { TournamentProvider, useTournamentContext };
