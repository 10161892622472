import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';

import * as S from './richTextParser.styles';
import useIsMobile from 'hooks/useIsMobile';

const RichTextParser = ({ data }) => {
  const isMobile = useIsMobile();
  return <S.Wrapper isMobile={isMobile}>{ReactHtmlParser(data)}</S.Wrapper>;
};
export default RichTextParser;
