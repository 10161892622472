import styled, { keyframes } from 'styled-components';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';
const progressAnimation = (progress) => keyframes`
  0% {
    width: 0;
  }
  100% {
    width: ${progress}%;
  }
`;

export const Item = styled.div`
  border: ${({ isSelected }) => (isSelected ? '3px' : '1px')} solid black;
  border-radius: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2rem;
  overflow: hidden;
  z-index: 1;
  line-height: 1;
  position: relative;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: ${({ progress }) => (progress ? progress + '%' : '0')};
    height: 100%;
    background-color: var(--color-green);
    animation: ${({ progress }) => progressAnimation(progress)} 1s ease-out forwards;
  }
`;

export const Text = styled.p`
  color: black;
  padding: 0.5rem 1rem 0.4rem;
  white-space: nowrap;
  font-family: ${primaryFont.bold};
  font-size: 1.2rem;

  ::before {
    content: '';
  }
`;
