import * as S from './MakeCard.style';
import MakeCardDetails from './components/MakeCardDetails/MakeCardDetails';
import VisuallyHidden from 'components/VisuallyHidden/VisuallyHidden';
import Teaser from './components/Teaser/Teaser';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const MakeCard = ({
  progress,
  trackName,
  icons,
  id,
  make,
  topics,
  index,
  isIOS,
  handleOnClick,
}) => {
  const topSectionHeight = useSelector((state) => state.ui?.topSectionHeight);
  const [hovered, setHovered] = useState(false);

  const teaser = make?.teaser;
  const thumbnail = make?.thumbnail;
  const topicName =
    make?.topicNameFromRecommendation ||
    topics?.find((topic) => topic?.makesId?.find((makeId) => makeId === make._id))
      ?.topicName;
  const contentCreator = make?.contentCreator;
  const progressStatus = progress?.status;

  const makeName = make?.makeName;

  const makeDescription = make?.makeDescription;

  return (
    <S.MakeListItem
      id={id}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      topSectionHeight={topSectionHeight}
    >
      {make?.quizMake && (
        <S.QuizButton onClick={(e) => handleOnClick(e, make, true)}>
          מייק חידון
        </S.QuizButton>
      )}
      <S.MakeBtn
        aria-describedby="makeDescription"
        onClick={(e) => handleOnClick(e, make)}
      >
        <VisuallyHidden>{`טראק ${trackName} .
        טופיק ${topicName}.
        make מספר ${index + 1}
        ,make זה מדבר על ${makeName}
${progressStatus ? `make ${progressStatus}` : ''}
${progress?.achievedStar ? ` וצברת ${progress?.achievedStar} נקודות` : ''}
        `}</VisuallyHidden>
        <S.Article>
          <Teaser
            hovered={hovered}
            make={make}
            handleOnClick={handleOnClick}
            isIOS={isIOS}
            teaser={teaser}
            thumbnail={thumbnail}
            index={index}
            progressStatus={progressStatus}
            contentCreator={contentCreator}
            topicName={topicName}
          />

          <MakeCardDetails
            progress={progress}
            makeName={makeName}
            makeDescription={makeDescription}
            icons={icons}
          />
        </S.Article>
      </S.MakeBtn>
    </S.MakeListItem>
  );
};

export default MakeCard;
