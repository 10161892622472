import MainLayout from 'features/Facilitator/layout/MainLayout';
import { useGuideContext } from 'features/Guide/context/guideContext';
import * as S from './StudentPage.styles';
import Progress from '../../components/Progress/Progress';
import Card from './components/Card/Card';
import Spinner from 'components/Spinner';

const StudentPage = () => {
  const { studentCompletedMakes, tracks } = useGuideContext();
  if (!tracks || !studentCompletedMakes) return <Spinner />;
  const {
    userName,
    userAvatar,
    userProgress,
    userId: studentId,
  } = studentCompletedMakes || {};
  return (
    <MainLayout title={userName}>
      {studentCompletedMakes && (
        <S.Container>
          <Progress name={userName} avatar={userAvatar} progress={userProgress} />
          <S.CardsList>
            {tracks?.map((track) => (
              <li key={track.trackName}>
                <Card track={track} studentId={studentId} />
              </li>
            ))}
          </S.CardsList>
        </S.Container>
      )}
    </MainLayout>
  );
};

export default StudentPage;
