import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4rem;

  @media ${device.tablet} {
    row-gap: 5rem;
  }
`;
