import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const Container = styled.div`
  display: flex;
  padding: 3rem 5rem;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  border-radius: 30px;
  background-color: #fff;
  @media ${device.tablet} {
    padding: 7rem 2rem;
  }
`;

export const TopicsList = styled.ul`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
