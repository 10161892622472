import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';

export const TopicName = styled.span`
  text-shadow: 1px 1px 1px var(--color-black);
  font-size: 1.4rem;
  color: var(--color-white);
  padding: 1rem;
  background: var(--accessibility-background);
  position: absolute;
  right: 0;
  top: 0;
`;

export const BottomWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 0%;
  align-items: flex-end;
`;

export const MakeStatusWrapper = styled.div`
  p {
    font-family: ${primaryFont.bold};
    font-size: 1.6rem;
    text-align: center;
    column-gap: 2.5px;
    font-weight: bold;
    padding: 0.5rem 1rem 0.4rem;
    height: 3rem;
    margin: 1rem;
    letter-spacing: 0.75px;
    z-index: 1;
    border-radius: 13.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
  }
`;
