import { useEffect, useState } from 'react';

import log_out from 'assets/icons/log_out.svg';
import placeholder_user from 'assets/icons/placeholder_user.svg';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LOGIN, SIGN_IN_OPEN_FROM, MY_MAKES, ROOT } from 'routes/makes/constants';
import { getUrlWithSpace } from 'routes/helper';
import { userLogoutEvent } from 'services/segmentIO/categories/UserState';

import * as S from '../login.styles';
import useIsMobile from 'hooks/useIsMobile';

const AvatarUser = () => {
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const [src, setSrc] = useState(placeholder_user);
  const user = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  useEffect(() => {
    if (isLoggedIn) {
      const { userAvatar, discordId, googleId, anonymousId } = user;

      if (discordId && userAvatar) {
        setSrc(`https://cdn.discordapp.com/avatars/${discordId}/${userAvatar}.png`);
      } else if (googleId && userAvatar) {
        setSrc(userAvatar);
      } else {
        setSrc(log_out);
      }
    }
  }, [isLoggedIn, user]);
  const logoutOnClick = () => {
    userLogoutEvent();
    setSrc(placeholder_user);
    localStorage.clear();
    window.location.reload();
  };

  return (
    <S.AvatarLoginUser>
      <S.LinkTo
        to={{
          pathname: isLoggedIn ? getUrlWithSpace(MY_MAKES) : LOGIN,
          state: {
            prevPath: ROOT,
            from: SIGN_IN_OPEN_FROM.DISCOVERY,
          },
        }}
      >
        {!isLoggedIn && !isMobile ? (
          <S.Logout isMobile={isMobile} alt="כניסה למערכת" src={log_out} />
        ) : (
          <S.Image isLoggedIn={isLoggedIn} alt={'דף פרופיל - המייקים שלי'} src={src} />
        )}
      </S.LinkTo>
    </S.AvatarLoginUser>
  );
};

export default AvatarUser;
