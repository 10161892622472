import instagram from 'assets/icons/instagram.svg';
import tiktok from 'assets/icons/tiktok.svg';
import youtube from 'assets/icons/youtube.svg';

export const socialLinks = [
  {
    name: 'youtube',
    link: 'https://www.youtube.com/channel/UC81gMNeJy36F09kXdhaVueg',
    icon: youtube,
    background: '#FF0000',
  },
  {
    name: 'instagram',
    link: 'https://www.instagram.com/makesapp.co/',
    icon: instagram,
    background:
      '   radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)',
  },
  {
    name: 'tiktok',
    link: 'https://www.tiktok.com/@makesapp.co/',
    icon: tiktok,
    background: '#white',
  },
];
