import MakeStatus from 'components/MakeStatus/MakeStatus';
import * as S from './AccordionOption.styles';
import arrow from 'assets/icons/selectArrowDark.svg';
import { ReactComponent as MyMakesIcon } from 'assets/icons/my_makes.svg';
import ProgressStar from 'components/ProgressStar';

const AccordionOption = ({
  name,
  progress,
  handleOnClick,
  isOpen,
  statusIcon,
  value,
  backgroundColor,
  disabled,
  isSeenByGuide,
}) => {
  return (
    <S.Button onClick={handleOnClick} disabled={disabled} aria-disabled={disabled}>
      <S.Name>{name}</S.Name>
      <S.Details>
        <S.StatusWrapper>
          <MakeStatus
            color="#fff"
            backgroundColor={backgroundColor}
            statusIcon={statusIcon}
            value={value}
          />
        </S.StatusWrapper>
        <ProgressStar progress={progress} />
        <S.Column>
          <MyMakesIcon opacity={disabled ? 0.5 : 1} />
          {!disabled && !isSeenByGuide && <S.Dot />}
        </S.Column>
        <S.Arrow src={arrow} alt="arrow" $isopen={isOpen} />
      </S.Details>
    </S.Button>
  );
};

export default AccordionOption;
