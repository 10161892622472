import { useEffect, useState } from 'react';
import * as S from './Feedback.styles';
import useAutoResize from 'hooks/useAutoResize';
import useInsertFeedForUploadedMake from 'features/Guide/hooks/useInsertFeedbackForUploadedMake';
import { useSelector } from 'react-redux';
import { useGuideContext } from 'features/Guide/context/guideContext';
import FeedbackMessage from 'components/FeedbackMessage/FeedbackMessage';
import Spinner from 'components/Spinner';

const Feedback = ({ feedback, progressId }) => {
  const guideId = useSelector((state) => state.auth.user._id);
  const { studentId } = useGuideContext();
  const [editMode, setEditMode] = useState(false);
  const [feedbackText, setFeedbackText] = useState(feedback);
  const { textAreaRef } = useAutoResize(feedbackText);
  const { insertFeedbackForUploadedMake, isLoading: isFeedBackInsertLoading } =
    useInsertFeedForUploadedMake();

  useEffect(() => {
    if (editMode && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [editMode, textAreaRef]);

  const insertFeedback = () => {
    insertFeedbackForUploadedMake({ progressId, guideId, studentId, feedbackText });
    setEditMode(false);
  };
  const editFeedback = () => {
    setEditMode(true);
  };

  const handleFeedbackText = (e) => {
    setFeedbackText(e.target.value);
  };

  if (isFeedBackInsertLoading)
    return (
      <S.Container>
        <Spinner />
      </S.Container>
    );
  return (
    <S.Container>
      <FeedbackMessage>
        {!feedbackText && !editMode && (
          <S.Button onClick={() => setEditMode(true)}>לתת משוב על Make</S.Button>
        )}
        <S.TextArea
          onChange={handleFeedbackText}
          $isvisible={editMode || feedbackText}
          ref={textAreaRef}
          value={feedbackText}
          aria-disabled={!editMode}
          disabled={!editMode}
        ></S.TextArea>
      </FeedbackMessage>
      {editMode && (
        <S.InsertFeedbackBtn onClick={insertFeedback}>
          שמור את המשוב שלי
        </S.InsertFeedbackBtn>
      )}
      {!editMode && feedbackText && (
        <S.EditFeedbackBtn onClick={editFeedback}>ערוך את המשוב שלי</S.EditFeedbackBtn>
      )}
    </S.Container>
  );
};

export default Feedback;
