import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';

export const AvatarLoginUser = styled.div`
  display: flex;
  align-items: center;
`;
export const Image = styled.img`
  border-radius: ${({ isLoggedIn }) => (!isLoggedIn ? 'default' : '50%')};
  height: 3rem;
  width: 3rem;

  cursor: ${({ isLoggedIn }) => (isLoggedIn ? 'default' : 'pointer')};
  @media ${device.tablet} {
    height: 4rem;
    width: 4rem;
  }
`;

export const Logout = styled.img`
  width: 2rem;
  height: 3rem;
  margin-top: 2px;
`;

export const LinkTo = styled(Link)`
  cursor: pointer;
  height: 3rem;
  width: 3rem;
`;

export const Main = styled.main`
  background: white;
  height: 100%;
  position: relative;
`;

export const ContainerOnboarding = styled.div`
  background: inherit;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const Header = styled.h1`
  padding-top: 5rem;
`;
export const BigTitle = styled.span`
  font-size: 5.1rem;
  font-family: ${primaryFont.bold};
  color: var(--color-charcoal);
  text-align: center;
  display: block;
`;

export const SmallTitle = styled.span`
  display: block;
  font-size: 2.5rem;
  font-family: ${primaryFont.bold};
  color: var(--color-charcoal);
  text-align: center;
`;

export const CenterContainer = styled.div`
  width: 100%;
  margin: ${({ marginVal }) => (marginVal ? marginVal : 'auto')};
  display: flex;
  justify-content: center;
`;

export const rectangle = styled.div`
  width: 20rem;
  height: 5rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-color: ${({ borderColor }) => borderColor};
  margin: 2rem;
  border-style: solid;
  border-width: 0.1rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-evenly;
  color: ${({ fontColor }) => fontColor};
  font-family: ${primaryFont.regular};
  font-size: 1.6rem;
  line-height: 4.6rem;
  opacity: ${({ opacity }) => opacity || 1};
  cursor: ${({ cursor }) => cursor || 'default'};
`;

export const whatIsDiscord = styled.p`
  text-decoration: underline;
  font-size: 1.4rem;
  font-family: ${primaryFont.regular};
  text-align: center;
  cursor: pointer;
`;

export const checkBoxSubtitleDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CheckboxLink = styled.div`
  text-decoration: underline;
`;

export const RainbowOnBoarding = styled.div`
  height: 6rem;
  width: 10rem;
  display: flex;
  justify-content: center;
  margin: 0.5rem;
`;

export const CircleOnBoarding = styled.div`
  border-radius: 4.5rem;
  width: 6rem;
  height: 6rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-color: black;
  border-width: 0.5rem;
  border-style: solid;
  display: flex;
  justify-content: center;
  margin: 0.5rem;
  padding: 1rem;
`;

export const RedirectButton = styled.button`
  width: 20rem;
  display: flex;
  align-items: center;
  height: 4.3rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-color: ${({ borderColor }) => borderColor};
  margin: 2rem;
  border-style: solid;
  border-width: 0.1rem;
  border-radius: 0.5rem;
  justify-content: space-evenly;
  color: ${({ fontColor }) => fontColor};
  font-family: ${primaryFont.regular};
  font-size: 1.6rem;
  line-height: 4.6rem;
  opacity: ${({ opacity }) => opacity || 1};
  cursor: ${({ cursor }) => cursor || 'default'};
`;

export const whatIsDiscordOnBoarding = styled.p`
  text-decoration: underline;
  font-size: 1.4rem;
  font-family: ${primaryFont.regular};
  text-align: center;
  cursor: pointer;
  position: absolute;
  bottom: 8rem;
  right: 2rem;
`;

export const LoginCheckboxContainer = styled.div`
  margin: ${({ isMobile }) => (isMobile ? '2rem' : '2rem auto')};
  background-color: var(--color-background);
  width: ${({ isMobile }) => (isMobile ? '' : '70%')};
`;

export const CloseLink = styled(Link)`
  position: absolute;
  top: 2.5%;
  z-index: 1000;
  right: 5%;
  cursor: pointer;
`;
export const CloseIcon = styled.img`
  height: ${({ isMobile }) => (isMobile ? '1.5rem' : '2rem')};
  width: ${({ isMobile }) => (isMobile ? '1.5rem' : '2rem')};
`;
