import star from 'assets/icons/star.svg';
import IconWithText from 'components/IconWithText/IconWithText';
import xpPath from 'assets/icons/xpPath.svg';
import points from 'assets/icons/pointsIcon.svg';
import { useSelector } from 'react-redux';
import { calculateUserAchivedStart, getCurrentLevel } from 'utils/helpers';
import { TOTAL_STARS_IN_MAKE } from 'components/XpBanner/CONSTANT';
import { Link } from 'react-router-dom';
import { getProfileOptionRoute } from 'routes/helper';
import { LEADER_BOARD, MY_MAKES, XP } from 'routes/makes/constants';
import useCounter from 'hooks/useCounter';
import * as S from './Statistics.style';
import VisuallyHidden from 'components/VisuallyHidden/VisuallyHidden';
import { levels } from 'config/constants/xpConstants';
import useGetUserProgressByUserId from 'hooks/queries/useGetUserProgressByUserId';
const Statistics = () => {
  const userXp = useSelector((state) => state.auth?.user?.xp?.currentXp);
  const userPoints = useSelector((state) => state.auth?.user?.points?.currentPoints);
  const userId = useSelector((state) => state.auth?.user?._id);
  const { userProgresses } = useGetUserProgressByUserId(userId);

  const { counter: pointsCounter } = useCounter(userPoints, 100);
  const stars = calculateUserAchivedStart(userProgresses);
  const { counter: starsCounter } = useCounter(stars, 300);
  const { counter: xpCounter } = useCounter(
    userXp && getCurrentLevel(levels, userXp)?.currentLevel?.level,
    300
  );

  return userProgresses ? (
    <S.List>
      <S.ListItem>
        <Link to={{ pathname: getProfileOptionRoute(XP) }}>
          <VisuallyHidden>
            {`כרגע נמצאים על לינק שמעביר אותכם למפת ניקוד , בתוכו תמונה של מסלול ודירוג של ${
              getCurrentLevel(levels, userXp)?.currentLevel?.level
            }
              `}
          </VisuallyHidden>
          <IconWithText icon={xpPath} alt={'מסלול'}>
            {xpCounter}
          </IconWithText>
        </Link>
      </S.ListItem>
      <S.ListItem>
        <Link to={{ pathname: getProfileOptionRoute(LEADER_BOARD) }}>
          <VisuallyHidden>
            {` כרגע נמצאים על לינק שמעביר אותכם לטבלת הדירוגים , בתוכו תמונה של כוכב וניקוד של ${starsCounter} מסך ${
              userProgresses.length * TOTAL_STARS_IN_MAKE
            } 
              `}
          </VisuallyHidden>
          <IconWithText icon={star} alt={'כוכב'}>
            {starsCounter + '/' + userProgresses.length * TOTAL_STARS_IN_MAKE}
          </IconWithText>
        </Link>
      </S.ListItem>
      <S.ListItem>
        <Link to={{ pathname: getProfileOptionRoute(MY_MAKES) }}>
          <VisuallyHidden>
            {`כרגע נמצאים על לינק שמעביר אותכם למייקים שלי , בתוכו תמונה של תגית שמתארת את הדרגה שלכם ומספר הנקודות ניסיון של${pointsCounter}
`}
          </VisuallyHidden>
          <IconWithText icon={points} alt={'תגית'}>
            {pointsCounter}
          </IconWithText>
        </Link>
      </S.ListItem>
    </S.List>
  ) : (
    <></>
  );
};

export default Statistics;
