import { api, getData } from 'apis';
import { API_VERSION } from 'apis/CONSTANTS';
import {
  CREATE_NEW_FACILITATOR_MAKE,
  DELETE_FACILITATOR_MAKE,
  GET_ALL_FACILITATOR_MAKES,
  GET_FACILITATOR_MAKE_BY_ID,
  UPDATE_FACILITATOR_MAKE,
} from './constants';

//makes

export const getFacilitatorMakes = () =>
  api.get(`${API_VERSION}/${GET_ALL_FACILITATOR_MAKES()}`).then(getData);

export const getFacilitatorMake = (makeId) =>
  api.get(`${API_VERSION}/${GET_FACILITATOR_MAKE_BY_ID(makeId)}`).then(getData);

export const deleteFacilitatorMake = (makeId) =>
  api.delete(`${API_VERSION}/${DELETE_FACILITATOR_MAKE(makeId)}`).then(getData);
export const updateFacilitatorMake = (makeId, data) =>
  api.put(`${API_VERSION}/${UPDATE_FACILITATOR_MAKE(makeId)}`, data).then(getData);

export const createFacilitatorMake = (data) => {
  return api.post(`${API_VERSION}/${CREATE_NEW_FACILITATOR_MAKE()}`, data).then(getData);
};
