import useScrollToSides from 'hooks/useScrollToSides';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { getTopicRoute } from 'routes/helper';
import TopicItem from 'components/Topic/Topic';
import * as S from './Topic.style';
import useIsMobile from 'hooks/useIsMobile';
const Topic = ({ progress, item, topics, scrollToFunc }) => {
  const params = useParams();
  const history = useHistory();
  const { _id: topicId, topicName, topicRoute } = item;
  const currentTopicId = useSelector((state) => state.app.currentTopic?._id);
  const trackRoute = useSelector((state) => state.app?.currentTrack?.trackRoute);
  const isMobile = useIsMobile();
  const currentTopicIndex = topics?.findIndex((topic) => topic._id === currentTopicId);
  const index = topics?.findIndex((topic) => topic._id === topicId);
  const { ref } = useScrollToSides(
    currentTopicIndex,
    index,
    topics?.length,
    scrollToFunc
  );
  return (
    <li ref={ref} key={topicId}>
      <S.Link
        aria-label={topicName}
        aria-current={params.topicRoute === topicRoute ? 'true' : 'false'}
        to={{
          pathname: getTopicRoute(trackRoute, topicRoute),
          hash: !isMobile && '#' + topicId,
        }}
      >
        <TopicItem
          progress={progress}
          topicName={isMobile ? topicName : '#' + topicName}
          isSelected={topicRoute === params.topicRoute}
        />
      </S.Link>
    </li>
  );
};

export default Topic;
