import * as S from './NavbarSkeleton.style';

const NavbarSkeleton = () => {
  return (
    <>
      <S.NavBarContainer>
        <S.NavBarWrapper>
          {Array.from({ length: 6 }).map((_, index) => (
            <S.TrackContainer key={index}>
              <S.TrackIconWrapper>
                <S.TrackIcon></S.TrackIcon>
              </S.TrackIconWrapper>
              <S.TrackName />
            </S.TrackContainer>
          ))}
        </S.NavBarWrapper>
      </S.NavBarContainer>
    </>
  );
};

export default NavbarSkeleton;
