import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  width: 100%;
  position: relative;

  @media ${device.tablet} {
    row-gap: 2rem;
  }
`;

export const Button = styled.button`
  background-color: var(--color-mid-grey);
  font-size: 2.4rem;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  line-height: 40px;
  padding: 0 3rem;
`;

export const TextArea = styled.textarea`
  display: ${({ $isvisible }) => !$isvisible && 'none'};
  resize: none;
  border: none;
  width: 100%;
  font-size: 18px;
  min-height: 20px;
  :focus {
    border: none;
    outline: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  :disabled {
    color: #000 !important;
    background-color: transparent;
  }
`;

const FeedbackBtn = styled.button`
  color: #fff;
  padding: 0 2.5rem;
  line-height: 35px;
  border-radius: 10px;
  font-size: 24px;
  width: fit-content;
  font-family: 'Noto Sans Hebrew ExtraCondensed Regular', sans-serif;
  cursor: pointer;
`;

export const InsertFeedbackBtn = styled(FeedbackBtn)`
  background-color: var(--color-green);
`;

export const EditFeedbackBtn = styled(FeedbackBtn)`
  background-color: var(--color-mid-grey);
`;
