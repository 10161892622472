import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { ROOT } from 'routes/makes/constants';

const RoleRoute = ({ component: Component, requiredRoles, ...rest }) => {
  const userRole = useSelector((state) => state.auth.user.role);

  return (
    <Route
      {...rest}
      render={(props) => {
        return requiredRoles.includes(userRole) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: ROOT, from: props.location }} />
        );
      }}
    />
  );
};

export default RoleRoute;
