import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';

export const Container = styled.div`
  padding: 6rem 12rem 2rem 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContentBox = styled.div`
  border-radius: 40px;
  border: solid 1px var(--color-mid-grey);
  box-shadow: 0 3px 6px 0 var(--color-grey);
  padding: 12rem 6rem 2rem 6rem;
`;

export const TextContainer = styled.div`
  margin: 3rem 0 7.5rem 0;
`;
export const SmallText = styled.p`
  font-family: ${primaryFont.bold};
  font-size: 57px;
  line-height: 1.11;
  text-align: center;
  color: var(--color-light-charcoal);
`;
export const BigText = styled.p`
  font-size: 90px;
  font-family: ${primaryFont.bold};
  text-align: center;
  color: var(--color-green);
  line-height: 0.4;
`;
export const CloseBtn = styled.button`
  display: flex;
  column-gap: 0.75rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const CloseText = styled.p`
  font-size: 14px;
  font-family: ${primaryFont.regular};
`;
export const Icon = styled.img``;
