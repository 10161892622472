import CircularProgressBar from 'components/CircualProgressBar/CircualProgressBar';
import useScrollToSides from 'hooks/useScrollToSides';
import { useSelector } from 'react-redux';
import * as S from './NavItem.style';
import Track from 'components/Track/Track';

const NavItem = ({ progress, path, params, item, scrollToFunc, tracks }) => {
  const { _id: trackId, trackName, trackRoute, icon: trackIcon } = item;
  const currentTrackId = useSelector((state) => state.app.currentTrack?._id);
  const currentTrackIndex = tracks?.findIndex((track) => track._id === currentTrackId);
  const index = tracks?.findIndex((track) => track._id === trackId);
  const { ref } = useScrollToSides(
    currentTrackIndex,
    index,
    tracks?.length,
    scrollToFunc
  );

  const renderContent = () => {
    const isSelected = trackId === currentTrackId;
    return (
      <Track
        isSelected={isSelected}
        progress={progress}
        trackName={trackName}
        icon={trackIcon}
      />
    );
  };

  return (
    <li ref={ref} key={trackId}>
      <S.Link
        exact
        to={{
          pathname: path,
        }}
        aria-current={params.trackRoute === trackRoute ? 'true' : 'false'}
      >
        {renderContent()}
      </S.Link>
    </li>
  );
};

export default NavItem;
