import wheelChair from 'assets/icons/wheelChair.svg.png';
import * as S from './WheelChair.styles.js';
const WheelChair = ({ cb, isAccessbilityMode }) => {
  const backGroundColor = isAccessbilityMode
    ? 'var(--color-green)'
    : 'var(--color-white)';
  return (
    <S.Circle backGroundColor={backGroundColor}>
      <S.WheelChairButton aria-label="ניגודיות צבע נגישות " onClick={cb}>
        <S.Image alt="הצהרת נגישות" src={wheelChair} aria-hidden="true" />
      </S.WheelChairButton>
    </S.Circle>
  );
};
export default WheelChair;
