import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTrackRoute } from 'routes/helper';

import * as S from './NavBar.styles';
import { useRef } from 'react';
import NavItem from './NavItem/NavItem';
import Progress from 'components/Progress/Progress';
import useIsMobile from 'hooks/useIsMobile';

const NavBar = ({ tracks }) => {
  const params = useParams();
  const ref = useRef();
  const user = useSelector((state) => state.auth.user);
  const userId = user?._id;
  const isMobile = useIsMobile();

  const scrollToFunc = (offSet) => {
    ref.current.scrollTo({
      top: 0,
      left: offSet,
      behavior: 'smooth',
    });
  };

  const NavLinks = (
    <>
      {tracks.map((track) => {
        console.log('track', track);
        return (
          <Progress key={track.trackName} item={track} itemKey="trackId" userId={userId}>
            {({ progress }) => {
              return (
                <NavItem
                  progress={progress}
                  item={track}
                  key={track._id}
                  tracks={tracks}
                  scrollToFunc={scrollToFunc}
                  path={getTrackRoute(track.trackRoute)}
                  params={params}
                />
              );
            }}
          </Progress>
        );
      })}
    </>
  );

  return (
    <S.Nav isMobile={isMobile} aria-label="ניווט טראקים">
      <S.NavList isMobile={isMobile} ref={ref}>
        {NavLinks}
      </S.NavList>
    </S.Nav>
  );
};

export default NavBar;
