import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const MakeItem = styled.li`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  @media ${device.tablet} {
    row-gap: 2rem;
  }
`;
