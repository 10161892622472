import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';
export const Container = styled.div`
  height: 100%;
  padding: 5rem 2.5rem 0 0;
  width: 80%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  background: var(--color-white);
  @media ${device.tablet} {
    padding: 2.5rem 5rem 0 0;
    width: 40rem;
  }
`;

export const SocialLinksList = styled.ul`
  display: flex;
  align-items: center;
  padding: 1.25rem 0 2.5rem 0;

  @media ${device.tablet} {
    padding: 2rem 0 4rem 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SubTitleWrapper = styled.div``;

export const Title = styled.div`
  font-family: ${primaryFont.bold};
  font-size: 1.8rem;
  color: var(--color-charcoal-grey);
  display: flex;
`;

export const SubTitle = styled.div``;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Logo = styled.div`
  text-align: center;
  justify-self: center;
  cursor: pointer;
  padding-left: 1rem;
`;
export const MakesIcon = styled.img`
  height: 3.1rem;
  width: 12.6rem;

  @media ${device.tablet} {
    width: 20rem;
    height: 4.97rem;
  }
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Topic = styled.section``;
export const List = styled.ul`
  font-family: ${primaryFont.regular};
  cursor: pointer;
  direction: rtl;
  color: ${({ active }) => (active ? 'var(--color-red)' : 'var(--color-charcoal-grey)')};
`;

export const CloseButton = styled.button`
  padding-left: 2.5rem;
  cursor: pointer;
`;

export const ListItem = styled.li``;
export const Link = styled.button`
  color: var(--color-charcoal-grey);
  width: fit-content;
  font-size: 1.4rem;
  cursor: pointer;
  @media ${device.tablet} {
    font-size: 1.8rem;
  }
`;
export const AccesibilityLink = styled.button`
  text-decoration: underline;
  font-size: 1.4rem;
  color: var(--color-charcoal-grey);
  width: fit-content;
  cursor: pointer;
  @media ${device.tablet} {
    font-size: 1.8rem;
  }
`;
