import brandingDark from 'assets/icons/MLogoBlack.svg';
import tournament from 'assets/icons/tournament.svg';
import AvatarUser from 'components/auth/components/AvatarUser';
import Hamburger from 'components/Hamburger/Hamburger';
import IconWithText from 'components/IconWithText/IconWithText';
import ComponentPageHeaderItems from 'layouts/ComponentPageHeader/components/ComponentPageHeaderElements/ComponentPageHeaderItems';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import {
  DEFAULTQUERIES,
  RECOMMENDATIONS,
  ROOT,
  TOURNAMENT_LEADER_BOARD,
} from 'routes/makes/constants';
import { getUrlWithSpace } from 'routes/helper';

import * as S from './Header.styles';
import useIsMobile from 'hooks/useIsMobile';

const Header = ({ type, children, isTransparent }) => {
  const isMobile = useIsMobile();
  const logo = brandingDark;
  const userActiveTournament = useSelector(
    (state) => state?.auth?.user?.activeTournament
  );

  const renderRightContent = () => {
    if (type !== 'component') {
      return (
        <S.IconsContainer>
          <Hamburger />
          <AvatarUser />
          {renderTournament()}
        </S.IconsContainer>
      );
    } else return <ComponentPageHeaderItems />;
  };

  const renderTournament = () => {
    if (userActiveTournament) {
      return (
        <Link
          to={{
            pathname: getUrlWithSpace(TOURNAMENT_LEADER_BOARD),
            search: `${DEFAULTQUERIES.leaderBoard}`,
          }}
          aria-label="מעבר לטורניר"
        >
          <IconWithText icon={tournament} alt="tournament" ariaHidden="true">
            {'טורניר'}
          </IconWithText>
        </Link>
      );
    }
  };
  return (
    <>
      <header id={'header'}>
        <S.Nav
          isTransparent={isTransparent}
          aria-label="ניווט"
          noChildren={!children}
          isMobile={isMobile}
          type={type}
        >
          {renderRightContent()}
          <S.Logo aria-label="דף ראשי" to={{ pathname: ROOT }}>
            <S.LogoIcon aria-hidden="true" alt={'makes logo'} src={logo} />
          </S.Logo>
          <S.LeftContent>{children}</S.LeftContent>
        </S.Nav>
      </header>
    </>
  );
};

export default Header;
