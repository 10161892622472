import styled from 'styled-components/macro';

export const Link = styled.a`
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  font-size: 2em;
  font-weight: bold;
  padding: 10px;
  background: white;
  display: block;

  :focus {
    position: static;
    width: auto;
    height: auto;
  }
`;
