import styled from 'styled-components/macro';

export const ContentCreator = styled.img`
  visibility: ${({ image }) => !image && 'hidden'};
  width: 6rem;
  height: 6rem;
  outline: 2px solid white;
  border: 1px solid black;
  border-radius: 50%;
  margin: 1rem;
  align-self: flex-end;
  object-fit: cover;
`;
