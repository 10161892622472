import { useGetAllTopicsByTrackQuery } from 'services/api/makesApi/makesApi';

const useGetTopicsByTrack = (trackId) => {
  const { data: topics, isFetching } = useGetAllTopicsByTrackQuery(
    { trackId },
    {
      skip: !trackId,
    }
  );

  return {
    data: topics,
    isFetching,
  };
};
export default useGetTopicsByTrack;
