import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const ItemIconWrapper = styled.div`
  border: ${({ isSelected }) => (isSelected ? '4px' : '2px')} solid
    var(--color-charcoal-grey);
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  @media ${device.tablet} {
    width: 7rem;
    height: 7rem;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Link = styled(NavLink)`
  display: block;
  overflow: visible;
`;
