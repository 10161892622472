export const GET_ALL_COMMENTS = (componentId) => {
  return `comments/component/${componentId}`;
};
export const ADD_COMMENTS = (componentId) => {
  return `comments/component/${componentId}/addComment`;
};

export const DELETE_COMMENT = (componentId, commentId) => {
  return `comments/component/${componentId}/comment/${commentId}`;
};
