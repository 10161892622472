import { useEffect } from 'react';
import { useErrorContext } from 'context/errorContext';
import { useGetUnitsByOrganizationIdQuery } from 'services/api/makesApi/makesApi';
const useGetUnitsByOrganizationId = (organizationId) => {
  const { handleError } = useErrorContext();
  const {
    data,
    error: getUnitsByOrganizationError,
    isLoading,
  } = useGetUnitsByOrganizationIdQuery(
    { organizationId },
    {
      skip: !organizationId,
    }
  );
  useEffect(() => {
    if (getUnitsByOrganizationError) handleError(getUnitsByOrganizationError);
  }, [getUnitsByOrganizationError]);

  return { data, isLoading };
};

export default useGetUnitsByOrganizationId;
