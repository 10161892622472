import React from 'react';
import * as S from './OOPS_MSG.style';
import oops from 'assets/icons/oops.svg';

import RootModal from 'components/RootModal/RootModal';
const OOPS_MSG = ({ resetErrorCB }) => {
  const handleOnClick = () => {
    resetErrorCB();
  };
  return (
    <RootModal visible={true}>
      <S.Container>
        <div>
          <S.ImageContainer>
            <img src={oops} alt="oops" />
          </S.ImageContainer>
          <S.Title>אהה.. אופס..</S.Title>
          <S.Paragraph>נראה לנו שמשהו השתבש</S.Paragraph>

          <S.SubParagraph>אנחנו עובדים על לסדר את התקלה כמה שיותר מהר</S.SubParagraph>
        </div>
        <S.ButtonContainer onClick={() => handleOnClick()}>לעמוד הראשי</S.ButtonContainer>
      </S.Container>
    </RootModal>
  );
};

export default OOPS_MSG;
