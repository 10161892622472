import { useSelector } from 'react-redux';
import TopicList from './components/TopicList/TopicList';
import * as S from './SubNav.style';

const SubNav = ({ userProgresses, topics }) => {
  const isMobile = useSelector((state) => state.ui?.isMobile);

  return (
    <S.Container isMobile={isMobile} aria-label="ניווט טופקים">
      <TopicList topics={topics} userProgresses={userProgresses} />
    </S.Container>
  );
};

export default SubNav;
