import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUrlWithSpace } from 'routes/helper';
import { ROOT } from 'routes/makes/constants';

const ExcludeRoleRoute = ({ component: Component, excludedRole, ...rest }) => {
  const userRole = useSelector((state) => state.auth.user.role);

  return (
    <Route
      {...rest}
      render={(props) =>
        userRole !== excludedRole ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: ROOT, from: props.location }} />
        )
      }
    />
  );
};

export default ExcludeRoleRoute;
