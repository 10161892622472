import { useInsertFeedForUploadedMakeMutation } from 'services/api/makesApi/makesApi';
import { useErrorContext } from 'context/errorContext';
import { useEffect } from 'react';
const useInsertFeedForUploadedMake = () => {
  const [
    insertFeedbackForUploadedMake,
    { error: insertFeedbackForUploadedMakeError, isLoading },
  ] = useInsertFeedForUploadedMakeMutation();

  const { handleError } = useErrorContext();

  useEffect(() => {
    if (insertFeedbackForUploadedMakeError)
      handleError(insertFeedbackForUploadedMakeError);
  }, [insertFeedbackForUploadedMakeError]);
  return { insertFeedbackForUploadedMake, isLoading };
};

export default useInsertFeedForUploadedMake;
