import Navigation from 'components/Navigation/Navigation';
import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toggleHamburger } from 'services/redux/ui.slice';

import * as S from './hamburger.styles';
import useIsMobile from 'hooks/useIsMobile';

const Hamburger = () => {
  const isHamburgerOpen = useSelector((state) => state.ui.toggleHamburger);
  const isMobile = useIsMobile();
  const left = useSelector((state) => state.ui?.hamburgerIconsWrapperPosition?.left);
  const right = useSelector((state) => state.ui?.hamburgerIconsWrapperPosition?.right);

  const dispatch = useDispatch();

  const [showAnimation, setAnimation] = useState(false);

  const handleHamburgerClick = () => {
    if (!showAnimation) {
      setAnimation(true);
    }
    dispatch(toggleHamburger());
  };

  return (
    <>
      <S.Hamburger
        left={left}
        right={right}
        isMobile={isMobile}
        aria-controls="navigation-menu"
        key={isHamburgerOpen}
        isOpen={isHamburgerOpen}
        showAnimation={showAnimation}
        onClick={handleHamburgerClick}
        aria-label="פתיחת תפריט ראשי"
        aria-expanded={isHamburgerOpen ? 'true' : 'false'}
      >
        <S.HandleBars
          isOpen={isHamburgerOpen}
          showAnimation={showAnimation}
        ></S.HandleBars>
      </S.Hamburger>
      {isHamburgerOpen && <S.BackGroundOpacity isOpen={isHamburgerOpen} />}

      <S.NavigationContainer isOpen={isHamburgerOpen}>
        {isHamburgerOpen && <Navigation />}
        <S.ClickArea
          isOpen={isHamburgerOpen}
          isMobile={isMobile}
          onClick={handleHamburgerClick}
        />
      </S.NavigationContainer>
    </>
  );
};

export default Hamburger;
