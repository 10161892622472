import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  @media ${device.tablet} {
    flex-direction: row;
    column-gap: 2.5rem;
    padding: 0 1rem;
    row-gap: unset;
  }
`;
export const Wrapper = styled.div`
  > * {
    width: 100%;
    aspect-ratio: 1/1;
    max-height: 320px;
    border-radius: 18px;
    object-fit: cover;

    @media ${device.tablet} {
      width: 300px;
      height: 300px;
    }
  }
`;
