import { useEffect } from 'react';
import LvlUpModal from 'components/LvlUpModal';
import RootModal from 'components/RootModal/RootModal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import useGetTracks from 'hooks/queries/useGetTracks';
import SkipLink from 'components/SkipLink/SkipLink';
import DiscoveryLayout from 'layouts/DiscoveryLayout/DiscoveryLayout';
import { getTopicRoute, getUrlWithSpace } from 'routes/helper';
import { RECOMMENDATIONS } from 'routes/makes/constants';
import {
  useGetAllMakesByTrackIdQuery,
  usePrefetch,
} from 'services/api/makesApi/makesApi';
import { setCurrentTopic, setCurrentTrack } from 'services/redux/app.slice';
import { getSpecificObjFromArrayOfObjects } from 'utils/helpers';
import DesktopContent from './components/Content/DesktopContent/DesktopContent';
import MobileContent from './components/Content/MobileContent/MobileContent';
import SkeletonContent from './components/Content/SkeletonContent';
import useUpdateTrackXp from './hooks/useUpdateTrackXp';
import useIsMobile from 'hooks/useIsMobile';
const DiscoveryPage = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.auth?.user?._id);
  const space = useSelector((state) => state.app.space);

  const showLvlUpModal = useSelector((state) => state.ui.showLvlUpModal);
  const currentTrack = useSelector((state) => state.app?.currentTrack);
  const isMobile = useIsMobile();
  const trackId = currentTrack?._id;
  const { tracks } = useGetTracks({ space });
  const prefetchAllMakesByTrackId = usePrefetch('getAllMakesByTrackId');
  const { updateTrackXP } = useUpdateTrackXp();

  const { trackRoute, topicRoute } = params;

  const { data: makesData, isFetching } = useGetAllMakesByTrackIdQuery(
    { space, trackId },
    {
      skip: !trackId || !userId || !space,
    }
  );

  const isAnythingLoading = useSelector((state) => {
    const requests = Object.values(state.makesApi.queries);
    return requests.some((entry) => entry.status === 'pending');
  });

  useEffect(() => {
    //prefetching
    if (!isAnythingLoading && tracks) {
      Promise.all(
        tracks.map((track) => {
          const trackId = track._id;
          prefetchAllMakesByTrackId({ space, trackId });
        })
      );
    }
  }, [isAnythingLoading]);

  useEffect(() => {
    if (!tracks?.length) return;
    let currentTrack = getSpecificObjFromArrayOfObjects(tracks, 'trackRoute', trackRoute);
    const trackId = currentTrack?._id;

    if (trackId) updateTrackXP(trackId);
    if (!trackId) {
      if (space === 'main') return history.push(getUrlWithSpace(RECOMMENDATIONS));
      return history.push(getUrlWithSpace(`/track/${tracks[0].trackRoute}/`));
    }

    dispatch(setCurrentTrack(currentTrack));
  }, [tracks, trackRoute, space]);

  useEffect(() => {
    if (makesData) {
      const currentTopic = makesData.topicsData.find(
        (topic) => topic.topicRoute === topicRoute
      );
      if (!currentTopic || !topicRoute) {
        return history.push(
          getTopicRoute(trackRoute, makesData.topicsData[0].topicRoute)
        );
      }

      dispatch(setCurrentTopic(currentTopic));
    }
  }, [makesData, topicRoute]);

  const renderChildren = () => {
    if (!isFetching && tracks && isMobile) return <MobileContent />;
    if (!isFetching && tracks && !isMobile && makesData)
      return <DesktopContent makesData={makesData} />;
    if (!isFetching || !makesData) return <SkeletonContent />;
    return null;
  };

  return (
    <>
      {showLvlUpModal && (
        <RootModal visible={showLvlUpModal} isScrollable={false} isOpacity>
          <LvlUpModal textColor={'white'} background={'black'} bgImageOpacity={'0.5'} />
        </RootModal>
      )}
      <SkipLink id={'main'} />
      <DiscoveryLayout tracks={tracks} topics={makesData?.topicsData}>
        {renderChildren()}
      </DiscoveryLayout>
    </>
  );
};

export default DiscoveryPage;
