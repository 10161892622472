import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';

export const MakeDetails = styled.div`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  text-align: right;
  row-gap: 0.5rem;
  justify-content: space-between;
  background-color: var(--color-white);
`;

export const MakeName = styled.h2`
  font-family: ${primaryFont.bold};
  margin: unset;
  font-size: 1.6rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;

  @media ${device.tablet} {
    font-size: 2rem;
  }
`;
export const MakeSubTitle = styled.p`
  font-family: ${primaryFont.regular};
  font-size: 1.2rem;
  @media ${device.tablet} {
    font-size: 1.4rem;
  }
`;

export const TitleContainer = styled.div``;
export const IconsList = styled.ul`
  display: flex;
`;
export const IconContainer = styled.li`
  display: flex;
`;

export const Icon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
`;
export const IconDescription = styled.p`
  font-family: ${primaryFont.bold};
  font-size: 1.15rem;
  margin: 0 0.25rem 0 1rem;
`;
export const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
