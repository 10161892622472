import { createGlobalStyle } from 'styled-components';
const GlobalStyle = createGlobalStyle`
:root {
  --color-charcoal-grey: #47434d;
  --color-dark-charcoal: #313443;
  --color-white: #fff;
  --color-black: #000;
  --color-charcoal: #25202c;
  --color-light-charcoal: #272431;
  --color-mid-grey: #bfbfbf;
  --color-light-blue: #67cddc;
  --color-background: #f7f7f7;
  --color-dark-grey: #8d8d8d;
  --color-dark-blue: #2a9cad;
  --color-blue: #58b2d3;
  --color-red: #d35883;
  --color-light-grey: #d2d2d2d2;
  --color-green: #58d39f;
  --color-light-green: #2ff116;
  --color-light-black: #3b3742;
  --color-grey: #dadada;
  --color-black-background: #161b22;
  --color-orange: #ffb600;
  --color-orange-mid: #fa0;
  --color-yellow: #fcd75b;
  --color-pink: #e567a2;
  /* additional */
  --color-dark-green: #158906;
  --color-purple: #a700ff;
  --accessibility-background: transparent;
}
html.accessibility{
  --color-green:#158906;
  --color-mid-grey:#757575;
  --color-light-blue:#218291;
  --color-red:#CD4272;
  --color-dark-blue:#238190;
  --color-blue:#2A7E9D;
  --accessibility-background:#292929;


}
*:focus{
  outline:4px solid blue;
}
`;
export default GlobalStyle;
