import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';

export const FeedbackContainer = styled.div`
  text-align: center;
  display: flex;
  padding: 3rem;
  color: var(--color-white);
  flex-direction: column;
  align-items: center;
`;
export const TitleFeedBack = styled.h3`
  font-size: 2.4rem;
  margin-top: 0.9rem;
  font-family: ${primaryFont.bold};
`;
export const SubTitle = styled.p`
  font-size: 1.4rem;
  font-family: ${primaryFont.regular};
  margin-top: 0.3rem;
`;

export const FeedbackButton = styled.button`
  border-radius: 1rem;
  background-color: var(--color-white);
  border: 1px solid var(--color-light-grey);
  width: 75.6%;
  margin-top: 5rem;
  padding: 1rem 0;
  font-family: ${primaryFont.bold};
  font-size: 2rem;
  height: 5rem;
  &:hover {
    cursor: pointer;
    background: var(--color-light-grey);
  }
`;
