import { getData } from 'apis/index';
import axios from 'axios';
import { generateNotificationData } from 'utils/helpers/pushNotification';

const API_KEY = process.env.REACT_APP_PUSH_ALERT_API_KEY;
const BASE_URL = 'https://api.pushalert.co/rest/v1';

export const sendNotification = (scenario, data, currentSubscriber) => {
  const dataObj = generateNotificationData(scenario, data);

  if (currentSubscriber) {
    dataObj['subscriber'] = window.PushAlertCo.subs_id;
  }

  return axios
    .post(`${BASE_URL}/send`, dataObj, {
      headers: {
        Authorization: `api_key=${API_KEY}`,
      },
    })
    .then(getData);
};

export const triggerPushAlertAllowNotification = () => {
  window.PushAlertCo?.triggerMe(true);
};
