import { configureStore } from '@reduxjs/toolkit';
import authSlice from 'services/redux/auth.slice';

import activityReducer from 'components/auth/userActivity.slice';
import { combineReducers } from 'redux';
import { makesApi } from 'services/api/makesApi/makesApi';
import { facilitatorApi } from 'services/api/facilitatorApi/facilitatorApi';
import appSlice from './app.slice';
import uiSlice from './ui.slice';
import { logout } from 'services/redux/auth.slice';

const UnAuthorizedMiddleWare = () => (storeAPI) => (next) => (action) => {
  if (
    action.type.endsWith('rejected') &&
    action.payload &&
    action.payload.success === false &&
    action.payload.message === 'Unauthorized'
  ) {
    localStorage.removeItem('token');
    storeAPI.dispatch(logout());
    window.history.href = '/';
  }
  return next(action);
};

const reducers = combineReducers({
  [makesApi.reducerPath]: makesApi.reducer,
  [facilitatorApi.reducerPath]: facilitatorApi.reducer,

  app: appSlice,
  userActivity: activityReducer,
  ui: uiSlice,
  auth: authSlice,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(makesApi.middleware)
      .concat(facilitatorApi.middleware)
      .concat(UnAuthorizedMiddleWare()),
  devTools: process.env.NODE_ENV !== 'production',
});
