import { useEffect, useRef } from 'react';

const useTrapFocus = (focusRef, isOpen = true) => {
  const firstFocusableElementRef = useRef(null);
  const lastFocusableElementRef = useRef(null);

  useEffect(() => {
    if (!isOpen || !focusRef?.current) {
      return;
    }

    const handleKeyDown = (event) => {
      if (event.key === 'Tab' || event.keyCode === 9) {
        // Get all focusable elements inside the menu
        const focusableElements = focusRef.current.querySelectorAll(
          'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
        );

        // Check if there are any focusable elements inside the menu
        if (focusableElements.length > 0) {
          // Get the first and last focusable elements
          const firstFocusableElement = focusableElements[0];
          const lastFocusableElement = focusableElements[focusableElements.length - 1];

          if (event.shiftKey && document.activeElement === firstFocusableElement) {
            // If the Shift key is pressed and the focus is on the first element, focus the last element
            lastFocusableElement.focus();
            event.preventDefault();
          } else if (!event.shiftKey && document.activeElement === lastFocusableElement) {
            // If the Shift key is not pressed and the focus is on the last element, focus the first element
            firstFocusableElement.focus();
            event.preventDefault();
          }
        }
      }
    };

    // Save the first and last focusable elements in refs
    const focusableElements = focusRef.current.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
    );
    if (focusableElements.length > 0) {
      firstFocusableElementRef.current = focusableElements[0];
      lastFocusableElementRef.current = focusableElements[focusableElements.length - 1];
    }

    // Add event listener for the Tab key
    document.addEventListener('keydown', handleKeyDown);

    // Return a function to clean up the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, focusRef]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    // Focus the first focusable element inside the menu when it is opened
    if (firstFocusableElementRef.current) {
      firstFocusableElementRef.current.focus();
    }
  }, [isOpen]);
};

export default useTrapFocus;
