import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetAllMakesByTrackIdQuery } from 'services/api/makesApi/makesApi';

const useGetAllMakesByTrack = (trackToFetch, setAllMakes) => {
  const [allTopics, setAllTopics] = useState([]);

  const userId = useSelector((state) => state.auth.user._id);
  const space = useSelector((state) => state.app.space);

  const { data: makesData, isFetching } = useGetAllMakesByTrackIdQuery(
    { userId, space, trackId: trackToFetch },
    {
      skip: !trackToFetch,
    }
  );

  useEffect(() => {
    if (!makesData) return;

    const makes = makesData.makes.filter((make) => !make.isCampaign);
    //if makesData.topicsData is included in all topics, dont add it

    if (!allTopics.some((topic) => topic._id === makesData.topicsData[0]._id)) {
      setAllTopics((prev) => [...prev, ...makesData.topicsData]);
    }
    setAllMakes((prev) => [...prev, ...makes]);
  }, [makesData]);

  return { allTopics, makesData, isFetching };
};
export default useGetAllMakesByTrack;
