import Track from 'components/Track/Track';
import * as S from './Card.styles';
import useGetTopicsByTrack from 'hooks/queries/useGetTopicsByTrack';
import Topic from 'components/Topic/Topic';
import Progress from 'components/Progress/Progress';
import { Link } from 'react-router-dom';
import { useGuideContext } from 'features/Guide/context/guideContext';
const Card = ({ track, studentId }) => {
  const trackId = track._id;
  const { data: topics } = useGetTopicsByTrack(trackId);
  const { organization, unitId } = useGuideContext();

  return (
    <S.Container>
      <Progress item={track} itemKey={'trackId'} userId={studentId}>
        {({ progress }) => {
          return (
            <Track progress={progress} trackName={track.trackName} icon={track.icon} />
          );
        }}
      </Progress>
      {topics && (
        <S.TopicsList>
          {topics.map((topic) => {
            return (
              <Link
                key={topic._id}
                to={{
                  pathname: `/guide/organization/${organization?._id}/unit/${unitId}/student/${studentId}/topic/${topic._id}`,
                }}
              >
                <li>
                  <Progress item={topic} itemKey={'topicId'} userId={studentId}>
                    {({ progress }) => {
                      return <Topic topicName={topic.topicName} progress={progress} />;
                    }}
                  </Progress>
                </li>
              </Link>
            );
          })}
        </S.TopicsList>
      )}
    </S.Container>
  );
};

export default Card;
