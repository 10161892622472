import { useGetTournamentByIdQuery } from 'services/api/makesApi/makesApi';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useErrorContext } from 'context/errorContext';
const useGetTournamentById = (tournamentId) => {
  const { handleError } = useErrorContext();
  const {
    data: tournament,
    error: getTournamentError,
    isLoading,
  } = useGetTournamentByIdQuery(tournamentId, {
    skip: !tournamentId,
  });
  useEffect(() => {
    if (getTournamentError) handleError(getTournamentError);
  }, [getTournamentError]);

  return { tournament, isLoading };
};

export default useGetTournamentById;
