import { useToggleUploadedMakeOpenStatusMutation } from 'services/api/makesApi/makesApi';
import { useErrorContext } from 'context/errorContext';
import { useEffect } from 'react';
const useToggleUploadedMakeOpenStatus = () => {
  const [toggleUploadedMakeOpenStatus, { error: toggleUploadedMakeOpenStatusError }] =
    useToggleUploadedMakeOpenStatusMutation();

  const { handleError } = useErrorContext();

  useEffect(() => {
    if (toggleUploadedMakeOpenStatusError) handleError(toggleUploadedMakeOpenStatusError);
  }, [toggleUploadedMakeOpenStatusError]);
  return { toggleUploadedMakeOpenStatus };
};

export default useToggleUploadedMakeOpenStatus;
