import { useEffect } from 'react';
import { useErrorContext } from 'context/errorContext';
import { useGetOrganizationByIdQuery } from 'services/api/makesApi/makesApi';
const useGetOrganizationById = (organizationId) => {
  const { handleError } = useErrorContext();
  const {
    data,
    error: getOrganizationByIdError,
    isLoading,
  } = useGetOrganizationByIdQuery(
    { organizationId },
    {
      skip: !organizationId,
    }
  );
  useEffect(() => {
    if (getOrganizationByIdError) handleError(getOrganizationByIdError);
  }, [getOrganizationByIdError]);

  return { data, isLoading };
};

export default useGetOrganizationById;
