export const ROLES = {
  GUEST: 'guest',
  USER: 'user',
  CMS: 'cms',
  FACILITATOR: 'facilitator',
  SUPER_USER: 'super_user',
  GUIDE: 'guide',
  STUDENT: 'student',
};
export const canSeeFacilitator = (role) => {
  return role === ROLES.SUPER_USER || role === ROLES.FACILITATOR;
};
export const canSeeGuide = (role) => {
  return role === ROLES.SUPER_USER || role === ROLES.GUIDE;
};

export const canSeeUnpublishedMakes = (role) =>
  role === ROLES.SUPER_USER || role === ROLES.CMS;

export const canSeeUnitOnBoarding = (role) => role !== ROLES.STUDENT;
export const canSeeUnitSignIn = (role) =>
  role === ROLES.USER || role === ROLES.GUEST || role === ROLES.SUPER_USER;
