export const SVG_XP_NUMBER_ELEMENTS = {
  mobile: [
    { number: 0, innerCircle: 'Path_33236', outerCircle: 'Ellipse_2964' },
    { number: 1, innerCircle: 'Path_33234', outerCircle: 'Ellipse_2962' },
    { number: 2, innerCircle: 'Path_33232', outerCircle: 'Ellipse_2960' },
    { number: 3, innerCircle: 'Path_33230', outerCircle: 'Ellipse_2958' },
    { number: 4, innerCircle: 'Path_33228', outerCircle: 'Ellipse_2956' },
    { number: 5, innerCircle: 'Path_33226', outerCircle: 'Ellipse_2954' },
    { number: 6, innerCircle: 'Path_33224', outerCircle: 'Ellipse_2952' },
    { number: 7, innerCircle: 'Path_33222', outerCircle: 'Ellipse_2950' },
    { number: 8, innerCircle: 'Path_33220', outerCircle: 'Ellipse_2948' },
    { number: 9, innerCircle: 'Path_33218', outerCircle: 'Ellipse_2946' },
    { number: 10, innerCircle: 'Path_33215', outerCircle: 'Ellipse_2944' },
  ],
  desktop: [
    { number: 0, innerCircle: 'Path_33172', outerCircle: 'Ellipse_2959' },
    { number: 1, innerCircle: 'Path_33169', outerCircle: 'Ellipse_2958' },
    { number: 2, innerCircle: 'Path_33166', outerCircle: 'Ellipse_2957' },
    { number: 3, innerCircle: 'Path_33163', outerCircle: 'Ellipse_2956' },
    { number: 4, innerCircle: 'Path_33160', outerCircle: 'Ellipse_2955' },
    { number: 5, innerCircle: 'Path_33157', outerCircle: 'Ellipse_2954' },
    { number: 6, innerCircle: 'Path_33154', outerCircle: 'Ellipse_2953' },
    { number: 7, innerCircle: 'Path_33151', outerCircle: 'Ellipse_2952' },
    { number: 8, innerCircle: 'Path_33148', outerCircle: 'Ellipse_2951' },
    { number: 9, innerCircle: 'Path_33145', outerCircle: 'Ellipse_2950' },
    { number: 10, innerCircle: 'Path_33141', outerCircle: 'Ellipse_2949' },
  ],
};

export const SVG_XP_PATH_ELEMENTS = {
  mobile: [
    {
      position: '0-1',
      path: 'Path_33211',
    },
    {
      position: '1-2',
      path: 'Path_33212',
    },
    {
      position: '2-3',
      path: 'Path_33213',
    },
    {
      position: '3-4',
      path: 'Path_33214',
    },
    {
      position: '4-5',
      path: 'Path_33210',
    },
    {
      position: '5-6',
      path: 'Path_33209',
    },
    {
      position: '6-7',
      path: 'Path_33208',
    },
    {
      position: '7-8',
      path: 'Path_33206',
    },
    {
      position: '8-9',
      path: 'Path_33207',
    },
    {
      position: '9-10',
      path: 'Path_33205',
    },
  ],
  desktop: [
    {
      position: '0-1',
      path: 'Path_33137',
      // width: 484,
    },
    {
      position: '1-2',
      path: 'Path_33138',
    },
    {
      position: '2-3',
      path: 'Path_33139',
    },
    {
      position: '3-4',
      path: 'Path_33140',
    },
    {
      position: '4-5',
      path: 'Path_33136',
    },
    {
      position: '5-6',
      path: 'Path_33135',
    },
    {
      position: '6-7',
      path: 'Path_33134',
    },
    {
      position: '7-8',
      path: 'Path_33132',
    },
    {
      position: '8-9',
      path: 'Path_33133',
    },
    {
      position: '9-10',
      path: 'Path_33131',
    },
  ],
};

export const XP_ICON_POSITIONING = {
  mobile: {
    side: [41, 24],
    top: [115, 188, 268, 344, 424, 507, 589, 673, 750, 784],
  },
  desktop: {
    side: [76, 322],
    top: [243, 391, 536, 685, 833, 985, 1137, 1293, 1437, 1510],
  },
};

export const levels = [
  { level: 0, rankName: 'מייקר מתחיל', startPoints: 0 },
  { level: 1, rankName: 'חכמולוג', startPoints: 250 },
  { level: 2, rankName: 'מיני מייקר', startPoints: 600 },
  { level: 3, rankName: 'מייקר', startPoints: 1000 },
  { level: 4, rankName: 'מגה מייקר', startPoints: 1688 },
  { level: 5, rankName: 'מאסטר מייקר', startPoints: 2531 },
  { level: 6, rankName: 'גאון', startPoints: 3797 },
  { level: 7, rankName: 'מוח על חלל', startPoints: 5695 },
  { level: 8, rankName: 'פרופסור', startPoints: 8543 },
  { level: 9, rankName: 'מאסטרו', startPoints: 12814 },
  { level: 10, rankName: 'גורו', startPoints: 19221 },
];

export const XP_PATH_ANIMATION_DURATION = 1;
export const INITAL_ANIMATION_DELAY = { path: 1 };

//XP Actions
export const FIRST_TIME_LOGIN = 'first_time_login';
export const FIRST_TIME_TRACK = 'first_time_track';
export const NEW_COMPONENT_EASY = 'new_component_easy';
export const NEW_COMPONENT_MEDIUM = 'new_component_medium';
export const NEW_COMPONENT_HARD = 'new_component_hard';
export const MAKE_SUBMISSION_EASY = 'make_submission_easy';
export const MAKE_SUBMISSION_MEDIUM = 'make_submission_medium';
export const MAKE_SUBMISSION_HARD = 'make_submission_hard';
export const MAKE_SUBMISSION_SHARING = 'make_submission_sharing';
export const FINISH_ONBOARDING_MAKE = 'finish_onBoarding_make';
export const TOURNAMENT_SEEN = 'tournament_seen';
