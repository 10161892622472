import CircularProgressBar from 'components/CircualProgressBar/CircualProgressBar';
import * as S from './Track.styles';
import { useSelector } from 'react-redux';
import useIsMobile from 'hooks/useIsMobile';

const Track = ({ icon, progress, isSelected, trackName }) => {
  const isMobile = useIsMobile();

  return (
    <S.ProgressWrapper>
      <CircularProgressBar
        strokeWidth={progress ? 6 : 0}
        sqSize={isMobile ? 60 : 70}
        isSelected={isSelected}
        percentage={Number(progress.toFixed(0))}
      >
        {icon && <S.ItemIcon aria-hidden="true" src={icon} alt={'טראק ' + trackName} />}
      </CircularProgressBar>
      <S.ItemName>{trackName}</S.ItemName>
    </S.ProgressWrapper>
  );
};

export default Track;
