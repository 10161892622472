import * as S from './MakeStatus.style';

const MakeStatus = ({ statusIcon, backgroundColor, color, value }) => {
  return (
    <S.MakeStatus backgroundColor={backgroundColor} color={color}>
      <img aria-hidden="true" alt="מייק סטטוס" src={statusIcon} />
      {value}
    </S.MakeStatus>
  );
};

export default MakeStatus;
