import { useLocation } from 'react-router-dom';

const useNavigationPath = () => {
  const location = useLocation();
  const { pathname } = location;

  const getBreadcrumbName = (pathSegment) => {
    const map = {
      unit: 'יחידה',
      topic: 'טופיק',
      student: 'שם התלמיד',
    };
    return map[pathSegment];
  };

  // Split the path into segments, filter out any empty strings, and map them to breadcrumb names
  const pathSegments = [
    'ארגון',
    ...pathname
      .split('/')
      .filter(Boolean)
      .map(getBreadcrumbName)
      .filter((item) => item),
  ];

  return pathSegments;
};

export default useNavigationPath;
