import RootModal from 'components/RootModal/RootModal';
import * as S from './Modal.style';

const Modal = ({ visible, children }) => {
  return (
    <RootModal visible={visible}>
      <S.Container>
        <S.ContentBox>{children}</S.ContentBox>
      </S.Container>
    </RootModal>
  );
};

export default Modal;
