import { useEffect, useState } from 'react';
const useIsIOS = () => {
  const [isIOS, setIsIOS] = useState(false);
  useEffect(() => {
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      setIsIOS(true);
    }
  }, []);

  return { isIOS };
};
export default useIsIOS;
