import React, { lazy } from 'react';
import {
  GUIDE_ORGANIZATION,
  GUIDE_STUDENT_PAGE,
  GUIDE_TOPIC_PAGE,
  GUIDE_UNIT,
} from './constants';
import { ROLES } from '../auth/constants';
import { GuideProvider } from 'features/Guide/context/guideContext';
import StudentPage from 'features/Guide/pages/StudentPage/StudentPage';
import TopicPage from 'features/Guide/pages/TopicPage/TopicPage';

const OrganizationPage = lazy(() =>
  import('features/Guide/pages/OrganizationPage/OrganizationPage')
);
const UnitPage = lazy(() => import('features/Guide/pages/UnitPage/UnitPage'));

const OrganizationPageWithProvider = () => {
  return (
    <GuideProvider>
      <OrganizationPage />
    </GuideProvider>
  );
};
const UnitPageWithProvider = () => {
  return (
    <GuideProvider>
      <UnitPage />
    </GuideProvider>
  );
};
const StudentPageWithProvider = () => {
  return (
    <GuideProvider>
      <StudentPage />
    </GuideProvider>
  );
};
const TopicPageWithProvider = () => {
  return (
    <GuideProvider>
      <TopicPage />
    </GuideProvider>
  );
};

const guideRoutes = [
  {
    path: GUIDE_TOPIC_PAGE,
    component: TopicPageWithProvider,
    private: true,
    requiredRoles: [ROLES.GUIDE, ROLES.SUPER_USER],
    exact: true,
  },
  {
    path: GUIDE_STUDENT_PAGE,
    component: StudentPageWithProvider,
    private: true,
    requiredRoles: [ROLES.GUIDE, ROLES.SUPER_USER],
    exact: true,
  },
  {
    path: GUIDE_UNIT,
    component: UnitPageWithProvider,
    private: true,
    requiredRoles: [ROLES.GUIDE, ROLES.SUPER_USER],
    exact: true,
  },
  {
    path: GUIDE_ORGANIZATION,
    component: OrganizationPageWithProvider,
    private: true,
    requiredRoles: [ROLES.GUIDE, ROLES.SUPER_USER],
    exact: true,
  },
];
export default guideRoutes;
