import VideoPlayer from 'components/VideoPlayer';
import Modal from '../../../../../../../components/Modal/Modal';
import * as S from './VideoGuide.style';
import closeModal from 'assets/icons/facilitatorDashboard/closeModal.svg';

const VideoGuide = ({ setShowVideoGuide }) => {
  return (
    <Modal visible={true}>
      <S.Container>
        <VideoPlayer url={''} controls={true} />
        <S.TextContainer>
          <S.SmallText>הכל נראה מגניב וזה</S.SmallText>
          <S.BigText>אבל מה עושים כאן?</S.BigText>
        </S.TextContainer>
        <S.CloseBtn onClick={() => setShowVideoGuide(false)}>
          <S.Icon src={closeModal} alt="כפתור סגירה" />
          <S.CloseText>סגור</S.CloseText>
        </S.CloseBtn>
      </S.Container>
    </Modal>
  );
};

export default VideoGuide;
