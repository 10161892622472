import { useEffect, useState, useRef } from 'react';

import usePrevious from 'hooks/usePrevious';
import { createPortal } from 'react-dom';

import * as S from './PopupModall.styles';
import useTrapFocus from 'hooks/useTrapFocus';

const PopupModal = ({
  backgroundColor,
  width,
  duration,
  height,
  visible,
  children,
  bottom,
  animation,
  role,
}) => {
  const modalRoot = document.getElementById('popup-modal');
  const [animationType, setAnimationType] = useState('leave');
  const prevPropsVisible = usePrevious(visible);
  const popupRef = useRef(null);

  useTrapFocus(popupRef);

  useEffect(() => {
    if (visible) {
      enter();
    }
  });

  useEffect(() => {
    if (visible && !prevPropsVisible) {
      enter();
    }
    if (!visible && prevPropsVisible) {
      leave();
    }
  }, [visible]);

  const enter = () => {
    setAnimationType('enter');
  };

  const leave = () => {
    return setAnimationType('leave');
  };

  return createPortal(
    <S.ModalContainerParent>
      <S.ModalContainer
        ref={popupRef}
        role={role}
        bgColor={backgroundColor}
        duration={duration}
        heightStyle={height}
        widthStyle={width}
        bottomStyle={bottom}
        animationAttr={`${animation}-${animationType}`}
        tabIndex={-1}
        aria-modal="true"
      >
        {children}
      </S.ModalContainer>
    </S.ModalContainerParent>,
    modalRoot
  );
};

export default PopupModal;
