import React, { useRef, useEffect } from 'react';
import useTrapFocus from 'hooks/useTrapFocus.js';

import LvlUpBackground from 'assets/icons/lvlUpBackground.svg';
import LvlUpMedalTagIcon from 'assets/icons/lvlUpMedalTag.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setShowLvlUpModal } from 'services/redux/ui.slice.js';

import * as S from './LvlUpModal.style.js';
import { getRankIcon } from 'utils/helpers/index.js';
import VisuallyHidden from 'components/VisuallyHidden/VisuallyHidden.js';
import useIsMobile from 'hooks/useIsMobile.js';
const LvlUpModal = ({ textColor, background, bgImageOpacity }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const rank = useSelector((state) => state.auth.user.rank);
  const buttonRef = useRef(null);
  const containerRef = useRef(null);
  useTrapFocus(containerRef);
  useEffect(() => {
    buttonRef.current.focus();
  }, [buttonRef.current]);
  return (
    <S.LvlUpContainer
      ref={containerRef}
      color={textColor}
      background={background}
      onClick={() => dispatch(setShowLvlUpModal(false))}
    >
      <S.BackgroundImage
        aria-hidden="true"
        bgImageOpacity={bgImageOpacity}
        src={LvlUpBackground}
        alt="background"
      />
      <S.CongratsText>
        <S.SecondaryText>{'כל הכבוד!'}</S.SecondaryText>
        <S.PrimaryText>{'עלית רמה'}</S.PrimaryText>
      </S.CongratsText>
      <S.MedalContainer isMobile={isMobile}>
        <S.RankIcon aria-hidden="true" src={getRankIcon(rank)} alt="rank" />
        <S.MedalTag aria-hidden="true" src={LvlUpMedalTagIcon} alt="medal" />
        <S.Level>{rank}</S.Level>
      </S.MedalContainer>
      {/* //TODO For Points Feature - UI READY */}
      {/* <S.RewardWrapper>
        <S.RewardContainer>
          <S.PointsWon>{'x100'}</S.PointsWon>
          <S.Bonus>{'בונוס עלית רמה'}</S.Bonus>
        </S.RewardContainer>
        <img src={coinIcon} alt="coin" />
      </S.RewardWrapper> */}
      <S.ClaimButton
        ref={buttonRef}
        color={textColor}
        onClick={() => dispatch(setShowLvlUpModal(false))}
      >
        <VisuallyHidden>{'לחץ כדי לקבל נקודות ולעבור לרמה הבאה'}</VisuallyHidden>
        {'לרמה הבאה!'}
      </S.ClaimButton>
    </S.LvlUpContainer>
  );
};

export default LvlUpModal;
