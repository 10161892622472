import {
  getBackgroundColor,
  getStatusIcon,
  getStatusValue,
} from 'features/Guide/utils/makeDetails';
import * as S from './MakeItem.styles';
import AccordionOption from '../AccordionOption/AccordionOption';
import AccordionContent from '../AccordionContent/AccordionContent';
import useToggleUploadedMakeOpenStatus from 'features/Guide/hooks/useToggleUploadedMakeOpenStatus';

const MakeItem = ({ openIndexes, userProgresses, make, index, handleItemClick }) => {
  const { toggleUploadedMakeOpenStatus } = useToggleUploadedMakeOpenStatus();
  const userProgressByMake = userProgresses.find(
    (progress) => progress.makeId === make._id
  );
  const isOpen = openIndexes.includes(index);
  const {
    status: progressStatus,
    uploadedMake,
    _id: progressId,
  } = userProgressByMake || {};
  const backgroundColor = getBackgroundColor(progressStatus);
  const statusIcon = getStatusIcon(progressStatus);
  const value = getStatusValue(progressStatus);
  const isSeenByGuide = uploadedMake?.isSeenByGuide;

  const handleOnClick = () => {
    !isSeenByGuide && toggleUploadedMakeOpenStatus({ progressId });
    handleItemClick(index);
  };

  return (
    <S.MakeItem key={make.makeName}>
      <AccordionOption
        isOpen={isOpen}
        handleOnClick={handleOnClick}
        name={make.makeName}
        progress={userProgressByMake}
        value={value}
        disabled={!uploadedMake}
        backgroundColor={backgroundColor}
        statusIcon={statusIcon}
        isSeenByGuide={isSeenByGuide}
      />
      {isOpen && <AccordionContent uploadedMake={uploadedMake} progressId={progressId} />}
    </S.MakeItem>
  );
};

export default MakeItem;
