import { RECOMMENDATION_ID } from 'config/constants/constants';
import useGetTracks from 'hooks/queries/useGetTracks';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const useJumpToMake = (swiper, setAllMakes, allMakes, allTopics) => {
  const currentTrack = useSelector((state) => state.app?.currentTrack);
  const currentTopic = useSelector((state) => state.app?.currentTopic);
  const topicId = useSelector((state) => state.app?.currentTopic?._id);
  const space = useSelector((state) => state.app?.space);

  const initalMount = useRef(true);

  const { tracks } = useGetTracks({ space });

  //need to sync my eventhandler cb function with current state of redux and state.
  const currentTrackRef = useRef(currentTrack);

  currentTrackRef.current = currentTrack;

  const swiperRef = useRef(swiper);
  swiperRef.current = swiper;

  const currentTopicRef = useRef(currentTopic);
  currentTopicRef.current = currentTopic;

  const allMakesRef = useRef(allMakes);
  allMakesRef.current = allMakes;

  const allTopicsRef = useRef(allTopics);
  allTopicsRef.current = allTopics;

  useEffect(() => {
    if (!swiper || !initalMount.current || !allMakes.length) return;

    const firstMakeIndex = allMakes.findIndex((make) => {
      return make.topicId === topicId || make.swiperTopicId === topicId;
    });
    initalMount.current = false;

    swiper.slideTo(firstMakeIndex, 0, false);
  }, [swiper, allMakes]);

  useEffect(() => {
    const handleClick = (e) => {
      const hrefLink = e.target?.closest('a')?.getAttribute('href');
      if (!hrefLink) return;
      const splitHref = hrefLink?.split('/');
      const isTrack = splitHref.includes('track') && !splitHref.includes('topic');
      const isTopic = hrefLink?.split('/').includes('topic') && !isTrack;
      if (isTrack && currentTrackRef?.current) {
        const route = splitHref[splitHref.length - 1];

        // if (currentTrackRef.current.trackRoute === route && route !== RECOMMENDATIONS)
        //   return;

        const track = tracks.find((track) => track.trackRoute === route);

        const filterMakesOfCurrentTrack = allMakesRef.current.filter((make) => {
          if (track._id === RECOMMENDATION_ID) {
            return make.topicNameFromRecommendation;
          } else {
            return make.trackId === track._id && !make.topicNameFromRecommendation;
          }
        });

        setAllMakes(filterMakesOfCurrentTrack);
        swiperRef.current.slideTo(0, 0, false);
      }
      if (isTopic && swiperRef?.current?.slides) {
        const topicId = allTopicsRef.current.find((topic) => {
          return topic.topicRoute === splitHref[splitHref.length - 1];
        })?._id;

        const activeSlide = swiperRef.current.slides[swiperRef.activeIndex];
        const activeSlideTopicId = activeSlide?.getAttribute('data-topic');
        if (activeSlideTopicId === topicId) return;
        const firstMakeIndex = allMakesRef.current.findIndex((make) => {
          return make.topicId === topicId || make.swiperTopicId === topicId;
        });

        swiperRef.current.slideTo(firstMakeIndex, 0, true);
      }
    };
    document.addEventListener('click', (e) => handleClick(e));

    return () => document.removeEventListener('click', handleClick);
  }, []);
};
export default useJumpToMake;
