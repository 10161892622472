import { useState } from 'react';

const Accordion = (props) => {
  const [openIndexes, setOpenIndexes] = useState(props.openIndexes || [0]);

  const handleItemClick = (index) => {
    setOpenIndexes(() => {
      const closing = openIndexes.includes(index);
      return closing ? openIndexes.filter((i) => i !== index) : [...openIndexes, index];
    });
  };
  return props.children({
    openIndexes,
    handleItemClick: handleItemClick,
  });
};

export default Accordion;
