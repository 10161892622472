import styled from 'styled-components/macro';
/* new */
export const App = styled.div`
  height: 100%;
  width: inherit;
  direction: rtl;
`;

export const Container = styled.div`
  display: flex;
  height: inherit;
  width: inherit;
  justify-content: center;
`;

export const Makes = styled.div`
  position: relative;
  scroll-behavior: smooth;
  height: inherit;
  max-width: unset;
  width: 100vw;
  //! changed
  /* background: #161b22; */
  background: inherit;
  display: flex;
  flex-direction: column;
`;
