import Accordion from 'components/Accordion/Accordion';
import * as S from './MakesAccordion.styles';
import MakeItem from '../MakeItem/MakeItem';

const MakesAccordion = ({ makes, userProgresses }) => {
  return (
    <Accordion openIndexes={[]}>
      {({ openIndexes, handleItemClick }) => {
        return (
          <S.List>
            {makes.map((make, index) => {
              return (
                <MakeItem
                  key={make.makeName}
                  make={make}
                  userProgresses={userProgresses}
                  openIndexes={openIndexes}
                  handleItemClick={handleItemClick}
                  index={index}
                />
              );
            })}
          </S.List>
        );
      }}
    </Accordion>
  );
};

export default MakesAccordion;
