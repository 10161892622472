import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTrackRoute } from 'routes/helper';
import useGetTracks from 'hooks/queries/useGetTracks';
import { toggleHamburger } from 'services/redux/ui.slice';

import * as S from './HamburgerNavBar.styles';

const HamburgerNavbar = () => {
  const dispatch = useDispatch();
  const space = useSelector((state) => state.app?.space);

  const { tracks } = useGetTracks({ space });

  const NavLinks = (
    <>
      {tracks &&
        tracks.map((track) => {
          const { _id: trackId, trackName, trackRoute } = track;
          let path = getTrackRoute(trackRoute);
          return (
            <li key={trackId}>
              <S.Link
                exact
                to={{
                  pathname: path,
                }}
                activeClassName="selected"
                onClick={() => dispatch(toggleHamburger())}
              >
                {trackName}
              </S.Link>
            </li>
          );
        })}
    </>
  );

  return <>{NavLinks}</>;
};

export default HamburgerNavbar;
