import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const LinkIcon = styled.img`
  margin-left: 0.5rem;
  height: 1.5rem;
  width: auto;
  @media ${device.tablet} {
    width: 2.84rem;
    height: 2rem;
  }
`;
