import { useSelector } from 'react-redux';
import ContentCreator from '../ContentCreator/ContentCreator.js';
import MakeStatus from '../../../../../../../../../../components/MakeStatus/MakeStatus.js';
import * as S from './Details.styles.js';
import continueIcon from 'assets/icons/continue-status.svg';
import completedIcon from 'assets/icons/completed-status.svg';
import useIsMobile from 'hooks/useIsMobile.js';
const Details = ({ topicName, contentCreator, progressStatus, make, handleOnClick }) => {
  const isMobile = useIsMobile();

  if (!progressStatus) progressStatus = 'new';
  const statusMap = {
    'in progress': 'המשך',
    completed: 'הושלם',
    new: 'התחל',
  };

  const backgroundColor =
    progressStatus === 'completed' ? 'var(--color-green)' : 'var(--color-orange)';

  const color = 'var(--color-white)';
  const statusIcon = progressStatus === 'completed' ? completedIcon : continueIcon;

  return (
    <>
      {!isMobile && <S.TopicName>{'#' + topicName}</S.TopicName>}
      <S.BottomWrapper>
        {contentCreator && <ContentCreator contentCreator={contentCreator} />}
        {
          <S.MakeStatusWrapper>
            <MakeStatus
              backgroundColor={backgroundColor}
              statusIcon={statusIcon}
              color={color}
              value={statusMap[progressStatus]}
            />
          </S.MakeStatusWrapper>
        }
      </S.BottomWrapper>
    </>
  );
};
export default Details;
