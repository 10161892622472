import styled from 'styled-components/macro';

export const FeedbackMessage = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border-top-right-radius: 0px;
  position: relative;
  ::after {
    content: '';
    position: absolute;
    top: 0px;
    right: -10px;
    width: 0;
    height: 0;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #fff;
    border-top-right-radius: 5px;
  }
`;

export const TextArea = styled.textarea`
  display: ${({ $isvisible }) => !$isvisible && 'none'};
  resize: none;
  border: none;
  width: 100%;
  font-size: 18px;
  :focus {
    border: none;
    outline: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  :disabled {
    color: unset;
    background-color: transparent;
  }
`;
