const fallbackFont = 'serif';
export const primaryFont = {
  regular: `  'Noto Sans Hebrew ExtraCondensed Regular','OpenSansHebrew Regular'`,
  bold: `  'Noto Sans Hebrew ExtraCondensed Black', 'OpenSansHebrew Bold'`,
};
export const secondaryFont = {
  regular: `'Gveret Levin AlefAlefAlef Regular', ${fallbackFont}`,
  bold: `'Gveret Levin AlefAlefAlef Bold', ${fallbackFont}`,
  extraBold: `'Gveret Levin AlefAlefAlef ExtraBold', ${fallbackFont}`,
};
