/* eslint-disable no-useless-escape */
import {
  ranksIcons,
  RECOMMENDATION_TRACK_ID,
  TRACK_ID_OF_META,
  XP_ICONS,
} from 'config/constants/constants';
import {
  levels,
  MAKE_SUBMISSION_EASY,
  MAKE_SUBMISSION_HARD,
  MAKE_SUBMISSION_MEDIUM,
  NEW_COMPONENT_EASY,
  NEW_COMPONENT_HARD,
  NEW_COMPONENT_MEDIUM,
} from 'config/constants/xpConstants';

import { htmlEntities } from 'config/htmlEntity';
import { array } from 'joi';
import { element } from 'prop-types';
export function getSpecificObjFromArrayOfObjects(arr = [], key, value) {
  return arr.find((item) => item[key] === value);
}

export function isEmptyObject(obj) {
  if (!(typeof obj === 'object')) return false;
  return (
    !Object.getOwnPropertyNames(obj).length && !Object.getOwnPropertySymbols(obj).length
  );
}
export function getTrackNameByTrackId(arr = [], _id) {
  return arr.find((item) => item[_id] === _id).trackName;
}
export function getTopicNameByTopicId(arr = [], _id) {
  return arr.find((item) => item[_id] === _id).topicName;
}
export function getUserNameAcronyms(userName) {
  const trimUserName = userName.trim().split(' ');
  return trimUserName.length !== 1
    ? trimUserName[0].charAt(0).toUpperCase() + trimUserName[1].charAt(0).toUpperCase()
    : trimUserName[0].slice(0, 2).toUpperCase();
}

export function getRandomColor() {
  return (
    '#' +
    Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, '0')
  );
}

export function formulaToCalculateUsers(number) {
  const num = number * 2 + 16;
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
    : Math.sign(num) * Math.abs(num);
}

/**
 *
 *
 * @export
 * @param {*} totalPoints
 * @param {*} points
 * @return {*} progress count
 */
export function formulaCalculateProgress(points, totalPoints) {
  return Math.round((points / totalPoints) * 100);
}

export function numberWithCommas(num) {
  return num.toString().toLocaleString();
}

/**
 * Gets `n` random elements at unique keys from `array` up to the
 * size of `array`.
 * @param {Array} array The array to sample.
 * @param {number} [n=1] The number of elements to sample.
 * @returns {Array} Returns the random elements.
 * @example
 *
 * sampleSize([1, 2, 3], 2)
 * // => [3, 1]
 *
 * sampleSize([1, 2, 3], 4)
 * // => [2, 3, 1]
 */
export function sampleSize(array, n) {
  n = n == null ? 1 : n;
  const length = array == null ? 0 : array.length;
  if (!length || n < 1) {
    return [];
  }
  n = n > length ? length : n;
  let index = -1;
  const lastIndex = length - 1;
  const result = [...array];
  while (++index < n) {
    const rand = index + Math.floor(Math.random() * (lastIndex - index + 1));
    const value = result[rand];
    result[rand] = result[index];
    result[index] = value;
  }
  return result.slice(0, n);
}

export function saveStringAsHtmlEntity(str) {
  return str.replace(/([&<>\"'])/g, (match) => htmlEntities[match]);
}

export const findIndexComponentById = (components, id) => {
  if (!components || !components?.length) return;
  const index = components.findIndex((component) => {
    return component._id == id;
  });

  return index;
};

export const findLastSubmissionId = (components) => {
  const tmpArray = [...components];
  const reverseList = tmpArray.reverse();
  let lastSubmissionId = null;
  for (let { type, _id } of reverseList) {
    if (type === 'upload') {
      lastSubmissionId = _id;
      break;
    }
  }
  return lastSubmissionId;
};

export const firstTimeInComponent = (sequences, lastComponentId, currentComponentId) =>
  findIndexComponentById(sequences, lastComponentId) <=
  findIndexComponentById(sequences, currentComponentId);

export const checkIfHasTopicAndMakeByTopic = (topics, makesByTopic) => {
  return topics.length > 0 && Object.keys(makesByTopic).length;
};
export function getOffset(element) {
  const rect = element?.getBoundingClientRect(),
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return rect.top + scrollTop;
}

export const modifyUserProgress = (progress) => {
  return progress.reduce((prevVal, data, index) => {
    let key = data.makeId;
    prevVal[key] = data;
    return prevVal;
  }, {});
};
export const nextComponentId = (components, index) => {
  if (index + 1 <= components.length - 1) {
    return components[index + 1]._id;
  } else {
    return components[index]._id;
  }
};
export const getComponentId = (userProgress, make) => {
  if (!userProgress) {
    return make.componentsId[0];
  } else {
    return make.componentsId.find((id) => {
      return userProgress.find((progress) => progress.lastComponent === id);
    });
  }
};

export function isMobileSafari() {
  const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  return isSafari;
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function randValueFromArray(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function removeItemFromArray(arr, item) {
  const temp = [...arr];
  const index = arr.indexOf(item);
  if (index !== -1) {
    temp.splice(index, 1);
  }
  return temp;
}

export function getComponentTitle(sequences, currentIndex, type) {
  if (
    [
      'upload',
      'checkbox',
      'youtube',
      'text',
      'choiceQuestion',
      'google',
      'discussion',
      'iframe',
    ].includes(type)
  ) {
    return sequences[currentIndex]?.data?.title;
  }
  return null;
}

export function dataURItoFile(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ia], { type: mimeString });
  return new File([blob], Date.now() + 'discord-Image.png', {
    type: blob.type,
  });
}

export function getMobileOperatingSystem() {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'unknown';
}

export const getS3UrlExtensionType = (urlExtension) => {
  switch (urlExtension) {
    case '.doc':
    case '.pdf':
    case '.ppt':
    case '.mp4':
      return 'file';
    default:
      return 'image';
  }
};

export const customErrorMsg = (message, errorType, from) => {
  return {
    message,
    errorType,
    from,
  };
};

export const shuffleArray = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

export const getShuffledMakes = (newMakes, popularMakes) => {
  const newArray = [...newMakes].map((make) => {
    make.make.feature = 'new';
    return make;
  });
  const popularArray = [...popularMakes].map((make) => {
    make.make.feature = 'popular';
    return make;
  });
  const combinePopularAndNew = [...newArray, ...popularArray];
  const shuffledArray = shuffleArray(combinePopularAndNew);
  const randomNum = Math.floor(Math.random() * (5 - 3 + 1) + 3);

  return shuffledArray.slice(0, randomNum);
};

export const isElementInArray = (array, element) => {
  return array?.indexOf(element) > -1;
};

export const getNextComponentXp = (currentMake) => {
  const { level = 'hard' } = currentMake;
  let action;
  switch (level) {
    case 'easy':
      action = NEW_COMPONENT_EASY;
      break;
    case 'medium':
      action = NEW_COMPONENT_MEDIUM;
      break;
    case 'hard':
      action = NEW_COMPONENT_HARD;
      break;
    default:
      break;
  }
  return action;
};
export const getXpForCompletingMake = (currentMake) => {
  const { level } = currentMake;
  let action;
  switch (level) {
    case 'easy':
      action = MAKE_SUBMISSION_EASY;
      break;
    case 'medium':
      action = MAKE_SUBMISSION_MEDIUM;
      break;
    case 'hard':
      action = MAKE_SUBMISSION_HARD;
      break;
    default:
      break;
  }
  return action;
};

export const emptyMake = (text, uploadedImage, uploadedFile) => {
  if (!text && !uploadedImage && isEmptyObject(uploadedFile)) return true;
  return false;
};

export const getRankIcon = (rank) => {
  const index = levels.findIndex(({ rankName }) => rankName === rank);
  if (index === -1) return '';
  return ranksIcons[index - 1];
};

export const convertStringToHTML = (str) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');
  return doc.body;
};
export const getMakeType = ({ isFacilitator, isCampaign }) => {
  if (isFacilitator) return 'facilitator';
  if (isCampaign) return 'isCampaign';
  return 'regular';
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const validateSchemaField = (field, value, schema, errors) => {
  const { error } = schema.extract(field).validate(value);
  if (error) return { ...errors, [field]: error.message };
  else return { ...errors, [field]: '' };
};
export const getTopicsData = (trackId, topicsObj) => {
  switch (trackId) {
    case TRACK_ID_OF_META:
      return topicsObj.metaTopics;
    case RECOMMENDATION_TRACK_ID:
      return topicsObj.recommendationTopics;

    default:
      return topicsObj.topics;
  }
};

export const commafy = (num) => {
  var str = num.toString().split('.');
  if (str[0].length >= 5) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join('.');
};

export const getCurrentLevel = (xpLevels, userXpPoints) => {
  const levelsAccomplished =
    xpLevels?.filter((level) => {
      return userXpPoints >= level?.startPoints;
    }) || [];
  const currentLevel = levelsAccomplished[levelsAccomplished?.length - 1];
  const nextLevel = xpLevels?.find((level) => userXpPoints < level.startPoints);
  return {
    currentLevel: {
      ...currentLevel,
      icon: XP_ICONS[currentLevel?.level - 1],
    },
    nextLevel: { ...nextLevel },
  };
};

export const calculateUserAchivedStart = (userProgresses) =>
  userProgresses?.reduce((sum, { achievedStar }) => sum + achievedStar, 0);

export const isActiveTournament = (tournament) =>
  new Date(tournament.startDate) <= Date.now() &&
  new Date(tournament.endDate) >= Date.now();

export const getCompletedMakesCount = (arr, key, value) => {
  return arr.reduce((count, obj) => {
    if (obj.status === 'completed' && obj[key] === value) {
      return count + 1;
    }
    return count;
  }, 0);
};
