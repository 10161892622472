import { useSelector } from 'react-redux';
import { isActiveTournament } from 'utils/helpers';
import useGetTournamentById from './useGetTournamentById';

const useGetActiveTournament = () => {
  const user = useSelector((state) => state.auth?.user);
  const tournamentId =
    user?.tournaments && user?.tournaments[user?.tournaments.length - 1]?.tournamentId;
  const { tournament } = useGetTournamentById(tournamentId);

  if (tournament) {
    const isActive = isActiveTournament(tournament);
    if (!isActive) return { tournament: null };
  }
  return { tournament };
};

export default useGetActiveTournament;
