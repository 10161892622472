import { useEffect } from 'react';
import { useErrorContext } from 'context/errorContext';
import { useGetTopicByIdQuery } from 'services/api/makesApi/makesApi';
const useGetTopicById = (topicId) => {
  const { handleError } = useErrorContext();
  const {
    data,
    error: useGetTopicByIdError,
    isLoading,
  } = useGetTopicByIdQuery(
    { topicId },
    {
      skip: !topicId,
    }
  );
  useEffect(() => {
    if (useGetTopicByIdError) handleError(useGetTopicByIdError);
  }, [useGetTopicByIdError]);

  return { data, isLoading };
};

export default useGetTopicById;
