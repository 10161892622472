import * as S from './TitleLink.styles';
const TitleLink = ({ imgAlt, icon, children, handleOnClick }) => {
  return (
    <S.TitleContainer aria-hidden="true" onClick={handleOnClick}>
      <S.Image alt={imgAlt} src={icon} />
      <S.Title>{children}</S.Title>
    </S.TitleContainer>
  );
};
export default TitleLink;
