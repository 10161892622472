import { useErrorContext } from 'context/errorContext';
import { useEffect } from 'react';
import { useGetAllMakesByTopicIdQuery } from 'services/api/makesApi/makesApi';

const useGetMakesByTopicId = (topicId) => {
  const { data: makes, error: getMakesByTopicIdError } = useGetAllMakesByTopicIdQuery(
    { topicId },
    { skip: !topicId }
  );
  const { handleError } = useErrorContext();

  useEffect(() => {
    if (getMakesByTopicIdError) handleError(getMakesByTopicIdError);
  }, [getMakesByTopicIdError]);

  return { data: makes };
};

export default useGetMakesByTopicId;
