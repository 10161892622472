import duration from 'assets/icons/duration.svg';
import levelIcon from 'assets/icons/level.svg';
import { LEVEL_MAKE_DIC, RECOMMENDATION_ID } from 'config/constants/constants';
import useGetTracks from 'hooks/queries/useGetTracks';
import useGetUserProgressByUserId from 'hooks/queries/useGetUserProgressByUserId.js';
import useIsIOS from 'hooks/useIsIOS';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getComponentRoute, getQuizComponentRoute, getTopicRoute } from 'routes/helper';
import { setActiveSlide } from 'services/redux/ui.slice.js';
import { makeClickedEvent } from 'services/segmentIO/categories/Navigation';
import { FreeMode } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { v4 as uuidv4 } from 'uuid';
import MakeCard from '../../MakeCard/MakeCard.js';
import useGetAllMakesByTrack from '../hooks/useGetAllMakesByTrack.js';
import Carousel from './Carousel/Carousel.js';
import './Carousel/swiperStyles.css';
import useJumpToMake from './hooks/useJumpToMake.js';
import * as S from './MobileContent.styles.js';
const MobileContent = () => {
  const [swiper, setSwiper] = useState(null);
  const [trackIds, setTrackIds] = useState(null);
  const [allMakes, setAllMakes] = useState([]);
  const [trackToFetch, setTrackToFetch] = useState(null);
  const [isLoop, setIsLoop] = useState(false);
  const trackId = useSelector((state) => state.app?.currentTrack?._id);
  const userId = useSelector((state) => state.auth?.user?._id);
  const space = useSelector((state) => state.app?.space);
  const history = useHistory();
  const dispatch = useDispatch();
  const { topicRoute } = useParams();
  const { tracks } = useGetTracks({ space });
  const { isIOS } = useIsIOS();

  const { allTopics } = useGetAllMakesByTrack(trackToFetch, setAllMakes);
  const { userProgresses } = useGetUserProgressByUserId(userId);

  useJumpToMake(swiper, setAllMakes, allMakes, allTopics);

  useEffect(() => {
    if (!tracks) return;
    let trackIds = [];
    for (let i = 0; i < tracks.length; i++) {
      if (tracks[i].trackRoute === 'meta') continue;
      trackIds.push(tracks[i]._id);
    }

    const currentTrackAndAfter = trackIds.slice(trackIds.indexOf(trackId));
    const beforeTracks = trackIds.slice(0, trackIds.indexOf(trackId));
    const trackIdSequence = [...currentTrackAndAfter, ...beforeTracks];

    setTrackIds(trackIdSequence);
    setTrackToFetch(trackIdSequence[0]);
  }, []);

  useEffect(() => {
    if (allMakes.length) return;

    setTrackToFetch(trackId);
  }, [allMakes, trackId]);

  useEffect(() => {
    if (!tracks || isLoop) return;
    const allTopicIdsFromTracks = tracks
      ?.map((track) => {
        if (track.topics) {
          return track.topics.map((topic) => topic._id);
        } else {
          return track.topicsId.map((topicId) => topicId);
        }
      })
      .flat();
    if (allTopics.length === allTopicIdsFromTracks.length) {
      setIsLoop(true);
    }
  }, [allTopics]);

  const handleOnClick = (e, make, isQuiz = false) => {
    e.stopPropagation();
    const track = tracks.find((track) => {
      return track?._id === make?.trackId;
    });

    const topic = allTopics.find((topic) => {
      return topic._id === make.topicId;
    });

    const trackRoute = track?.trackRoute;
    const topicRoute = topic?.topicRoute || make?.topicRoute;
    const makeRoute = make?.makeRoute;
    const isCampaign = make?.isCampaign;
    const makeName = make.makeName;

    !isQuiz && makeClickedEvent(track, topic, makeName, makeRoute, isCampaign);
    const route = isQuiz
      ? getQuizComponentRoute(trackRoute, topicRoute, makeRoute)
      : getComponentRoute(trackRoute, topicRoute, makeRoute);

    history.push({
      pathname: route,
    });
  };

  const createCards = useMemo(() => {
    return allMakes.map((make, i) => {
      const icons = [
        { icon: duration, description: make.timeFrame + ' דקות' },
        { icon: levelIcon, description: LEVEL_MAKE_DIC[make.level] },
      ];
      const trackId =
        make.swiperTrackId === RECOMMENDATION_ID ? make.swiperTrackId : make.trackId;
      const topicId =
        make.swiperTrackId === RECOMMENDATION_ID ? make.swiperTopicId : make.topicId;
      const track = tracks.find((track) => {
        return track?._id === make?.trackId;
      });

      let id = uuidv4();
      return (
        <SwiperSlide
          onClick={(e) => handleOnClick(e, make)}
          data-track={trackId}
          data-topic={topicId}
          key={id}
        >
          <MakeCard
            icons={icons}
            topics={allTopics}
            x
            make={make}
            index={i}
            isIOS={isIOS}
            trackName={track?.trackName}
            handleOnClick={handleOnClick}
            progress={userProgresses?.find((progress) => progress.makeId === make._id)}
          />
        </SwiperSlide>
      );
    });
  }, [allMakes, userProgresses]);

  const settings = {
    modules: [FreeMode],
    loop: isLoop,
    freeMode: true,
    cssMode: true,
    direction: 'vertical',
    slidesPerView: 'auto',
    loopedSlides: allMakes.length,
  };

  const onSlideChange = (swiper) => {
    dispatch(setActiveSlide(swiper.realIndex));
    const el = swiper.slides[swiper.realIndex];
    const trackIdFromActiveEl = el?.getAttribute('data-track');
    const topicIdFromActiveEl = el?.getAttribute('data-topic');
    const currentTrackIndex = trackIds.indexOf(trackIdFromActiveEl);
    const nextTrackId = trackIds[currentTrackIndex + 1];
    const nextTrackMake = allMakes.find((make) => {
      if (!make.topicNameFromRecommendation) return make.trackId === nextTrackId;
    });

    if (!nextTrackMake) {
      setTrackToFetch(nextTrackId);
    }

    const currentTrack = tracks?.find((track) => track._id === trackIdFromActiveEl);
    const currentTopic = allTopics?.find((topic) => topic._id === topicIdFromActiveEl);

    if (currentTrack && currentTopic) {
      const isDifferentUrl = currentTopic.topicRoute !== topicRoute;
      if (isDifferentUrl) {
        let path = getTopicRoute(currentTrack.trackRoute, currentTopic.topicRoute);
        history.push(path);
      }
    }
  };

  return (
    <>
      <Carousel setSwiper={setSwiper} settings={settings} onSlideChange={onSlideChange}>
        {allMakes.length && createCards}
      </Carousel>
    </>
  );
};
export default MobileContent;
