import { useErrorContext } from 'context/errorContext';
import { useEffect } from 'react';
import { useToggleUnitMutation } from 'services/api/makesApi/makesApi';
const useToggleUnit = () => {
  const [toggleUnit, { error: toggleUnitError }] = useToggleUnitMutation();

  const { handleError } = useErrorContext();
  useEffect(() => {
    if (toggleUnitError) handleError(toggleUnitError);
  }, [toggleUnitError]);
  return { toggleUnit };
};

export default useToggleUnit;
