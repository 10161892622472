export const USER_UPLOAD = 'discord/upload';
export const GET_DETAILS_USER = () => `user/details`;
export const UPDATE_USER = (id, src) => `user/${src}/${id}`;
export const UPLOAD_MAKE = (makeId) => `progress/make/${makeId}/uploadMake`;
export const UPDATE_USER_POINTS = (trackId) => `user/addPoints?trackId=${trackId}`;
export const GET_XP_LEVELS = `user/getXpLevels`;
export const UPDATE_USER_XP = () => `user/updateRank`;
export const GET_USER_XP = (userId) => `user/${userId}/getXP`;
export const GET_LEADER_BOARD = (
  selectedOption,
  offset,
  isTournament,
  tournamentId,
  classId
) => {
  return `user/leaderBoard/topTen?leaderboard=${selectedOption}&offset=${offset}&isTournament=${isTournament}&tournamentId=${tournamentId}&classId=${classId}`;
};

export const GET_MY_LEADERBOARD_POSITION = (
  selectedOption,
  tournamentId,
  classId,
  isTournament
) => {
  return `user/leaderBoard/myPosition?leaderboard=${selectedOption}&isTournament=${isTournament}&tournamentId=${tournamentId}&classId=${classId}`;
};
