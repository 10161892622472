import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { useErrorContext } from 'context/errorContext';
import { useEffect } from 'react';
import { useGetUserProgressByUserIdQuery } from 'services/api/makesApi/makesApi';

const useGetUserProgressByUserId = (userId) => {
  const { data: userProgresses, error: getUserProgressesError } =
    useGetUserProgressByUserIdQuery({ userId }, { skip: !userId });
  const { handleError } = useErrorContext();

  useEffect(() => {
    if (getUserProgressesError) handleError(getUserProgressesError);
  }, [getUserProgressesError]);

  return { userProgresses };
};

export default useGetUserProgressByUserId;
