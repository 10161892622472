//POINTS Actions
export const RECEIVE_STAR = 'receive_star';
export const ADD_MAKES_TO_HOMEPAGE = 'add_makes_to_homepage';
export const UPLOAD_MAKE_WITHOUT_SHARE = 'upload_make_without_sharing';
export const SHARE_MAKE = 'share_make';
export const COMPLETED_TOPIC = 'complete_topic';
export const LEVEL_UP = 'level_up';
export const GURU_QUESTION = 'guru_question';
export const NORMAL_QUESTION = 'normal_question';
export const MINI_GAME = 'mini_game';
export const MOTIVATIONAL_LAYER = 'motivational_layer';
export const ADDITIONAL_COMMENT = 'additional_comment';
export const FIRST_COMMENT = 'first_comment';
