import Spinner from 'components/Spinner';
import * as S from './AccordionContent.styles';
import VideoPlayer from 'components/VideoPlayer';
import Feedback from '../Feedback/Feedback';

const AccordionContent = ({ uploadedMake, progressId }) => {
  return (
    <S.Container>
      <S.Wrapper>
        {uploadedMake.type === 'image' ? (
          <img key={uploadedMake.makeName} src={uploadedMake.url} />
        ) : (
          <VideoPlayer
            ariaLabel={'סרטון של מייק'}
            url={uploadedMake.url}
            controls={true}
            width={'100%'}
            height={'100%'}
            fallback={<Spinner />}
          ></VideoPlayer>
        )}
      </S.Wrapper>
      <Feedback progressId={progressId} feedback={uploadedMake?.feedback?.feedbackText} />
    </S.Container>
  );
};

export default AccordionContent;
