import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const Container = styled.div`
  display: flex;
  color: var(--color-charcoal-grey);
  justify-content: unset;
  margin-bottom: 1.5rem;
  padding: 0.5rem 2rem 0.5rem 0.5rem;

  @media ${device.tablet} {
    justify-content: center;
    margin-bottom: 0;
    padding: 0;
  }
`;
