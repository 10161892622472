import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visitedTracks: [],
  participatedDiscussionComponents: [],
  isOnBoardingSeen: false,
  miniGamesFinished: [],
  isTournamentSeen: false,
};

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    updateVisitedTracks: (state, action) => {
      state.visitedTracks = [...state.visitedTracks, action.payload];
    },
    updateParticipatedDiscussionComponent: (state, action) => {
      state.participatedDiscussionComponents = [
        ...state.participatedDiscussionComponents,
        action.payload,
      ];
    },
    setVisitedTracks: (state, action) => {
      state.visitedTracks = action.payload;
    },
    setParticipatedDiscussionComponents: (state, action) => {
      state.participatedDiscussionComponents = action.payload;
    },
    setOnBoarding: (state, action) => {
      state.isOnBoardingSeen = action.payload;
    },
    updateMiniGamesFinished: (state, action) => {
      state.miniGamesFinished = [...state.miniGamesFinished, action.payload];
    },
    setMiniGamesFinished: (state, action) => {
      state.miniGamesFinished = action.payload;
    },
    setIsTournamentSeen: (state, action) => {
      state.isTournamentSeen = action.payload;
    },
  },
});

export const {
  updateVisitedTracks,
  setOnBoarding,
  setVisitedTracks,
  updateMiniGamesFinished,
  setMiniGamesFinished,
  updateParticipatedDiscussionComponent,
  setParticipatedDiscussionComponents,
  setIsTournamentSeen,
} = activitySlice.actions;

export default activitySlice.reducer;
