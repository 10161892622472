import mixpanel from 'services/mixPanel';

// const analytics = window.analytics;
export const identify = (distinctID, properties) => {
  // if (process.env.REACT_APP_NODE_ENV !== 'dev') {
  mixpanel.identify(distinctID);
  mixpanel.people.set(properties);
  // } else {
  //   analytics.identify(distinctID, properties);
  // }
};

export const track = (eventName, properties) => {
  // if (process.env.REACT_APP_NODE_ENV !== 'dev')
  mixpanel.track(eventName, properties);
  // else analytics.track(eventName, properties);
};

// export const page = (pageName) => {
//   analytics.page(pageName);
// };
