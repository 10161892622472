import ProgressStar from 'components/ProgressStar';
import * as S from './MakeCardDetails.style';

const MakeCardDetails = ({ progress, makeName, makeDescription, icons }) => {
  const renderIcons = () => {
    return icons?.map(({ icon, description }, index) => {
      return (
        <S.IconContainer key={index}>
          <S.Icon src={icon} alt="" aria-describedby="image-description" />
          <S.IconDescription id="image-description">{description}</S.IconDescription>
        </S.IconContainer>
      );
    });
  };
  return (
    <S.MakeDetails>
      <S.TitleContainer>
        <S.MakeName>{makeName}</S.MakeName>
        <S.MakeSubTitle>{makeDescription}</S.MakeSubTitle>
      </S.TitleContainer>
      <S.DescriptionContainer>
        <S.IconsList>{renderIcons()}</S.IconsList>
        <ProgressStar progress={progress} />
      </S.DescriptionContainer>
    </S.MakeDetails>
  );
};

export default MakeCardDetails;
