export const DEFAULTQUERIES = {
  leaderBoard: '?option=general&isOpen=false',
  myMakes: '?option=grid',
  CMS_EDITOR: '?track=&&topic=&&make=&&component=',
};

export const ON_BOARDING_TOURNAMENT = '/onboarding/tournament';
export const ON_BOARDING_LESSON = '/onboarding/lesson';
export const ROOT = '/';
export const LOGIN = '/login';
export const ABOUT = '/about';
export const META_SIGN_UP = '/metasignup';
export const DASHBOARD = '/dashboard';
export const COMPONENT = '/component';
export const INFO = '/info';
export const SAFARI = '/safari';
export const PRIVACY = '/privacy';
export const TERMS = '/terms';
export const PROFILE = '/profile';
export const XP = `${PROFILE}/xp`;
export const LEADER_BOARD = `${PROFILE}/leaderboard`;
export const ACHIEVEMENTS = `${PROFILE}/achievements`;
export const MY_MAKES = `${PROFILE}/myMakes`;
export const RECOMMENDATIONS = '/track/recommendation/topic/popularMakes';
export const ACCESSIBILITY = `/accessibility`;
export const TOURNAMENT_LEADER_BOARD = `/tournamentLeaderBoard`;
export const SIGN_IN_OPEN_FROM = {
  DISCOVERY: 'discovery',
  INSIDE_MAKE: 'inside make',
};
