import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  headerHeight: 0,
  showPointsIncrease: false,
  showComponentInfo: false,
  showOnBoardingButton: false,
  showWhatIDoHere: false,
  showMetaIframe: false,
  isVideoMuted: false,
  toggleHamburger: false,
  showLvlUpModal: false,
  showBgLevitatingGuru: true,
  activeSlide: 0,
  isAccessibilityMode: false,
  topSectionHeight: 0,
};
export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setActiveSlide: (state, action) => {
      state.activeSlide = action.payload;
    },
    setHeaderHeight: (state, { payload }) => {
      state.headerHeight = payload;
    },
    setTopSectionHeight: (state, { payload }) => {
      state.topSectionHeight = payload;
    },
    toggleComponentInfo: (state) => {
      state.showComponentInfo = !state.showComponentInfo;
    },
    setShowOnBoardingButton: (state, { payload }) => {
      state.showOnBoardingButton = payload;
    },
    setShowWhatIDoHere: (state, { payload }) => {
      state.showWhatIDoHere = payload;
    },
    setShowPointsIncrease: (state, { payload }) => {
      state.showPointsIncrease = payload;
    },
    setShowComponentInfo: (state, { payload }) => {
      state.showComponentInfo = payload;
    },
    setShowLvlUpModal: (state, { payload }) => {
      state.showLvlUpModal = payload;
    },
    toggleMuteVideo: (state) => {
      state.isVideoMuted = !state.isVideoMuted;
    },
    toggleHamburger: (state) => {
      state.toggleHamburger = !state.toggleHamburger;
    },
    setBgBgLevitatingGuru: (state, { payload }) => {
      state.showBgLevitatingGuru = payload;
    },
    toggleAccessibilityMode: (state) => {
      state.isAccessibilityMode = !state.isAccessibilityMode;
    },
  },
});

export const {
  setActiveSlide,
  setHeaderHeight,
  toggleComponentInfo,
  toggleHamburger,
  setMobileOrDesktop,
  setShowOnBoardingButton,
  setShowWhatIDoHere,
  setShowPointsIncrease,
  setShowMetaIframe,
  setShowComponentInfo,
  toggleMuteVideo,
  setShowLvlUpModal,
  setBgBgLevitatingGuru,
  toggleAccessibilityMode,
  setTopSectionHeight,
} = uiSlice.actions;

export default uiSlice.reducer;
