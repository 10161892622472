import { socialLinks } from 'utils/socialLinks.js/socialLinks';

import * as S from './socialLinks.styles';

const SocialLinks = () => {
  return (
    <>
      {socialLinks.map((item, index) => (
        <li key={index}>
          <a
            href={item.link}
            rel="noopener noreferrer"
            target="_blank"
            aria-label={`קישור לפתיחת ${item.name} בחלון חדש`}
          >
            <S.LinkIcon src={item.icon} alt={item.name} aria-hidden="true" />
          </a>
        </li>
      ))}
    </>
  );
};

export default SocialLinks;
