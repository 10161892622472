import React from 'react';
import * as S from './Progress.styles';

const Progress = ({ name, progress, avatar }) => {
  const normalizedProgress = Math.min(Math.max(progress, 0), 100);
  return (
    <S.Container>
      <S.TextContainer>
        {avatar && <S.Avatar src={avatar} />}
        <S.Name>{name}</S.Name>
      </S.TextContainer>
      <S.ProgressWrapper>
        <S.ProgressBar
          role="progressbar"
          aria-valuenow={normalizedProgress}
          aria-valuemin={0}
          aria-valuemax={100}
          progress={normalizedProgress}
        >
          {`${normalizedProgress}%`}
        </S.ProgressBar>
      </S.ProgressWrapper>
    </S.Container>
  );
};

export default Progress;
