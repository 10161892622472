export const GET_ALL_USER_UPLOADED_MAKES = () => `progress/uploadedMakes`;
export const GET_MAKES_BY_TOPIC = (topicId, from = 'CMS', type = 'warning') => {
  return `makes/topic/${topicId}/uploadedMakes?from=${from}&type=${type}`;
};

export const GET_MAKE = (userId, makeId) => `makes/make/${makeId}/user/${userId}`;
export const UPDATE_MAKE_INDEX = (topicId) => `makes/topic/${topicId}/updateIndex`;

export const CREATE_NEW_MAKE = (trackId, topicId) =>
  `makes/track/${trackId}/topic/${topicId}/create`;
export const UPDATE_MAKE = (makeId) => `makes/make/${makeId}/update`;
export const DELETE_MAKE = (topicId, makeId) =>
  `makes/topic/${topicId}/make/${makeId}/delete`;
export const PUBLISH_MAKE = (makeId) => `makes/make/${makeId}/publish`;
export const UNPUBLISH_MAKE = (makeId) => `makes/make/${makeId}/unPublish`;
export const GET_DISCUSSION_MAKES = () => `makes/discussionMakes`;
export const GET_NEW_MAKES = (space) => `makes/space/${space}/getNewMakes?itemNumber=7`;

export const GET_MAKES_IN_PROGRESS = (space) => `makes/space/${space}/getInProgressMakes`;

export const GET_POPULAR_MAKES = (space) =>
  `makes/space/${space}/getPopularMakes?itemNumber=7`;

export const GET_RECOMMENDED_MAKES = (space) =>
  `makes/space/${space}/getRecommendedMakesForUser`;
export const GET_MAKES_BY_TRACK = (trackId) => `makes/track/${trackId}`;
