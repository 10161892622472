export const GET_ALL_UNITS = () => `unit/`;
export const GET_UNIT_BY_ID = (unitId) => `unit/${unitId}`;
export const GET_UNITS_BY_ORGANIZATION_ID = (unitId) => `unit/organization/${unitId}`;
export const DELETE_UNIT = (unitId) => `unit/${unitId}`;
export const EDIT_UNIT = (unitId) => `unit/${unitId}`;
export const CREATE_UNIT = () => `unit/`;
export const GET_UNIT_STUDENTS_PROGRESS = (unitId) =>
  `unit/${unitId}/students/completedMakes`;

export const TOGGLE_UNIT = () => `unit/toggleUnit`;
