import * as S from './componentPageHeaderItems.styles';
import CloseIcon from './components/CloseIcon/CloseIcon';
import InfoIcon from './components/InfoIcon/InfoIcon';

const ComponentPageHeaderItems = () => {
  return (
    <>
      <S.RIconsWrapper>
        <CloseIcon />
        <InfoIcon />
      </S.RIconsWrapper>
    </>
  );
};
export default ComponentPageHeaderItems;
