export const GET_ALL_COMPONENTS_BY_MAKE_ID = (makeId, from = 'CMS', type = 'warning') =>
  `components/make/${makeId}/getAllComponents?from=${from}&type=${type}`;
export const CREATE_NEW_COMPONENT = (makeId) => `components/make/${makeId}/create`;
export const GET_DISCUSSION_COMPONENTS_BY_MAKE_ID = (makeId) =>
  `components/make/${makeId}/discussionComponents`;
export const UPDATE_COMPONENT_INDEX = (makeId) => `components/make/${makeId}/updateIndex`;
export const UPDATE_COMPONENT = (componentId) =>
  `components/component/${componentId}/update`;
export const DELETE_COMPONENT = (makeId, componentId) =>
  `components/make/${makeId}/component/${componentId}/delete`;

export const UNPUBLISH_COMPONENT = (componentId) =>
  `components/component/${componentId}/unPublish`;

export const PUBLISH_COMPONENT = (componentId) =>
  `components/component/${componentId}/publish`;
