import { forwardRef, useEffect, useState } from 'react';

import { createPortal } from 'react-dom';

import * as S from './RootModal.styles.js';

const RootModal = forwardRef(
  ({ visible, isOpacity, color, isScrollable, children }, ref) => {
    const rootModal = document.getElementById('root-modal');
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
      if (visible) {
        return enter();
      }
      return leave();
    });
    const enter = () => {
      setIsShow(true);
    };
    const leave = () => {
      setIsShow(false);
    };

    return createPortal(
      <S.Container
        ref={ref}
        isOpacity={isOpacity}
        color={color}
        isScrollable={isScrollable}
        isShow={isShow}
      >
        {children}
      </S.Container>,
      rootModal
    );
  }
);

export default RootModal;
