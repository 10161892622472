import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserDetails } from 'apis/usersApi/userApi/users.api';
import { googleAuth, createAnonymousUser, getAnonymousUser } from 'apis/authApi/auth.api';

const initialState = {
  isLoggedIn: false,
  user: null,
  currentRequestId: '',
  loading: 'fin',
  error: '',
  signUpWith: '',
  cameFromURL: false,
};

export const fetchUserDataGoogle = createAsyncThunk(
  'auth/fetchUserGoogle',
  async (code, { rejectWithValue }) => {
    try {
      const {
        data: { user, token },
      } = await googleAuth(code);

      return { user, token };
    } catch (error) {
      return rejectWithValue({}, error);
    }
  }
);
export const getUser = createAsyncThunk(
  'auth/getUserDetails',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getUserDetails();

      return res?.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);
export const createUserAnonymous = createAsyncThunk(
  'auth/createUserAnonymous',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await createAnonymousUser();
      const { anonymousId, user } = data;
      const { xp, points, ...restOfUser } = user;
      return { anonymousId, restOfUser, xp, points };
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);
export const getAnonymous = createAsyncThunk(
  'auth/getAnonymous',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getAnonymousUser();

      return res.data;
    } catch (error) {
      return rejectWithValue([], error);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    logout: (state) => {
      Object.assign(state, initialState);
    },
    updateUserStudentId: (state, action) => {
      state.user.metaStudentId = action.payload;
    },
    updateXp: (state, { payload }) => {
      state.user.xp.currentXp = payload.currentXp;
      state.user.xp.previousXp = payload.previousXp;
    },
    updateRank: (state, { payload }) => {
      state.user.rank = payload;
    },
    updatePoints: (state, { payload }) => {
      state.user.points.currentPoints = payload.currentPoints;
      state.user.points.previousPoints = payload.previousPoints;
    },
    updateUserProp: (state, { payload }) => {
      state.user.gender = payload.gender;
      state.user.city = payload.infoLocation;
      state.user.grade = payload.grade;
      state.user.mathUnits = payload.mathUnits;
      state.user.userName = payload.userName;
      state.user.tournaments = payload.tournaments;
      state.user.role = payload.role;
      state.user.lesson = payload.lesson;
    },
    updateUserPreferences: (state, action) => {
      state.user.preferences = action.payload;
    },
    updateUserActiveTournament: (state, { payload }) => {
      state.user.activeTournament = payload.activeTournament;
    },
    setCameFromURL: (state, { payload }) => {
      state.cameFromURL = payload;
    },
  },
  extraReducers: {
    [getUser.fulfilled]: (state, { meta, payload }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        const { points, xp, ...user } = payload;
        state.user = {
          ...user,
          xp: { currentXp: xp, previousXp: 0 },
          points: { currentPoints: points, previousPoints: 0 },
        };
        state.isLoggedIn = true;
        state.loading = 'fin';
        state.currentRequestId = '';
      }
    },
    [getUser.pending]: (state, { meta }) => {
      state.currentRequestId = meta;
      state.loading = 'pending';
    },
    [getUser.rejected]: (state, { meta, error }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.currentRequestId = meta;
        state.loading = 'fin';
        state.error = error;
        localStorage.removeItem('makes_userId');
      }
    },
    [createUserAnonymous.fulfilled]: (state, { meta, payload }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        const { anonymousId, restOfUser: user, xp, points } = payload;
        state.user = {
          ...user,
          xp: { currentXp: xp, previousXp: 0 },
          points: { currentPoints: points, previousPoints: 0 },
        };
        localStorage.setItem('anonymousId', anonymousId);
        state.loading = 'fin';
        state.currentRequestId = '';
      }
    },
    [createUserAnonymous.pending]: (state, { meta }) => {
      state.currentRequestId = meta;
      state.loading = 'pending';
    },
    [createUserAnonymous.rejected]: (state, { meta, error }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.currentRequestId = meta;
        state.loading = 'fin';
        state.error = error;
        // localStorage.removeItem('makes_userId');
      }
    },
    [getAnonymous.fulfilled]: (state, { meta, payload }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        const { user } = payload;
        const { xp, points, ...restOfUser } = user;
        state.user = {
          ...restOfUser,
          xp: { currentXp: xp, previousXp: 0 },
          points: { currentPoints: points, previousPoints: 0 },
        };
        state.loading = 'fin';
        state.currentRequestId = '';
      }
    },
    [getAnonymous.pending]: (state, { meta }) => {
      state.currentRequestId = meta;
      state.loading = 'pending';
    },
    [getAnonymous.rejected]: (state, { meta, error }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.currentRequestId = meta;
        state.loading = 'fin';
        state.error = error;
        // localStorage.removeItem('makes_userId');
      }
    },
    [fetchUserDataGoogle.fulfilled]: (state, { meta, payload }) => {
      const { token, user } = payload;
      if (meta.requestId === state.currentRequestId.requestId) {
        localStorage.setItem('token', token);
        localStorage.removeItem('anonymousId');
        localStorage.removeItem('isAuthenticating');
        const { xp, points, ...restOfUser } = user;
        state.user = {
          ...restOfUser,
          xp: { currentXp: xp, previousXp: 0 },
          points: { currentPoints: points, previousPoints: 0 },
        };
        state.isLoggedIn = true;
        state.loading = 'fin';
        state.currentRequestId = '';
      }
    },
    [fetchUserDataGoogle.pending]: (state, { meta }) => {
      state.currentRequestId = meta;
      state.loading = 'pending';
    },
    [fetchUserDataGoogle.rejected]: (state, { meta, error }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        localStorage.removeItem('isAuthenticating');
        state.currentRequestId = meta;
        state.loading = 'fin';
        state.error = error;
        state.isLoggedIn = false;
        state.user = null;
      }
    },
  },
});

export const {
  login,
  logout,
  startAuthentication,
  updateUserStudentId,
  updateUserProp,
  updateUserPreferences,
  updateXp,
  updateUserActiveTournament,
  updateRank,
  setCameFromURL,
  updatePoints,
} = authSlice.actions;

export default authSlice.reducer;
