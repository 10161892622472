import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const Hamburger = styled.button`
  cursor: pointer;
`;

export const HandleBars = styled.span`
  position: relative;
  height: 2px;
  width: 18.3px;
  display: inline-block;
  background-color: ${({ isOpen }) => (isOpen ? 'transparent' : 'var(--color-black)')};
  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--color-black);
    left: 0;
    top: ${({ isOpen }) => (isOpen ? '0' : '7px')};
    transform: ${({ isOpen }) => (isOpen ? 'rotate(135deg)' : null)};
    transition: all 0.6s;
  }
  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--color-black);
    left: 0;
    top: ${({ isOpen }) => (isOpen ? '0' : '-7px')};
    transform: ${({ isOpen }) => (isOpen ? 'rotate(-135deg)' : null)};
    transition: all 0.6s;
  }
`;

// export const AnimateNavigation = styled.div`
//   display: flex;
//   position: absolute;
//   top: 0;
//   right: 0;
//   height: 100%;
//   width: 80%;
//   background: red;
//   transition: 1s;
//   z-index: 1;

//   /* transform: ${({ isOpen }) =>
//     isOpen ? 'translateX(0x)' : 'translateX(360px)'}; */
// `;

export const BackGroundOpacity = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 2;
  background: black;
  transition: all 5s;
  opacity: ${({ isOpen }) => (isOpen ? '0.5' : '0')};
`;

export const ClickArea = styled.div`
  height: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;

  @media ${device.tablet} {
    width: calc(100% - 40rem);
  }
`;

export const NavigationContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 4;
  transition: ${({ isOpen }) => (isOpen ? 'all 0.5s' : 'all 1s')};
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0px)' : 'translateX(100%)')};

  /* transition: all 1s;
  width: 100%;
  margin-top: -62px;
  transform: translate(2.5rem); */
`;
