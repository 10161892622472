import * as S from './skipLink.styles';
const SkipLink = ({ id, text = 'דלג לתוכן', children }) => {
  if (!id) return new Error('SkipLink must have an id');
  return (
    <>
      <S.Link href={`#${id}`}>{text}</S.Link>
      {children}
    </>
  );
};

export default SkipLink;
