import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';

export const Wrapper = styled.span`
  font-size: ${({ isMobile }) => (isMobile ? '1.4rem' : '1.8rem')};
  font-family: ${primaryFont.regular};
  margin-left: 1 rem;
  margin-right: 1 rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: inherit;
  word-wrap: break-word;
  iframe {
    width: auto !important;
    height: auto !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
    /* white-space: nowrap; */
  }
  strong {
    font-family: ${primaryFont.bold};
  }
`;
