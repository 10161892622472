import { lazy } from 'react';
import { CMS_FACILITATOR, CMS_GUIDE, CMS_MAIN, CMS_TOURNAMENT } from './constants';
import { ROLES } from 'routes/auth/constants';
import { TournamentProvider } from 'features/CMS/ContentType/TournamentEditor/contexts/tournamentContext';
import { CmsProvider } from 'features/CMS/contexts/cmsContext';

const MainEditor = lazy(() => import('features/CMS/ContentType/MainEditor/MainEditor'));
const FacilitatorEditor = lazy(() =>
  import('features/CMS/ContentType/Facilitator/FacilitatorEditor')
);
const LessonEditor = lazy(() => import('features/CMS/ContentType/Lesson/LessonEditor'));
const TournamentEditor = lazy(() =>
  import('features/CMS/ContentType/TournamentEditor/TournamentEditor')
);

// Wrapping components in CmsProvider
const MainEditorWithProvider = () => (
  <CmsProvider>
    <MainEditor />
  </CmsProvider>
);

const FacilitatorEditorWithProvider = () => (
  <CmsProvider>
    <FacilitatorEditor />
  </CmsProvider>
);

const LessonEditorWithProvider = () => (
  <CmsProvider>
    <LessonEditor />
  </CmsProvider>
);
const TournamentEditorWithProvider = () => (
  <CmsProvider>
    <TournamentProvider>
      <TournamentEditor />
    </TournamentProvider>
  </CmsProvider>
);

// Repeat for all other components

const cmsRoutes = [
  {
    path: CMS_MAIN,
    component: MainEditorWithProvider,
    exact: true,
    private: true,
    requiredRoles: [ROLES.CMS, ROLES.SUPER_USER],
  },
  {
    path: CMS_FACILITATOR,
    component: FacilitatorEditorWithProvider,
    exact: true,
    private: true,
    requiredRoles: [ROLES.CMS, ROLES.SUPER_USER],
  },
  {
    path: CMS_TOURNAMENT,
    component: TournamentEditorWithProvider,
    exact: true,
    private: true,
    requiredRoles: [ROLES.CMS, ROLES.SUPER_USER],
  },
  {
    path: CMS_GUIDE,
    component: LessonEditorWithProvider,
    exact: true,
    private: true,
    requiredRoles: [ROLES.CMS, ROLES.SUPER_USER],
  },
];

export default cmsRoutes;
