import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media ${device.tablet} {
    padding: 0 10%;
  }
`;

export const MainContent = styled.main`
  padding: 5rem 5%;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${device.tablet} {
    margin: 0 auto;
    padding: 3rem 0;
  }
`;

export const Title = styled.h1`
  line-height: 2;
  font-family: ${primaryFont.bold};
  padding: 2rem 0;

  @media ${device.tablet} {
    padding: unset;
    font-size: 36px;
    line-height: unset;
  }
`;
export const BreadCrumbs = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  span:last-child {
    font-size: 18px;
    font-family: ${primaryFont.bold};
  }
  @media ${device.tablet} {
    span:last-child {
      font-size: unset;
    }
    font-size: 24px;
    font-family: ${primaryFont.regular};
  }
`;

export const IndicationContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 1px 3px 5px var(--color-light-grey);
  padding: 5% 0 1.5% 0;
  width: 100%;

  @media ${device.tablet} {
    box-shadow: unset;
    padding: unset;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 5%;
  @media ${device.tablet} {
    padding: unset;
  }
`;

export const ChildrenWrapper = styled.div`
  @media ${device.tablet} {
    padding-top: 5%;
  }
`;
