import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const Nav = styled.nav`
  direction: rtl;
  display: flex;
  align-items: center;
  padding: 1.5rem 5%;
  margin: 0 auto;
  justify-content: space-between;
  position: relative;
  background-color: ${({ isTransparent }) =>
    isTransparent ? 'transparent' : 'var(--color-background)'};
  @media ${device.tablet} {
    padding: 2rem 0;
    width: 100%;
  }
`;
export const Logo = styled(Link)`
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
export const LogoIcon = styled.img`
  width: 4.3rem;
  height: 3.6rem;

  @media ${device.tablet} {
    width: 6rem;
    height: 5rem;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  @media ${device.tablet} {
    column-gap: 2rem;
    width: unset;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  justify-content: flex-end;
  @media ${device.tablet} {
    column-gap: 2rem;
  }
`;
