import MainLayout from 'features/Facilitator/layout/MainLayout';
import Progress from 'features/Guide/components/Progress/Progress';
import { useGuideContext } from 'features/Guide/context/guideContext';
import * as S from './TopicPage.styles';
import useGetMakesByTopicId from 'hooks/queries/useGetMakesByTopicId';
import useGetUserProgressByTopic from 'features/Guide/hooks/useGetUserProgressByTopic';
import useGetTopicById from 'features/Guide/hooks/useGetTopicById';
import useGetTrackById from 'features/Guide/hooks/useGetTrackById';
import MakesAccordion from './components/MakesAcoordion/MakesAccordion';
import Spinner from 'components/Spinner';

const TopicPage = () => {
  const { studentCompletedMakes, topicId, studentId } = useGuideContext();
  const { userProgress: userProgressByMake } = studentCompletedMakes || {};
  const { data: makes } = useGetMakesByTopicId(topicId);
  const { data: topic } = useGetTopicById(topicId);
  const { data: track } = useGetTrackById(topic?.trackId);
  const { data: userProgressesByTopic } = useGetUserProgressByTopic({
    topicId,
    userId: studentId,
  });
  if (!studentCompletedMakes || !makes || !topic || !track || !userProgressesByTopic)
    return <Spinner />;
  return (
    <MainLayout title={track.trackName}>
      <S.Container>
        <Progress name={topic.topicName} progress={userProgressByMake} />
        <MakesAccordion makes={makes} userProgresses={userProgressesByTopic} />
      </S.Container>
    </MainLayout>
  );
};

export default TopicPage;
