import { store } from 'services/redux/store';

export const getUrlWithSpace = (path) => {
  const currentState = store.getState();
  const space = currentState.app.space;
  return `/space/${space}${path}`;
};
export const getTrackRoute = (trackRouteName) => {
  const currentState = store.getState();
  const space = currentState.app.space;
  return `/space/${space}/track/${trackRouteName}`;
};

export const getTopicRoute = (trackRouteName, topicRouteName) => {
  const currentState = store.getState();
  const space = currentState.app.space;
  return `/space/${space}/track/${trackRouteName}/topic/${topicRouteName}`;
};

export const getMakeRoute = (trackRouteName, topicRouteName, makeRouteName) => {
  const currentState = store.getState();
  const space = currentState.app.space;
  return `/space/${space}/track/${trackRouteName}/${topicRouteName}/${makeRouteName}`;
};

export const getComponentRoute = (
  trackRouteName,
  topicRouteName,
  makeRouteName,
  componentId
) => {
  const currentState = store.getState();
  const space = currentState.app.space;
  return componentId
    ? `/space/${space}/track/${trackRouteName}/topic/${topicRouteName}/${makeRouteName}/component/${componentId}`
    : `/space/${space}/track/${trackRouteName}/topic/${topicRouteName}/${makeRouteName}/component`;
};
export const getQuizComponentRoute = (
  trackRouteName,
  topicRouteName,
  makeRouteName,
  componentId
) => {
  const currentState = store.getState();
  const space = currentState.app.space;
  return componentId
    ? `/space/${space}/track/${trackRouteName}/topic/${topicRouteName}/${makeRouteName}/quizMake/component/${componentId}`
    : `/space/${space}/track/${trackRouteName}/topic/${topicRouteName}/${makeRouteName}/quizMake/component`;
};

export const getProfileOptionRoute = (profileOption) => {
  const currentState = store.getState();
  const space = currentState.app.space;
  return `/space/${space}${profileOption}`;
};
