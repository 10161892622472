import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';

export const Button = styled.button`
  display: flex;
  border-radius: 30px;
  cursor: pointer;
  width: 100%;
  padding: 2rem;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
`;

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  background-color: var(--color-red);
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const Column = styled.div`
  position: relative;
`;

export const Name = styled.p`
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--color-light-charcoal);
  font-family: ${primaryFont.bold};

  @media ${device.tablet} {
    font-size: 2.4rem;
  }
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`;
export const StatusWrapper = styled.div`
  p {
    font-weight: 900;
    padding: 0.25rem 0.75rem;
    font-size: 12px;
    column-gap: 1rem;
    display: flex;
    justify-content: space-between;
    border-radius: 40px;
    align-items: center;
  }
`;

export const Arrow = styled.img`
  ${({ $isopen }) => $isopen && `transform:${$isopen && 'rotate(180deg)'}`}
`;
