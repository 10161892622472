import { useRef, useEffect } from 'react';

const useAutoResize = (content) => {
  const textAreaRef = useRef(null);

  const autoResize = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    autoResize();
  }, [content]);

  useEffect(() => {
    const textArea = textAreaRef.current;
    textArea?.addEventListener('keyup', autoResize, false);

    // Cleanup
    return () => {
      textArea?.removeEventListener('keyup', autoResize);
    };
  }, []);

  return { textAreaRef };
};

export default useAutoResize;
