import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  ADD_COMMENTS,
  DELETE_COMMENT,
  GET_ALL_COMMENTS,
} from 'apis/commentsApi/constants';
import { GET_ALL_COMPONENTS_BY_MAKE_ID } from 'apis/componentsApi/constants';
import {
  GET_ALL_USER_UPLOADED_MAKES,
  GET_MAKES_BY_TOPIC,
  GET_MAKES_IN_PROGRESS,
  GET_NEW_MAKES,
  GET_POPULAR_MAKES,
  GET_RECOMMENDED_MAKES,
} from 'apis/makesApi/constants';
import { GET_ALL_ORGANIZATIONS } from 'apis/organizationApi/constants';
import { GET_QUIZ_COMPONENTS_BY_MAKE } from 'apis/quizMakeApi/constants';
import { GET_TOPICS_BY_TRACK_ID, GET_TOPIC_BY_ID } from 'apis/topicsApi/constants';
import {
  ADD_USER_TO_TOURNAMENT,
  GET_ACTIVE_TOURNAMENTS,
  GET_ALL_TOURNAMENTS,
  GET_TOURNAMENT_BY_ID,
  UPDATE_MAKE_STATUS_BY_CLASS_ID,
} from 'apis/tournamentsApi/constants';
import {
  GET_RECOMMENDATION_TRACK,
  GET_TRACKS,
  GET_TRACK_BY_ID,
} from 'apis/tracksApi/constants';
import {
  UPDATE_USER_ACTIVITY,
  GET_USER_ACTIVITIES,
} from 'apis/userActivityApi/constants';
import {
  GET_FOUR_USER_PROGRESS_BY_TRACK,
  GET_PROGRESS_OF_USER,
  GET_USER_PROGRESS_BY_TOPIC_ID,
  GET_USER_PROGRESS_BY_USER_AND_MAKE_ID,
  TOGGLE_UPLOADED_MAKE_OPEN_STATUS,
  INSERT_FEEDBACK_FOR_UPLOADED_MAKE,
  UPDATE_PROGRESS_OF_USER,
  UPDATE_QUESTION_STATUS,
  UPLOAD_MAKE_TO_DB,
} from 'apis/userProgressApi/constants';
import { ADD_STUDENT_TO_UNIT } from 'apis/usersApi/studentApi/constants';
import {
  GET_LEADER_BOARD,
  GET_MY_LEADERBOARD_POSITION,
  GET_XP_LEVELS,
  UPDATE_USER_POINTS,
  UPDATE_USER_XP,
} from 'apis/usersApi/userApi/constants';
import { RECOMMENDATION_ID } from 'config/constants/constants';
import { BASE_URL, API_VERSION } from '../CONSTANTS';
import {
  GET_UNITS_BY_ORGANIZATION_ID,
  GET_UNIT_BY_ID,
  GET_UNIT_STUDENTS_PROGRESS,
  TOGGLE_UNIT,
} from 'apis/unitApi/constants';
import { GET_ORGANIZATION_BY_ID } from 'apis/organizationApi/constants';
import { GET_STUDENT_COMPLETED_MAKES } from 'apis/usersApi/studentApi/constants';
import { GET_SPACES } from 'apis/spacesApi/constants';

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_URL}${API_VERSION}`,
  credentials: 'include',
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');
    const anonymousId = localStorage.getItem('anonymousId');
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    if (anonymousId) {
      headers.set('anonymousId', anonymousId);
    }

    return headers;
  },
});

export const makesApi = createApi({
  reducerPath: 'makesApi',
  baseQuery,
  keepUnusedDataFor: 60 * 60,
  tagTypes: [
    'UserProgress',
    'xp',
    'points',
    'comments',
    'uploadMake',
    'userActivity',
    'tournament',
  ],
  endpoints: (builder) => ({
    getActivity: builder.query({
      query: () => {
        return {
          url: GET_USER_ACTIVITIES(),
          method: 'GET',
          transformResponse: (response) => response.data,
        };
      },

      transformErrorResponse: (response) => response.data,
      providesTags: ['userActivity', 'comments'],
    }),

    getTournamentById: builder.query({
      query: (tournamentId) => GET_TOURNAMENT_BY_ID(tournamentId),
      transformResponse: (response) => response.data,
      providesTags: ['tournament'],
      transformErrorResponse: (response) => response.data,
    }),
    getAllTracks: builder.query({
      query: ({ space }) => GET_TRACKS(space, 'MAKES', 'fatal'),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    transformErrorResponse: (response) => response.data,
    getAllTopicsByTrack: builder.query({
      query: ({ trackId }) => GET_TOPICS_BY_TRACK_ID(trackId, 'MAKES', 'fatal'),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getAllMakesByTopicId: builder.query({
      query: ({ topicId, userId }) => GET_MAKES_BY_TOPIC(topicId, 'MAKES', 'fatal'),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),

    getAllComments: builder.query({
      query: ({ componentId }) => {
        return GET_ALL_COMMENTS(componentId);
      },
      providesTags: ['comments'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),

    addComment: builder.mutation({
      query: ({ componentId, makeId, comment, userAvatar, userName }) => ({
        url: ADD_COMMENTS(componentId),
        method: 'POST',
        body: { makeId, comment, userAvatar, userName },
      }),
      invalidatesTags: ['comments'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    updateQuestionStatus: builder.mutation({
      query: ({
        makeId,
        question,
        attempts,
        isDone,
        selectedAnswer,
        componentId,
        questionsIds,
        isQuizMake,
      }) => ({
        url: UPDATE_QUESTION_STATUS(makeId),
        method: 'PATCH',
        body: {
          question,
          attempts,
          isDone,
          selectedAnswer,
          componentId,
          questionsIds,
          isQuizMake,
        },
      }),
      invalidatesTags: ['UserProgress'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    deleteComment: builder.mutation({
      query: ({ componentId, commentId }) => ({
        url: DELETE_COMMENT(componentId, commentId),
        method: 'DELETE',
      }),
      invalidatesTags: ['comments'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getProgressByTrack: builder.query({
      query: ({ trackId }) => GET_FOUR_USER_PROGRESS_BY_TRACK(trackId),
      providesTags: ['UserProgress'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getAllComponentsByMakeId: builder.query({
      //HERE
      query: ({ quizMake, makeId }) =>
        quizMake
          ? GET_QUIZ_COMPONENTS_BY_MAKE(makeId)
          : GET_ALL_COMPONENTS_BY_MAKE_ID(makeId, 'MAKES', 'fatal'),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getAllUserUploadedMakes: builder.query({
      query: () => GET_ALL_USER_UPLOADED_MAKES(),
      transformResponse: (response) => response.data,
      providesTags: ['uploadMake'],
      transformErrorResponse: (response) => response.data,
    }),
    getAllTournaments: builder.query({
      query: () => GET_ALL_TOURNAMENTS(),
      transformResponse: (response) => response.data,
      providesTags: ['tournament'],
      transformErrorResponse: (response) => response.data,
    }),
    getActiveTournaments: builder.query({
      query: () => GET_ACTIVE_TOURNAMENTS(),
      transformResponse: (response) => response.data,
      providesTags: ['tournament'],
      transformErrorResponse: (response) => response.data,
    }),
    getPopularMakes: builder.query({
      query: (userId) => GET_POPULAR_MAKES(userId),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),

    getRecommendedMakes: builder.query({
      query: ({ userId, space }) => GET_RECOMMENDED_MAKES(userId, space),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),

    getNewMakes: builder.query({
      query: (userId) => GET_NEW_MAKES(userId),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getMakesInProgress: builder.query({
      query: (userId) => GET_MAKES_IN_PROGRESS(userId),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getAllRecommendationMakes: builder.query({
      async queryFn({ userId }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const recommendations = await fetchWithBQ(GET_RECOMMENDATION_TRACK(userId));
          const trackId = recommendations.data.data._id;
          const topics = recommendations.data.data.topics;

          const recommendedMakes = await fetchWithBQ(GET_RECOMMENDED_MAKES(userId));
          const newMakes = await fetchWithBQ(GET_NEW_MAKES(userId));
          const popularMakes = await fetchWithBQ(GET_POPULAR_MAKES(userId));
          const makesInProgress = await fetchWithBQ(GET_MAKES_IN_PROGRESS(userId));
          const makes = [
            popularMakes.data.data,
            newMakes.data.data,
            makesInProgress.data.data,
            recommendedMakes.data.data,
          ];
          const modifyMakes = makes.map((makeCategory, i) => {
            return makeCategory.map((make) => {
              return {
                ...make,
                swiperTrackId: trackId,
                swiperTopicId: topics[i]._id,
              };
            });
          });

          return {
            data: {
              makes: modifyMakes.flat(),
              topicsData: topics,
            },
          };
        } catch (e) {
          return { error: e };
        }
      },
    }),
    getUserProgressByUserId: builder.query({
      query: ({ userId }) => GET_PROGRESS_OF_USER(userId),
      providesTags: ['UserProgress'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getUserProgressByTopic: builder.query({
      query: ({ topicId, userId }) => GET_USER_PROGRESS_BY_TOPIC_ID(topicId, userId),
      providesTags: ['UserProgress'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),

    getUserProgressByMake: builder.query({
      query: ({ makeId }) => GET_USER_PROGRESS_BY_USER_AND_MAKE_ID(makeId),
      providesTags: ['UserProgress'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getUnitsByOrganizationId: builder.query({
      query: ({ organizationId }) => GET_UNITS_BY_ORGANIZATION_ID(organizationId),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getOrganizationById: builder.query({
      query: ({ organizationId }) => GET_ORGANIZATION_BY_ID(organizationId),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getStudentsCompletedMakesByUnitId: builder.query({
      query: ({ unitId }) => GET_UNIT_STUDENTS_PROGRESS(unitId),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getStudentCompletedMakes: builder.query({
      query: ({ spaceId, studentId }) => GET_STUDENT_COMPLETED_MAKES(spaceId, studentId),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getUnitById: builder.query({
      query: ({ unitId }) => GET_UNIT_BY_ID(unitId),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getTopicById: builder.query({
      query: ({ topicId }) => GET_TOPIC_BY_ID(topicId),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getTrackById: builder.query({
      query: ({ trackId }) => GET_TRACK_BY_ID(trackId),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getOrganizations: builder.query({
      query: () => GET_ALL_ORGANIZATIONS(),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getUserXPLevels: builder.query({
      query: (userId) => GET_XP_LEVELS(userId),
      providesTags: ['xp'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getLeaderBoardUsers: builder.query({
      query: ({ selectedOption, offset, isTournament, tournamentId, classId }) =>
        GET_LEADER_BOARD(selectedOption, offset, isTournament, tournamentId, classId),

      transformResponse: (response) => {
        return {
          data: response.data,
          pages: response.pages,
        };
      },
      transformErrorResponse: (response) => response.data,
    }),
    getLeaderBoardPosition: builder.query({
      query: ({ selectedOption, tournamentId, classId, isTournament }) =>
        GET_MY_LEADERBOARD_POSITION(selectedOption, tournamentId, classId, isTournament),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    toggleUploadedMakeOpenStatus: builder.mutation({
      query: ({ progressId }) => ({
        url: TOGGLE_UPLOADED_MAKE_OPEN_STATUS(progressId),
        method: 'PATCH',
      }),
      invalidatesTags: ['UserProgress'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    insertFeedForUploadedMake: builder.mutation({
      query: ({ progressId, guideId, studentId, feedbackText }) => ({
        url: INSERT_FEEDBACK_FOR_UPLOADED_MAKE(progressId),
        method: 'PATCH',
        body: { guideId, studentId, feedbackText },
      }),
      invalidatesTags: ['UserProgress'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    updateUserProgressByStar: builder.mutation({
      query: ({ makeId, numOfStars }) => ({
        url: UPDATE_PROGRESS_OF_USER(makeId),
        method: 'PATCH',
        body: { addToStar: numOfStars },
      }),
      invalidatesTags: ['UserProgress'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    addUserToTournament: builder.mutation({
      query: ({ tournamentId, classId, userTournaments, isAnonymousUser }) => ({
        url: ADD_USER_TO_TOURNAMENT(tournamentId, classId),
        method: 'PUT',
        invalidatesTags: ['tournament'],

        body: { userTournaments, isAnonymousUser },
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),

    addStudentToUnit: builder.mutation({
      query: ({ studentId, role, userName, unitId }) => ({
        url: ADD_STUDENT_TO_UNIT(studentId),
        method: 'PATCH',
        body: {
          userName,
          unitId,
          role,
        },
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    uploadMake: builder.mutation({
      query: ({ fileData, makeId }) => ({
        url: UPLOAD_MAKE_TO_DB(makeId),
        method: 'POST',
        body: fileData,
        params: { makeId },
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['uploadMake'],
      transformErrorResponse: (response) => response.data,
    }),

    updateUserPoints: builder.mutation({
      query: ({ body, trackId }) => ({
        url: UPDATE_USER_POINTS(trackId),
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['points'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),

    toggleUnit: builder.mutation({
      query: () => ({
        url: TOGGLE_UNIT(),
        method: 'PATCH',
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    updateUserXp: builder.mutation({
      query: ({ actionName }) => ({
        url: UPDATE_USER_XP(),
        method: 'PUT',
        body: { actionName },
      }),
      invalidatesTags: ['xp'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    updateUserActivity: builder.mutation({
      query: ({ data }) => ({
        url: UPDATE_USER_ACTIVITY(),
        method: 'PUT',
        body: { data },
      }),
      invalidatesTags: ['userActivity'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    updateMakeStatusByClassId: builder.mutation({
      query: ({ tournamentId, classId, makeId }) => ({
        url: UPDATE_MAKE_STATUS_BY_CLASS_ID(tournamentId, classId, makeId),
        method: 'PUT',
      }),
      invalidatesTags: ['tournament'],

      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),

    updateUserProgressByComponent: builder.mutation({
      query: ({
        makeId,
        trackId,
        spaceRoute,
        completed,
        lastComponent,
        questionId,
        topicId,
        questionsIds,
        attempts,
        questionDone,
      }) => ({
        url: UPDATE_PROGRESS_OF_USER(makeId),
        method: 'PATCH',
        body: {
          makeId,
          trackId,
          topicId,
          spaceRoute,
          completed,
          lastComponent,
          questionId,
          questionsIds,
          questionDone,
          attempts,
        },
      }),
      invalidatesTags: ['UserProgress'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getAllTopics: builder.query({
      queryFn(tracks, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const topics = Promise.all(
            tracks.map(async (track) => {
              return await fetchWithBQ(GET_TOPICS_BY_TRACK_ID(track._id));
            })
          );
          return { data: topics.data };
        } catch (error) {
          return { error };
        }
      },
    }),
    getAllMakesByTrackId: builder.query({
      async queryFn({ space, trackId }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const from = 'MAKES';
          const type = 'fatal';
          if (trackId === RECOMMENDATION_ID) {
            const recommendations = await fetchWithBQ(GET_RECOMMENDATION_TRACK());

            const trackId = recommendations.data.data._id;
            const topics = recommendations.data.data.topics;

            const recommendedMakes = await fetchWithBQ(GET_RECOMMENDED_MAKES(space));

            const newMakes = await fetchWithBQ(GET_NEW_MAKES(space));
            const popularMakes = await fetchWithBQ(GET_POPULAR_MAKES(space));
            const makesInProgress = await fetchWithBQ(GET_MAKES_IN_PROGRESS(space));
            const makes = [
              popularMakes.data.data,
              newMakes.data.data,
              makesInProgress.data.data,
              recommendedMakes.data.data,
            ];

            const modifyMakes = makes.map((makeCategory, i) => {
              return makeCategory.map((makeObj, makeIndex) => {
                const make = {
                  ...makeObj,
                  isFirst: makeIndex === 0,
                  topicNameFromRecommendation: topics[i].topicName,
                };
                return {
                  ...make,
                  swiperTrackId: trackId,
                  swiperTopicId: topics[i]._id,
                };
              });
            });

            return {
              data: {
                makes: modifyMakes.flat(),
                topicsData: topics,
              },
            };
          } else {
            const topics = await fetchWithBQ(GET_TOPICS_BY_TRACK_ID(trackId, from, type));
            const topicIds = topics.data.data.map((topic) => topic._id);
            const makes = Promise.all(
              topicIds.map(async (topicId) => {
                return await fetchWithBQ(GET_MAKES_BY_TOPIC(topicId, from, type));
              })
            );

            const result = await makes;

            // const modifyMakes = makes.map((makeCategory, i) => {
            //   return makeCategory.map((makeObj) => {
            //     const make = {
            //       ...makeObj,
            //       topicNameFromRecommendation: topics[i].topicName,
            //     };
            //     return {
            //       make,
            //       swiperTrackId: trackId,
            //       swiperTopicId: topics[i]._id,
            //     };
            //   });
            // });
            const formatData = [
              ...result.map((item, i) => {
                return item.data.data.map((makeObj, makeIndex) => {
                  const make = { ...makeObj, isFirst: makeIndex === 0 };
                  return {
                    ...make,
                    swiperTrackId: trackId,
                    swiperTopicId: topics.data.data[i]._id,
                  };
                });
              }),
            ].flat();

            const topicsData = topics.data.data;

            return { data: { makes: formatData, topicsData } };
          }
        } catch (e) {
          return { error: e };
        }
      },
    }),
    //space(pin):"main"
    //trackId(pin):"612b89a91434013ce5542855"//

    getRecommendationTrack: builder.query({
      providesTags: ['UserProgress'],
      query: () => GET_RECOMMENDATION_TRACK(),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),

    getNextMake: builder.query({
      async queryFn(_, { getState }, _extraOptions, fetchWithBQ) {
        const from = 'MAKES';
        const type = 'fatal';
        const state = getState();
        const user = state.auth.user;
        const trackId = state.app.currentTrack._id;
        const currentMake = state.app.currentMake;
        const currentTopic = state.app.currentTopic;
        // let makesSize = currentTopic.makesId.length;

        try {
          const { data: topicsData } = await fetchWithBQ(
            GET_TOPICS_BY_TRACK_ID(trackId, user?._id, from, type)
          );
          const topics = topicsData;
          const topicIndex = topics.data.findIndex((t) => t._id === currentTopic._id);
          const { data: makes } = await fetchWithBQ(
            GET_MAKES_BY_TOPIC(currentTopic?._id, user?._id, from, type)
          );

          if (currentMake.isCampaign === true) {
            const filteredMakes = makes.data.filter((make) => {
              return (
                make._id === currentMake._id ||
                make.isCampaign === false ||
                make.isCampaign === undefined
              );
            });
            const makesSize = filteredMakes.length;
            const currentMakeIndex = filteredMakes.findIndex(
              (make) => make._id === currentMake._id
            );

            if (makesSize - 1 > currentMakeIndex) {
              const nextMake = filteredMakes[currentMakeIndex + 1];
              return { data: { nextMake, nextTopic: currentTopic } };
            }
          }

          if (currentMake.isCampaign === false) {
            const filteredMakes = makes.data.filter((make) => {
              return make.isCampaign === false || make.isCampaign === undefined;
            });
            const makesSize = filteredMakes.length;

            const currentMakeIndex = filteredMakes.findIndex((make) => {
              return make._id === currentMake._id;
            });
            if (makesSize - 1 > currentMakeIndex) {
              const nextMake = filteredMakes[currentMakeIndex + 1];
              return { data: { nextMake, nextTopic: currentTopic } };
            }
          }

          if (topics?.data.length - 1 > topicIndex) {
            const nextTopic = topics.data[topicIndex + 1];
            const { data: makes } = await fetchWithBQ(
              GET_MAKES_BY_TOPIC(nextTopic._id, user?._id, from, type)
            );
            const nextMake = makes.data[0];

            return { data: { nextMake, nextTopic } };
          }
          if (topics.data.length - 1 === topicIndex) {
            const nextTopic = topics.data[0];
            const { data: makes } = await fetchWithBQ(
              GET_MAKES_BY_TOPIC(nextTopic._id, user?._id, from, type)
            );

            const nextMake = makes.data[0];
            return { data: { nextMake, nextTopic } };
          }
        } catch (e) {
          return { error: e };
        }
      },
    }),
    getSpaces: builder.query({
      query: () => ({
        url: GET_SPACES,
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetAllTracksQuery,
  useGetAllTopicsByTrackQuery,
  useGetAllTopicsQuery,
  useGetAllMakesByTopicIdQuery,
  useGetAllUserUploadedMakesQuery,
  useGetAllCommentsQuery,
  useAddCommentMutation,
  useDeleteCommentMutation,
  useGetNewMakesQuery,
  useGetPopularMakesQuery,
  useGetLeaderBoardUsersQuery,
  useGetLeaderBoardPositionQuery,
  useGetAllComponentsByMakeIdQuery,
  useUpdateUserProgressByComponentMutation,
  useUpdateUserProgressByStarMutation,
  useGetMakesInProgressQuery,
  useGetNextMakeQuery,
  useGetUserProgressByUserIdQuery,
  useGetProgressByTrackQuery,
  useUploadMakeMutation,
  usePrefetch,
  useUpdateUserXpMutation,
  useUpdateUserActivityMutation,
  useGetActivityQuery,
  useUpdateUserPointsMutation,
  useGetUserProgressByTopicQuery,
  useGetUserProgressByMakeQuery,
  useGetAllTournamentsQuery,
  useGetRecommendationTrackQuery,
  useGetAllMakesByTrackIdQuery,
  useGetAllRecommendationMakesQuery,
  useUpdateMakeStatusByClassIdMutation,
  useGetActiveTournamentsQuery,
  useGetTournamentByIdQuery,
  useAddUserToTournamentMutation,
  useUpdateQuestionStatusMutation,
  useGetUnitsByOrganizationIdQuery,
  useGetOrganizationByIdQuery,
  useGetStudentsCompletedMakesByUnitIdQuery,
  useGetStudentCompletedMakesQuery,
  useGetUnitByIdQuery,
  useGetTopicByIdQuery,
  useGetTrackByIdQuery,
  useGetOrganizationsQuery,
  useAddStudentToUnitMutation,
  useToggleUnitMutation,
  useToggleUploadedMakeOpenStatusMutation,
  useInsertFeedForUploadedMakeMutation,
  useGetSpacesQuery,
} = makesApi;
