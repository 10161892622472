import styled from 'styled-components';
import { device } from 'utils/helpers/deviceScreen';

export const Nav = styled.nav`
  overflow: hidden;
`;
export const List = styled.ul`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  overflow-x: auto;
  padding: 0.5rem;

  &::-webkit-scrollbar {
    display: none;
  }
  @media ${device.tablet} {
    overflow-x: unset;
  }
`;
