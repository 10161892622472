import styled from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';
export const Main = styled.main`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  @media ${device.tablet} {
    padding: 4rem 0;
  }
`;
export const MakeList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4rem;
  width: 23.3%;
  ::-webkit-scrollbar {
    display: none;
  }
`;
