import { Link } from 'react-router-dom';

import { getUrlWithSpace } from './helper';
import { useSelector } from 'react-redux';
import { ROOT } from './makes/constants';

export const NotFound = () => {
  return (
    <>
      {/* Style this as you see fit */}
      <Link to={ROOT}>Home</Link>
      <h2 style={{ color: 'white' }}>404: page not found!</h2>
    </>
  );
};

export default NotFound;
