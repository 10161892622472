import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
export const CloseWrapper = styled.button`
  img {
    height: ${({ isMobile }) => (isMobile ? '1.5rem' : '2rem')};
    width: ${({ isMobile }) => (isMobile ? '1.5rem' : '2rem')};
  }
`;
export const TextHeader = styled.h1`
  color: var(--color-charcoal-grey);
  font-size: ${({ isMobile }) => (isMobile ? '0.7rem' : '1.1rem')};
  font-family: ${primaryFont.bold};
  margin: 0;
  margin-top: ${({ isMobile }) => (isMobile ? '0.2rem' : '0.4rem')};
  text-align: center;
`;
