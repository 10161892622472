import { useEffect } from 'react';
import { useErrorContext } from 'context/errorContext';
import { useGetTrackByIdQuery } from 'services/api/makesApi/makesApi';
const useGetTrackById = (trackId) => {
  const { handleError } = useErrorContext();
  const {
    data,
    error: useGetTrackByIdError,
    isLoading,
  } = useGetTrackByIdQuery(
    { trackId },
    {
      skip: !trackId,
    }
  );
  useEffect(() => {
    if (useGetTrackByIdError) handleError(useGetTrackByIdError);
  }, [useGetTrackByIdError]);

  return { data, isLoading };
};

export default useGetTrackById;
