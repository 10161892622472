export const GET_ALL_TOURNAMENTS = () => `tournament/tournaments`;
export const DELETE_FACILITATOR_FROM_TOURNAMENT = (tournamentId, facilitatorId) =>
  `tournament/deleteFacilitator/tournament/${tournamentId}/facilitator/${facilitatorId}`;
export const ADD_FACILITATOR_TO_TOURNAMENT = (tournamentId) =>
  `tournament/addFacilitator/tournament/${tournamentId}`;
export const ADD_CLASS_TO_TOURNAMENT = () => `tournament/addClass`;
export const DELETE_CLASS_FROM_TOURNAMENT = (tournamentId, classId) =>
  `tournament/deleteClass/tournament/${tournamentId}/class/${classId}`;
export const CREATE_TOURNAMENT = () => `tournament/create`;
export const EDIT_TOURNAMENT = (tournamentId) =>
  `tournament/edit/tournament/${tournamentId}`;
export const DELETE_TOURNAMENT = (tournamentId) =>
  `tournament/delete/tournament/${tournamentId}`;

export const GET_ACTIVE_TOURNAMENTS = () => 'tournament/activeTournaments';
export const ADD_USER_TO_TOURNAMENT = (tournamentId, classId) =>
  `tournament/addUser/tournament/${tournamentId}/class/${classId}`;
export const UPDATE_MAKE_STATUS_BY_CLASS_ID = (tournamentId, classId, makeId) =>
  `tournament/update/tournament/${tournamentId}/class/${classId}/make/${makeId}`;

export const GET_TOURNAMENT_BY_ID = (tournamentId) =>
  `tournament/tournament/${tournamentId}`;
