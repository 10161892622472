import Spinner from 'components/Spinner';
import { Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { NotFound } from './NotFound';

import RoleRoute from './auth/RoleRoute';
import PrivateRoute from './auth/PrivateRoute';

import cmsRoutes from './cms/cmsRoutes';
import facilitatorRoutes from './facilitator/facilitatorRoutes';
import makeRoutes from './makes/makesRoutes';
import guideRoutes from './guide/guideRoutes';
import ExcludeRoleRoute from './auth/ExcludedRoleRoute';
import { ROLES } from './auth/constants';
import SpaceRoute from './auth/SpaceRoute';

const RouterConfig = (props) => {
  const componentPagePath =
    '/space/:space/track/:trackRoute/topic/:topicRoute/:makeRoute/component/:componentId?';
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {makeRoutes.map((route, index) => {
          if (route.exclude) {
            return (
              <ExcludeRoleRoute
                component={route.component}
                key={index}
                path={route.path}
                excludedRole={ROLES.STUDENT}
              />
            );
          }
          if (route.private) {
            return (
              <PrivateRoute key={index} path={route.path}>
                <SpaceRoute component={route.component} path={route.path} />
              </PrivateRoute>
            );
          }

          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(routeProps) => {
                if (route.path.includes(componentPagePath)) {
                  // need to rerender component after summary page that will go to next make for the state to be reset. Need to change the key for resetting
                  const { makeRoute } = routeProps.match.params;

                  return <route.component key={makeRoute} {...props} {...routeProps} />;
                }
                return <route.component {...props} {...routeProps} />;
              }}
            />
          );
        })}
        {facilitatorRoutes.map((route, index) => {
          if (route.private) {
            return (
              <RoleRoute
                component={route.component}
                key={index}
                path={route.path}
                requiredRoles={route.requiredRoles}
              />
            );
          }
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(routeProps) => {
                return <route.component {...props} {...routeProps} />;
              }}
            />
          );
        })}
        {cmsRoutes.map((route, index) => {
          return (
            <RoleRoute
              component={route.component}
              key={index}
              path={route.path}
              requiredRoles={route.requiredRoles}
            />
          );
        })}
        {guideRoutes.map((route, index) => {
          if (route.private) {
            return (
              <RoleRoute
                component={route.component}
                key={index}
                path={route.path}
                requiredRoles={route.requiredRoles}
              />
            );
          }
        })}
        {/* <Route path={`${ERROR_PAGE}/:space`}  /> */}
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default RouterConfig;
