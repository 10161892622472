import * as S from './Topic.styles';

const Topic = ({ progress, isSelected, topicName }) => {
  return (
    <S.Item progress={progress} isSelected={isSelected}>
      <S.Text>{topicName}</S.Text>
    </S.Item>
  );
};

export default Topic;
