import {
  DIS_SIGN_UP,
  GOOGLE_SIGN_UP,
  FAIL_GOOGLE_SIGN_UP,
  FAIL_DIS_SIGN_UP,
  LOGOUT_CLICK,
  QUESTION_ANSWERED,
} from '../CONSTANT';
import { identify, track as TRACK } from '../Methods';

export const userSignUpSuccessEvent = (user) => {
  if (user?.discordId) {
    const { discordId, userName, email } = user;
    identify(discordId, {
      $name: userName,
      $email: email,
    });
    TRACK(DIS_SIGN_UP, {
      status: 'Success',
      'Discord ID': discordId,
    });
  } else if (user?.googleId) {
    const { googleId, userName, email } = user;
    identify(googleId, {
      $name: userName,
      $email: email,
    });
    TRACK(GOOGLE_SIGN_UP, {
      status: 'Success',
      'Google ID': googleId,
    });
  }
};

export const userSignUpFailEvent = (signUpWith) => {
  if (signUpWith === 'Discord') TRACK(FAIL_DIS_SIGN_UP);
  else if (signUpWith === 'Google') TRACK(FAIL_GOOGLE_SIGN_UP);
};

export const userLogoutEvent = () => {
  TRACK(LOGOUT_CLICK);
};

export const userAnsweredQuestion = (
  appData,
  questionTitle,
  selectedCorrectAns,
  arrOfWrongAnswerIndex,
  counterWrongAns,
  answers,
  componentType
) => {
  const { make, topic, track } = appData;

  if (!selectedCorrectAns) {
    return TRACK(QUESTION_ANSWERED, {
      'Make Name': make.makeName,
      'Topic Name': topic.topicName,
      'Track Name': track.trackName,
      'Question Title': questionTitle,
      'Answer Text':
        answers[arrOfWrongAnswerIndex[arrOfWrongAnswerIndex.length - 1]]?.text,
      'Try Number': counterWrongAns,
      'Is Correct': false,
      'Is Guru': componentType === 'guru',
    });
  }
  return TRACK(QUESTION_ANSWERED, {
    'Make Name': make.makeName,
    'Topic Name': topic.topicName,
    'Track Name': track.trackName,
    'Question Title': questionTitle,
    'Answer Text': answers[selectedCorrectAns]?.text,
    'Is Correct': true,
    'Is Guru': componentType === 'guru',
  });
};
