import { useEffect } from 'react';
import { useErrorContext } from 'context/errorContext';
import { useGetUnitByIdQuery } from 'services/api/makesApi/makesApi';
const useGetUnitById = (unitId) => {
  const { handleError } = useErrorContext();
  const {
    data,
    error: useGetUnitByIdError,
    isLoading,
  } = useGetUnitByIdQuery(
    { unitId },
    {
      skip: !unitId,
    }
  );
  useEffect(() => {
    if (useGetUnitByIdError) handleError(useGetUnitByIdError);
  }, [useGetUnitByIdError]);

  return { data, isLoading };
};

export default useGetUnitById;
