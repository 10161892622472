import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import * as S from './Teaser.styles';
import Details from './components/Details/Details';
import useIsMobile from 'hooks/useIsMobile';
const Teaser = ({
  hovered,
  make,
  handleOnClick,
  teaser,
  thumbnail,
  index,
  contentCreator,
  progressStatus,
  topicName,
  isIOS,
}) => {
  const [playing, setPlaying] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const activeSlide = useSelector((state) => state.ui.activeSlide);
  const isMobile = useIsMobile();
  const isAccessibilityMode = useSelector((state) => state.ui.isAccessibilityMode);
  const { trackRoute } = useParams();
  const playerRef = useRef(null);
  const thumbnailRef = useRef(null);

  const isActive = activeSlide === index;
  const preload = isActive || !isMobile ? 'auto' : 'none';

  useEffect(() => {
    if (!isActive && isMobile) {
      setPlaying(false);
    }
  }, [isActive, playing]);

  useEffect(() => {
    if (!isMobile) {
      setPlaying(false);
    }
  }, [trackRoute]);

  useEffect(() => {
    if (!waiting) return;
    const player = playerRef.current?.player?.player?.player;
    player?.pause();
    const timer = setTimeout(() => {
      player?.play();
      setPlaying(true);
    }, 250);
    return () => clearTimeout(timer);
  }, [waiting, isActive]);

  useEffect(() => {
    if (!isMobile) setPlaying(hovered);
  }, [hovered, isMobile]);

  const fallbackToIteaser = (teaser) => {
    return (
      <S.TeaserWrapper>
        <S.FallBackImage src={teaser} alt={make.makeName} aria-hidden="true" />
        <Details
          make={make}
          handleOnClick={handleOnClick}
          topicName={topicName}
          contentCreator={contentCreator}
          progressStatus={progressStatus}
        />
      </S.TeaserWrapper>
    );
  };

  if (isAccessibilityMode) {
    return fallbackToIteaser(thumbnail);
  }

  if (!teaser.includes('.mp4')) {
    return fallbackToIteaser(teaser);
  }

  const renderVideoTeaser = () => {
    if (isActive && isMobile) {
      return (
        isActive &&
        isMobile && (
          <ReactPlayer
            ref={playerRef}
            url={teaser}
            playing={true}
            aria-hidden="true"
            onPlay={() => {
              if (isIOS) {
                return setWaiting(true);
              }
              setPlaying(true);
            }}
            loop={true}
            muted={true}
            controls={false}
            height={thumbnailRef.current?.clientHeight}
            width={thumbnailRef.current?.clientWidth}
            preload={preload}
            playsinline={true}
          />
        )
      );
    }
    if (!isMobile) {
      return (
        <ReactPlayer
          url={teaser}
          aria-hidden="true"
          playing={playing}
          loop
          controls={false}
          width="100%"
          height="100%"
          muted
          preload={preload}
          playsinline={true}
        />
      );
    }
  };

  const renderThumbnail = () => {
    return (
      <S.Image
        playing={playing}
        src={thumbnail}
        ref={thumbnailRef}
        alt={make.makeName}
        aria-hidden="true"
      />
    );
  };

  return (
    <S.TeaserWrapper thumbnail={thumbnail}>
      {renderThumbnail()}
      {renderVideoTeaser()}
      <Details
        make={make}
        handleOnClick={handleOnClick}
        topicName={topicName}
        contentCreator={contentCreator}
        progressStatus={progressStatus}
      />
    </S.TeaserWrapper>
  );
};

export default Teaser;
