import { API_VERSION } from 'apis/CONSTANTS';
import { api, getData } from 'apis/index';

import { USER_UPLOAD, GET_DETAILS_USER, UPLOAD_MAKE } from './constants';

export const getUserDetails = () => {
  return api.get(`${API_VERSION}/${GET_DETAILS_USER()}`).then(getData);
};

export const uploadMake = (imageData, makeId) => {
  return api.post(
    `${API_VERSION}/${UPLOAD_MAKE(makeId)}`,
    { ...imageData },
    {
      params: {
        makeId: makeId,
      },
    }
  );
};

export const userUpload = (fileUrl, publicSharing, userId, makeName) => {
  return api
    .post(
      `${API_VERSION}/${USER_UPLOAD}`,
      { fileUrl },
      {
        params: {
          publicSharing: publicSharing,
          userId: userId,
          makeName: makeName,
        },
      }
    )
    .then(getData);
};
