import React, { useRef } from 'react';
import Topic from '../Topic/Topic';
import * as S from './TopicList.style';
import Progress from 'components/Progress/Progress';
import { useSelector } from 'react-redux';

const TopicList = ({ topics }) => {
  const ref = useRef(null);
  const userId = useSelector((state) => state?.auth?.user?._id);
  const scrollToFunc = (offSet) => {
    ref.current.scrollTo({
      top: 0,
      left: offSet,
      behavior: 'smooth',
    });
  };

  return (
    <S.Nav>
      <S.List ref={ref}>
        {topics?.map((topic) => {
          console.log('topic', topic);
          return (
            <Progress
              topicRoute={topic.topicRoute}
              key={topic.topicName}
              item={topic}
              makesAmount={topic?.makesId?.length}
              itemKey="topicId"
              userId={userId}
            >
              {({ progress }) => {
                return (
                  <Topic
                    progress={progress}
                    item={topic}
                    key={topic._id}
                    topics={topics}
                    scrollToFunc={scrollToFunc}
                  />
                );
              }}
            </Progress>
          );
        })}
      </S.List>
    </S.Nav>
  );
};

export default TopicList;
