import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import MakesCard from '../../MakeCard/MakeCard';
import { useHistory } from 'react-router-dom';
import useGetTracks from 'hooks/queries/useGetTracks';
import { getComponentRoute, getQuizComponentRoute } from 'routes/helper';
import { makeClickedEvent } from 'services/segmentIO/categories/Navigation';
import * as S from './DesktopContent.styles';
import { useSelector } from 'react-redux';
import useGetUserProgressByUserId from 'hooks/queries/useGetUserProgressByUserId';
const DesktopContent = ({ makesData }) => {
  const [allTopics, setAllTopics] = useState([]);
  const history = useHistory();
  const trackName = useSelector((state) => state.app?.currentTrack?.trackName);
  const userId = useSelector((state) => state.auth?.user?._id);
  const topSectionHeight = useSelector((state) => state.ui?.topSectionHeight);
  const space = useSelector((state) => state.app?.space);
  const { tracks } = useGetTracks({ space });
  const { userProgresses } = useGetUserProgressByUserId(userId);
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let el = document.getElementById(location.hash.slice(1));
      const offset = el?.getBoundingClientRect().top + window.scrollY;

      window.scroll({
        top: offset,
        behavior: 'smooth',
      });
    }
  }, [location?.hash]);

  useEffect(() => {
    if (!makesData) return;

    setAllTopics((prev) => [...prev, ...makesData.topicsData]);
  }, [makesData]);

  const handleClick = (e, make, isQuiz = false) => {
    e.stopPropagation();
    const track = tracks?.find((track) => {
      return track._id === make.trackId || track._id === make.make?.trackId;
    });
    const topic = allTopics?.find((topic) => {
      return topic._id === make.topicId;
    });

    const trackRoute = track?.trackRoute;
    const topicRoute = topic?.topicRoute || make?.topicRoute;
    const makeRoute = make?.makeRoute;
    const isCampaign = make?.isCampaign;
    const makeName = make.makeName;

    !isQuiz && makeClickedEvent(track, topic, makeName, makeRoute, isCampaign);
    const route = isQuiz
      ? getQuizComponentRoute(trackRoute, topicRoute, makeRoute)
      : getComponentRoute(trackRoute, topicRoute, makeRoute);

    history.push({
      pathname: route,
    });
  };

  const renderCards = useMemo(() => {
    const filteredMakes = makesData && makesData.makes.filter((make) => !make.isCampaign);

    return (
      makesData &&
      userProgresses &&
      filteredMakes.map((make, i) => {
        const id = make.isFirst ? make.swiperTopicId : '';

        return (
          <MakesCard
            userProgresses={userProgresses}
            progress={userProgresses?.find((progress) => progress.makeId === make._id)}
            trackName={trackName}
            key={i}
            index={i}
            make={make}
            handleOnClick={handleClick}
            topics={makesData.topicsData}
            count={i}
            id={id}
          />
        );
      })
    );
  }, [makesData, userProgresses, allTopics]);

  return (
    <S.Main id="main">
      <S.MakeList topSectionHeight={topSectionHeight} cards={3}>
        {makesData && renderCards}
      </S.MakeList>
    </S.Main>
  );
};
export default DesktopContent;
