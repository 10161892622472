import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';

export const Container = styled.div`
  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
  height: 100%;
  width: 100%;
  top: 0;
  direction: rtl;
  left: 0;
  overflow-y: ${({ isScrollable }) => (isScrollable ? 'scroll' : 'hidden')};
  scroll-behavior: smooth;
  position: fixed;
  z-index: 219;

  background-color: ${({ color, isOpacity }) => {
    if (color) return 'rgba(250, 250, 250, 0.76)';
    if (isOpacity) return 'rgba(0, 0, 0, 0.76)';
  }};
  font-family: ${primaryFont.bold};
`;
