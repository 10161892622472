import { useEffect, useState } from 'react';

const useCounter = (end, duration) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (counter === end || !end) return;
    let range = end - counter;
    let stepTime = Math.abs(Math.floor(duration / range));
    let timer = setTimeout(function () {
      const current = counter + 1;
      setCounter(current);

      if (current == end) {
        clearTimeout(timer);
      }
    }, stepTime);
    return () => clearTimeout(timer);
  }, [counter, end]);
  return { counter };
};

export default useCounter;
