import styled, { keyframes, css } from 'styled-components/macro';

const slideUpEnter = keyframes`  from {transform: translate3d(0, 100px, 0);}`;
const slideUpLeave = keyframes` to{transform: translate3d(0, 100px, 0);}`;

const handleAnimationType = (animationAttr) => {
  const [animation, animationType] = animationAttr.split('-');
  switch (animation) {
    case 'slideUp':
      if (animationType === 'enter') {
        return css`
          animation: ${slideUpEnter} both cubic-bezier(0.4, 0, 0, 1.5);
        `;
      }
      if (animationType === 'leave') {
        return css`
          animation: ${slideUpLeave} both cubic-bezier;
        `;
      }
      return;
  }
};
export const ModalContainerParent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  position: absolute;
  z-index: 999;
  min-height: ${({ heightStyle }) => heightStyle || 'fit-content'};
  bottom: ${({ bottomStyle }) => bottomStyle || '0'};
  width: ${({ widthStyle }) => widthStyle || 'fit-content'};
  ${({ animationAttr }) => handleAnimationType(animationAttr)};
  animation-duration: ${({ duration }) => duration + 'ms'};
  &:focus {
    outline: none;
  }
  ${({ bgColor }) =>
    css`
      background-color: ${bgColor};
    `}
`;
