import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';
export const WheelChairImg = styled.img`
  padding-left: 1.5rem;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
`;
export const WheelChairButton = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;
`;
export const Title = styled.h2`
  font-family: ${primaryFont.bold};
  font-size: 1.8rem;
  margin: 0;
  color: var(--color-black);
  @media ${device.tablet} {
    font-size: 2.4rem;
  }
`;

export const Image = styled.img`
  height: 2.5rem;
  width: 2.5rem;
  margin-left: 2px;
`;

export const Circle = styled.div`
  //create a circle around the icon
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 3px solid var(--color-black);
  background-color: ${({ backGroundColor }) => backGroundColor};
  height: 4rem;
  width: 4rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
`;
