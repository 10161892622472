// All events names;
export const SITE_VISIT = 'Site_Visit';
export const SIGN_IN_OPEN = 'Sign_In_Open';
export const DIS_SIGN_UP = 'Dis_Sign_up';
export const FAIL_DIS_SIGN_UP = 'Fail_Dis_Sign_up';
export const GOOGLE_SIGN_UP = 'Google_Sign_up';
export const FAIL_GOOGLE_SIGN_UP = 'Fail_Google_Sign_up';
export const NO_DIC_CLK = 'No_Dis_Clk';
export const SKIP_CLK = 'Onboarding_Skip';
export const WHAT_DIC_CLK = 'What_Dis_Clk';
export const DIS_LINK_CLK = 'Dis_Link_Clk';
export const APP_CRASH = 'App_Crash';
export const PRIVACY_POLICY_MP = 'Privacy_Policy';
export const TERMS_AND_CONDITIONS_MP = 'Terms_&_Conditions';
export const LOGOUT_CLICK = 'Logout_Clk';
export const QUESTION_ANSWERED = 'Question_Answered';
export const DISK_HELP_CLK = 'Disk_Help_Clk';
export const Discussion_Comment = 'Discussion_Comment';
//Tracks
export const TRACK_CLK = 'Track_Clk';
// DiscoveryPage
export const DISCOVERY_ENTER = 'Discovery_Enter';
export const MAKE_CLK = 'Make_Clk';
export const MUTE_CLK = 'Mute_Clk';
export const TOPIC_INFO_CLK = 'Topic_Info_Clk';
export const TEASER_WATCH = 'Teaser_Watch';
//ComponentPage
export const COMP_CLOSE = 'Comp_Close';
export const NEXT_COMPONENT = 'Next_Component';
export const PREVIOUS_COMPONENT = 'Previous_Component';
export const MAKE_INFO_CLICK = 'Make_Info_Click';
export const NAVIGATION_IN_INFO = 'Navigation_In_Info';
export const SHARE_A_MAKE = 'Share_A_Make';
export const GURU_REVEAL = 'Guru_Reveal';
export const GURU_CHOICE = 'Guru_Choice';
export const RECIEVE_STAR = 'Recieve_star';
//Intro - on ComponentPage
export const SEQUENCE_START = 'Sequence_Start';
export const SUBMIT_UP_SHR = 'Submit_Up&Shr';
export const SUBMIT_JST_UP = 'Submit_Jst_Up';
//Upload component
export const SUBMISSION_LOAD = 'Submission_Load';
//Summary component
export const UP_NEXT_MAKE = 'Up_Next_Make';
//On boarding
export const ONBOARDING_CLK = 'Onboarding_Start';
export const ONBOARDING_NEXT_COMP = 'Onboarding_Next_Comp';
export const ONBOARDING_INTER_COMP = 'Onboarding_Interests';
export const ONBOARDING_GENDER = 'Onboarding_Gender';
export const ONBOARDING_CITY = 'Onboarding_City';
export const ONBOARDING_CLASS = 'Onboarding_Class';
export const ONBOARDING_COMPETITION_INFO = 'Onboarding_Competition_Info';
export const ONBOARDING_MATH_UNIT = 'Onboarding_Math_Units';
export const ONBOARDING_DONE = 'Onboarding_Done';
export const ONBOARDING_DISCORD = 'Onboarding_Dis_Join';
export const ONBOARDING_SUBMISSION = 'Onboarding_submission';
export const GOOGLE_SEARCH = 'Google_Search_Comp';
//Meta Pilot
export const META_MODAL_OPENED = 'Meta_Modal_Opened';
export const META_WAITING_LIST = 'Meta_Waiting_List';
//Campaign
export const CAMPAIGN_ENTRY = 'Campaign_Entry';
//Leaderboard
export const PROFILE_LEADERBOARD_CLICK = 'Profile_Leaderboard_Click';
