import {
  useGetAllTracksQuery,
  useGetRecommendationTrackQuery,
} from 'services/api/makesApi/makesApi';
import { useSelector } from 'react-redux';
import { useErrorContext } from 'context/errorContext';
const useGetTracks = ({ space }) => {
  const userId = useSelector((state) => state?.auth?.user?._id);
  const { handleError } = useErrorContext();

  const {
    data: tracks,
    error: tracksError,
    isFetching: isFetchingTracks,
  } = useGetAllTracksQuery({ space }, { skip: !userId || !space });
  const {
    data: recommendationTrack,
    error: recommendationTrackError,
    isFetching: isFetchingRecommendationTrack,
  } = useGetRecommendationTrackQuery(
    {},
    {
      skip: !userId || space !== 'main' || !space,
    }
  );

  try {
    if (tracksError) throw tracksError;
    if (recommendationTrackError) throw recommendationTrackError;
    let tracksArr = null;
    if (tracks) tracksArr = [...tracks];
    if (tracks && recommendationTrack) tracksArr = [recommendationTrack, ...tracksArr];
    return {
      tracks: tracksArr,
      isFetching: isFetchingRecommendationTrack || isFetchingTracks,
    };
  } catch (err) {
    handleError(err);
    return {};
  }
};
export default useGetTracks;
