import styled, { css } from 'styled-components/macro';
import { device } from 'utils/helpers/deviceScreen';

export const Nav = styled.nav`
  display: flex;
  direction: rtl;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 1rem 0;

  @media ${device.tablet} {
    overflow: hidden;
    margin: 0;
  }
`;

export const NavList = styled.ul`
  display: flex;
  column-gap: 1rem;
  scroll-behavior: smooth;
  overflow-x: auto;

  overflow-y: hidden;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    padding: 0.5rem;
  }
`;
