import { useSelector } from 'react-redux';
import { useGetActivityQuery } from 'services/api/makesApi/makesApi';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useErrorContext } from 'context/errorContext';
const useGetUserActivity = () => {
  const userId = useSelector((state) => state.auth?.user?._id);
  const { handleError } = useErrorContext();

  const { data: userActivity, error: userActivityError } = useGetActivityQuery(
    undefined,
    {
      skip: !userId,
    }
  );

  useEffect(() => {
    if (userActivityError) handleError(userActivityError);
  }, [userActivityError]);
  return { userActivity };
};
export default useGetUserActivity;
