import SkipLink from 'components/SkipLink/SkipLink';
import Footer from 'layouts/Footer';
import FacilitatorHeader from '../FacilitatorDashboard/features/Tournament/components/FacilitatorHeader/FacilitatorHeader';
import * as S from './MainLayout.style';
import useNavigationPath from 'features/Guide/hooks/useNavigationPaths';
const MainLayout = ({ children, title }) => {
  const pathSegments = useNavigationPath();
  //TODO refactor faclitator dash board and guide layout
  const renderBreadcrumbs = () => {
    return pathSegments.map((path, index) => {
      if (index !== 0) return <span key={path}> {`> ${path}  `}</span>;
      else return <span key={path}>{path}</span>;
    });
  };
  return (
    <S.Container>
      <SkipLink id="main" />
      <S.HeaderContainer>
        <FacilitatorHeader />
      </S.HeaderContainer>
      <S.MainContent id="main">
        <S.IndicationContainer>
          <S.Title>{title}</S.Title>
          <S.BreadCrumbs>{renderBreadcrumbs()}</S.BreadCrumbs>
        </S.IndicationContainer>
        <S.ChildrenWrapper>{children}</S.ChildrenWrapper>
      </S.MainContent>
      <S.FooterContainer>
        <Footer />
      </S.FooterContainer>
    </S.Container>
  );
};

export default MainLayout;
