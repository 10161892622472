import { updatePoints } from 'services/redux/auth.slice';
import { useDispatch } from 'react-redux';
import { useUpdateUserPointsMutation } from 'services/api/makesApi/makesApi';
import * as Sentry from '@sentry/react';
import { useErrorContext } from 'context/errorContext';
import { useEffect } from 'react';
const usePointsUpdate = () => {
  const dispatch = useDispatch();
  const [updateUserPoints, { error: updatePointsError }] = useUpdateUserPointsMutation();

  const { handleError } = useErrorContext();

  useEffect(() => {
    if (updatePointsError) handleError(updatePointsError);
  }, [updatePointsError]);

  const pointsUpdate = async (request) => {
    const { data } = await updateUserPoints({ ...request });
    dispatch(
      updatePoints({
        previousPoints: data.previousPoints,
        currentPoints: data.currentPoints,
      })
    );
  };
  return { pointsUpdate };
};

export default usePointsUpdate;
