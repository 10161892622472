import * as S from './SubnavSkeleton.style';
const SubnavSkeleton = () => {
  return (
    <>
      <S.Container>
        <S.TopicList>
          {Array.from({ length: 3 }).map((topic, index) => (
            <S.TopicItem key={index} />
          ))}
        </S.TopicList>
      </S.Container>
    </>
  );
};

export default SubnavSkeleton;
