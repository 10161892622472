import { getFacilitatorMakes } from 'apis/facilitatorMakesApi/facilitatorMake.api';
import { useErrorContext } from 'context/errorContext';
import { useErrorHandler } from 'react-error-boundary';
import { customErrorMsg } from 'utils/helpers';

const useGetAllFacilitatorMakes = () => {
  const { handleError } = useErrorContext();
  const getAllFacilitatorMakes = async () => {
    try {
      const result = await getFacilitatorMakes();
      return { data: result?.data };
    } catch (error) {
      const errorData = error.response.data;
      handleError(errorData);
    }
  };
  return { getAllFacilitatorMakes };
};

export default useGetAllFacilitatorMakes;
