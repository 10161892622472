import Header from 'layouts/Header';
import * as S from './FacilitatorHeader.style';
import video from 'assets/icons/facilitatorDashboard/video.svg';
import { useState } from 'react';
import VideoGuide from '../VideoGuide/VideoGuide';

const FacilitatorHeader = () => {
  const [showVideoGuide, setShowVideoGuide] = useState(false);

  return (
    <Header>
      <S.VideoGuide onClick={() => setShowVideoGuide(true)}>
        <S.Icon src={video} alt="סרטון הסבר" aria-hidden="true" />
        <S.Text>סרטון הסבר</S.Text>
      </S.VideoGuide>
      {showVideoGuide && <VideoGuide setShowVideoGuide={setShowVideoGuide} />}
    </Header>
  );
};

export default FacilitatorHeader;
