import { API_VERSION } from 'apis/CONSTANTS';
import { api, getData } from 'apis/index';
import {
  ADD_CLASS_TO_TOURNAMENT,
  ADD_FACILITATOR_TO_TOURNAMENT,
  CREATE_TOURNAMENT,
  DELETE_CLASS_FROM_TOURNAMENT,
  DELETE_FACILITATOR_FROM_TOURNAMENT,
  DELETE_TOURNAMENT,
  EDIT_TOURNAMENT,
  GET_ALL_TOURNAMENTS,
} from './constants';

export const getAllTournaments = () =>
  api.get(`${API_VERSION}/${GET_ALL_TOURNAMENTS()}`).then(getData);

export const deleteTournamentById = (tournamentId) =>
  api.put(`${API_VERSION}/${DELETE_TOURNAMENT(tournamentId)}`).then(getData);

export const editTournamentById = (tournamentId, data) =>
  api.put(`${API_VERSION}/${EDIT_TOURNAMENT(tournamentId)}`, { data }).then(getData);
export const createNewTournament = () =>
  api.post(`${API_VERSION}/${CREATE_TOURNAMENT()}`).then(getData);

export const addClass = (data) =>
  api.put(`${API_VERSION}/${ADD_CLASS_TO_TOURNAMENT()}`, data).then(getData);

export const deleteClass = (tournamentId, classId) =>
  api
    .put(`${API_VERSION}/${DELETE_CLASS_FROM_TOURNAMENT(tournamentId, classId)}`)
    .then(getData);

export const addFacilitator = (tournamentId) =>
  api.put(`${API_VERSION}/${ADD_FACILITATOR_TO_TOURNAMENT(tournamentId)}`).then(getData);

export const deleteFacilitator = (tournamentId, facilitatorId, data) =>
  api
    .put(
      `${API_VERSION}/${DELETE_FACILITATOR_FROM_TOURNAMENT(tournamentId, facilitatorId)}`,
      data
    )
    .then(getData);
