export const GET_PROGRESS_OF_USER = (userId) => `progress/userProgress/${userId}`;
export const GET_USER_PROGRESS_BY_USER_AND_MAKE_ID = (makeId) =>
  `progress/make/${makeId}`;

export const UPDATE_PROGRESS_OF_USER = (makeId) => `progress/edit/make/${makeId}`;
export const GET_FOUR_USER_PROGRESS_BY_TRACK = (trackId) => `progress/track/${trackId}`;

export const GET_USER_PROGRESS_BY_TOPIC_ID = (topicId, userId) => {
  return `progress/topic/${topicId}/user/${userId}`;
};
export const UPLOAD_MAKE_TO_DB = (makeId) => {
  return `progress/make/${makeId}/uploadMake`;
};
export const TOGGLE_UPLOADED_MAKE_OPEN_STATUS = (progressId) => {
  return `progress/${progressId}/toggleOpenStatus`;
};
export const INSERT_FEEDBACK_FOR_UPLOADED_MAKE = (progressId) => {
  return `progress/${progressId}/insertFeedback`;
};

export const UPDATE_QUESTION_STATUS = (makeId) => {
  return `progress/make/${makeId}/updateQuestion`;
};
