import React from 'react';
import { useParams } from 'react-router-dom';
import useGetUnitsByOrganizationId from '../hooks/useGetUnitsByOrganizationId';
import useGetOrganizationById from '../hooks/useGetOrganizationById';
import useGetStudentsCompletedMakesByUnitId from '../hooks/useGetStudentsCompletedMakesByUnitId';
import useGetStudentCompletedMake from '../hooks/useGetStudentCompletedMakes';
import useGetUnitById from '../hooks/useGetUnitById';
import useGetTracks from 'hooks/queries/useGetTracks';

const GuideContext = React.createContext();

const useGuideContext = () => {
  const context = React.useContext(GuideContext);
  if (!context) {
    throw new Error('useGuideContext must be used within a GuideContext Provider');
  }
  return context;
};

const GuideProvider = (props) => {
  const params = useParams();
  const { organizationId, unitId, studentId, topicId } = params || {};
  const { data: organization } = useGetOrganizationById(organizationId);
  const { data: units } = useGetUnitsByOrganizationId(organizationId);
  const { data: unit } = useGetUnitById(unitId);
  const { tracks } = useGetTracks({ space: unit?.space?.spaceRoute });
  const { data: studentsCompletedMakesByUnitId } =
    useGetStudentsCompletedMakesByUnitId(unitId);
  const { data: studentCompletedMakes } = useGetStudentCompletedMake(
    unit?.space?._id,
    studentId
  );
  const value = {
    units,
    organization,
    studentsCompletedMakesByUnitId,
    unitId,
    studentCompletedMakes,
    tracks,
    topicId,
    unit,
    studentId,
  };

  return <GuideContext.Provider value={value} {...props} />;
};

export { GuideProvider, useGuideContext };
