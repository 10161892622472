export const GET_SPACES = () => `spaces/`;
export const GET_TRACKS = (space, from, type) =>
  `tracks/space/${space}?from=${from}&type=${type}`;
export const UPDATE_TRACK_INDEX = (trackId) => `tracks/track/${trackId}/updateIndex`;
export const DELETE_TRACK = (trackId) => `tracks/track/${trackId}/delete`;
export const UPDATE_TRACK = (trackId) => `tracks/track/${trackId}/update`;
export const CREATE_TRACK = () => `tracks/create`;
export const GET_TRACK_BY_ID = (trackId) => `tracks/track/${trackId}`;
export const UNPUBLISH_TRACK = (trackId) => `tracks/track/${trackId}/unPublish`;
export const PUBLISH_TRACK = (trackId) => `tracks/track/${trackId}/publish`;
export const GET_RECOMMENDATION_TRACK = () => {
  return `tracks/track/recommendation`;
};
