import { useState, useEffect, useRef } from 'react';

import learningIcon from 'assets/icons/hamburger/learning.svg';
import logoutIcon from 'assets/icons/hamburger/logout.svg';
import makeLogo from 'assets/icons/makes-logo.svg';
import makesIcon from 'assets/icons/hamburger/makesIcon.svg';
import closeIcon from 'assets/icons/close_component.svg';
import profileIcon from 'assets/icons/hamburger/profile.svg';

import TitleLink from 'components/Navigation/components/TitleLink/TitleLink';
import SocialLinks from 'components/SocialLinks/SocialLinks';
import { canSeeFacilitator, canSeeGuide, canSeeUnitSignIn } from 'routes/auth/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  LOGIN,
  SIGN_IN_OPEN_FROM,
  MY_MAKES,
  XP,
  LEADER_BOARD,
  ABOUT,
  ON_BOARDING_TOURNAMENT,
  ACCESSIBILITY,
  ON_BOARDING_LESSON,
  ROOT,
} from 'routes/makes/constants';
import { getUrlWithSpace } from 'routes/helper';
import { FACILITATOR_DASHBOARD } from 'routes/facilitator/constants';
import { toggleHamburger, toggleAccessibilityMode } from 'services/redux/ui.slice';
import { userLogoutEvent } from 'services/segmentIO/categories/UserState';

import * as S from './navigation.styles';
import HamburgerNavbar from './components/HamburgerNavBar/HamburgerNavBar';
import useTrapFocus from 'hooks/useTrapFocus';
import WheelChair from './components/WheelChair/WheelChair';
import useIsMobile from 'hooks/useIsMobile';
import useToggleUnit from './hooks/useToggleUnit';
import { updateUserProp } from 'services/redux/auth.slice';
import { resetAppSlice } from 'services/redux/app.slice';

const Navigation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menuRef = useRef();
  const { profileOption } = useParams();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isMobile = useIsMobile();
  const user = useSelector((state) => state.auth.user);
  const { role, organizationsId } = user;
  const organizationId = organizationsId ? organizationsId[0] : null;
  const userDoesHaveUnit = user?.lesson?.unitId;
  const activeUnit = user?.lesson?.isActive;
  const isAccessbilityMode = useSelector((state) => state.ui.isAccessibilityMode);
  const [activeIndex, setActiveIndex] = useState(null);
  useTrapFocus(menuRef);
  const { toggleUnit } = useToggleUnit();

  const handleUnitToggle = async () => {
    const {
      data: { user: updatedUser, unit },
    } = await toggleUnit();
    dispatch(updateUserProp({ lesson: updatedUser?.lesson }));
    dispatch(toggleHamburger());
    dispatch(resetAppSlice());
    window.location.pathname = '/';
  };

  const profileLinks = [
    {
      name: 'המייקים שלי',
      route: getUrlWithSpace(MY_MAKES),
      profileOptionName: 'myMakes',
    },
    { name: 'ההתקדמות שלי', route: getUrlWithSpace(XP), profileOptionName: 'xp' },
    {
      name: 'טבלת שיאים',
      route: getUrlWithSpace(LEADER_BOARD),
      profileOptionName: 'leaderboard',
    },
    {
      name: 'הצטרפות לקבוצה',
      route: getUrlWithSpace(ON_BOARDING_TOURNAMENT),
      profileOptionName: 'onBoardingTournament',
    },
  ];

  if (!userDoesHaveUnit && canSeeUnitSignIn(role)) {
    profileLinks.push({
      name: 'הרשמה ליחידה',
      profileOptionName: 'onBoardingLesson',
      routeWithOptions: {
        pathname: getUrlWithSpace(ON_BOARDING_LESSON),
        state: { prevPath: ON_BOARDING_LESSON, from: SIGN_IN_OPEN_FROM.DISCOVERY },
      },
    });
  }
  if (userDoesHaveUnit) {
    profileLinks.push({
      name: activeUnit ? 'התנתקות מיחידה' : 'התחברות ליחידה',
      profileOptionName: 'UnitConnection',
      callback: handleUnitToggle,
    });
  }

  if (canSeeFacilitator(role)) {
    profileLinks.push({
      name: 'מערכת מנחה',
      route: FACILITATOR_DASHBOARD,
      profileOptionName: 'Facilitator',
    });
  }
  if (canSeeGuide(role) && userDoesHaveUnit) {
    profileLinks.push({
      name: 'מערכת מדריך',
      route: `/guide/organization/${organizationId}`,
      profileOptionName: 'Guide',
    });
  }

  useEffect(() => {
    profileLinks.forEach((element, index) => {
      if (element.profileOptionName === profileOption) {
        setActiveIndex(index);
        return;
      }
    });
  }, [profileOption]);

  const loginProfileOnClick = (profileComponent, i) => {
    const { route, routeWithOptions } = profileComponent;
    setActiveIndex(i);
    dispatch(toggleHamburger());
    return history.push(routeWithOptions || route);
  };

  const navigateTo = (path) => {
    dispatch(toggleHamburger());
    return history.push({
      pathname: path,
      state: { from: location.pathname },
    });
  };

  const handleUserLogin = () => {
    if (isLoggedIn) {
      userLogoutEvent();
      dispatch(resetAppSlice());
      localStorage.clear();
      window.location.pathname = '/';
    } else {
      history.push({
        pathname: LOGIN,
        state: { prevPath: ROOT, from: SIGN_IN_OPEN_FROM.DISCOVERY },
      });
    }
  };

  const renderProfileOptions = () => {
    return (
      <>
        <S.Topic>
          <S.List>
            <S.ListItem>
              <S.Link
                aria-label="פרופיל"
                onClick={() => loginProfileOnClick(profileLinks[0].route, 0)}
              >
                <TitleLink imgAlt="פרופיל" icon={profileIcon}>
                  פרופיל
                </TitleLink>
              </S.Link>
            </S.ListItem>

            {profileLinks.map((link, index) => (
              <S.ListItem key={index}>
                <S.Link
                  onClick={() =>
                    profileLinks[index]?.callback
                      ? profileLinks[index].callback()
                      : loginProfileOnClick(profileLinks[index], index)
                  }
                >
                  {link.name}
                </S.Link>
              </S.ListItem>
            ))}
          </S.List>
        </S.Topic>
      </>
    );
  };

  const hamburgerHandleClick = () => {
    dispatch(toggleHamburger());
    return history.push(ROOT);
  };
  const toggleColors = () => {
    dispatch(toggleAccessibilityMode());
    document.documentElement.classList.toggle('accessibility');
  };
  const renderStudySubjects = () => {
    return (
      <S.Topic>
        <S.List>
          <S.ListItem>
            <S.Link aria-label="עולמות תוכן" onClick={() => hamburgerHandleClick()}>
              <TitleLink imgAlt="learning" icon={learningIcon}>
                עולמות תוכן
              </TitleLink>
            </S.Link>
          </S.ListItem>
          <HamburgerNavbar />
        </S.List>
      </S.Topic>
    );
  };

  return (
    <S.Container
      id="navigation-menu"
      ref={menuRef}
      isMobile={isMobile}
      aria-label="ניווט ראשי"
    >
      <S.TopSection>
        <S.MakesIcon src={makeLogo} alt="makes logo" />
        <S.CloseButton
          aria-label="סגירת תפריט ראשי"
          onClick={() => dispatch(toggleHamburger())}
        >
          <img src={closeIcon} alt="closeIcon" />
        </S.CloseButton>
      </S.TopSection>
      <S.SocialLinksList>
        <SocialLinks />
      </S.SocialLinksList>
      <S.ContentWrapper>
        {renderProfileOptions()}
        {renderStudySubjects()}
        <S.BottomContainer>
          <S.Link aria-label="מי אנחנו" onClick={() => navigateTo(ABOUT)}>
            <TitleLink imgAlt="מי אנחנו" icon={makesIcon}>
              מי אנחנו ?
            </TitleLink>
          </S.Link>
          <S.Link
            aria-label={`${isLoggedIn ? 'התנתקות' : 'התחברות'}`}
            onClick={() => handleUserLogin()}
          >
            <TitleLink
              imgAlt={`${isLoggedIn ? 'התנתקות' : 'התחברות'}`}
              icon={logoutIcon}
              isLoggedIn={isLoggedIn}
            >
              {isLoggedIn ? 'התנתקות' : 'התחברות'}
            </TitleLink>
          </S.Link>
        </S.BottomContainer>
        <S.AccesibilityLink
          aria-label="מעבר לדף הסדרי הנגישות"
          onClick={() => navigateTo(ACCESSIBILITY)}
        >
          הצהרת נגישות
        </S.AccesibilityLink>
        <WheelChair cb={toggleColors} isAccessbilityMode={isAccessbilityMode} />
      </S.ContentWrapper>
    </S.Container>
  );
};
export default Navigation;
