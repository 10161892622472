import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { ROOT } from 'routes/makes/constants';
import { ROLES } from './constants';

const SpaceRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.auth.user);
  const space = useSelector((state) => state.app.space);
  const isActiveUnit = useSelector((state) => state.auth.user?.lesson?.isActive);
  const userSpace = isActiveUnit && user?.lesson?.unitId.space?.spaceRoute;
  return (
    <Route
      {...rest}
      render={(props) => {
        // Access for CMS or SUPER_USER roles without any additional checks
        if (user.role === ROLES.CMS || user.role === ROLES.SUPER_USER) {
          return <Component {...props} />;
        }

        // USER role only has access to the 'main' space
        if (user.role === ROLES.USER && space === 'main') {
          return <Component {...props} />;
        }

        // STUDENT role access is restricted to their assigned space if it's active,
        // or fallback to 'main' if their specific space is not active
        if (user.role === ROLES.STUDENT || user.role === ROLES.GUIDE) {
          if (isActiveUnit && userSpace === space) {
            return <Component {...props} />;
          }
        }
        return <Redirect to={ROOT} />;
      }}
    />
  );
};

export default SpaceRoute;
