import { lazy } from 'react';
import {
  TOURNAMENT_LEADER_BOARD,
  LOGIN,
  INFO,
  PRIVACY,
  TERMS,
  ABOUT,
  PROFILE,
  DEFAULTQUERIES,
  ACCESSIBILITY,
  ON_BOARDING_TOURNAMENT,
  ON_BOARDING_LESSON,
} from './constants';
import DiscoveryPage from 'features/Makes/pages/DiscoveryPage/DiscoveryPage';
const OfficialPageContainer = lazy(() => import('features/Makes/pages/OfficialPage'));
const InfoPageContainer = lazy(() => import('features/Makes/pages/InfoPage'));
const TournamentLeaderBoard = lazy(() =>
  import('features/Makes/pages/TournamentLeaderBoardPage')
);
const PrivacyPage = lazy(() =>
  import('features/Makes/pages/PrivacyAndTermsPage/components/PrivacyPage')
);
const TermsPage = lazy(() =>
  import('features/Makes/pages/PrivacyAndTermsPage/components/TermsPage')
);
const AccessibilityPage = lazy(() =>
  import('features/Makes/pages/AccessibilityPage/AccessibilityPage')
);
const LoginPageContainer = lazy(() => import('components/auth'));
const ProfilePageContainer = lazy(() => import('features/Makes/pages/ProfilePage'));

const TournamentOnBoarding = lazy(() =>
  import(
    'features/Makes/pages/onBoarding/features/TournamentOnBoarding/TournamentOnBoarding'
  )
);
const LessonOnBoarding = lazy(() =>
  import('features/Makes/pages/onBoarding/features/LessonOnBoarding/LessonOnBoarding')
);
const ComponentPageContainer = lazy(() =>
  import('../../features/Makes/pages/ComponentPage/componentPageContainer')
);

const makeRoutes = [
  {
    path: [ON_BOARDING_LESSON, `/space/:spaceRoute${ON_BOARDING_LESSON}`],
    component: LessonOnBoarding,
    exact: true,
    private: true,
  },
  {
    path: [ON_BOARDING_TOURNAMENT, `/space/:spaceRoute${ON_BOARDING_TOURNAMENT}`],
    component: TournamentOnBoarding,
  },
  {
    //if its a quiz
    path: [
      '/space/:spaceRoute/track/:trackRoute/topic/:topicRoute/:makeRoute/quizMake/component/:componentId?',
      '/track/:trackRoute/topic/:topicRoute/:makeRoute/quizMake/component/:componentId?',
    ],
    component: ComponentPageContainer,
  },
  {
    path: [
      '/space/:spaceRoute/track/:trackRoute/topic/:topicRoute/:makeRoute/component/:componentId?',
      '/track/:trackRoute/topic/:topicRoute/:makeRoute/component/:componentId?',
    ],
    component: ComponentPageContainer,
  },
  {
    path: [
      '/',
      '/space/:spaceRoute',
      '/track/:trackRoute/',
      '/track/:trackRoute/topic/:topicRoute',
      '/space/:spaceRoute/track/:trackRoute/',
      '/space/:spaceRoute/track/:trackRoute/topic/:topicRoute',
    ],
    component: DiscoveryPage,
    exact: true,
  },
  {
    path: INFO,
    component: InfoPageContainer,
  },
  {
    path: PRIVACY,
    component: PrivacyPage,
  },
  {
    path: ACCESSIBILITY,
    component: AccessibilityPage,
  },
  {
    path: TERMS,
    component: TermsPage,
  },
  {
    path: ABOUT,
    component: OfficialPageContainer,
  },
  {
    path: LOGIN,
    component: LoginPageContainer,
  },
  {
    path: [`${PROFILE}/:profileOption`, `/space/:spaceRoute${PROFILE}/:profileOption`],
    component: ProfilePageContainer,
    exact: true,
    private: true,
  },
  {
    path: [
      TOURNAMENT_LEADER_BOARD,
      `/space/:spaceRoute${TOURNAMENT_LEADER_BOARD}`,
      `${TOURNAMENT_LEADER_BOARD}${DEFAULTQUERIES.leaderBoard}`,
      `/space/:spaceRoute${TOURNAMENT_LEADER_BOARD}${DEFAULTQUERIES.leaderBoard}`,
    ],
    component: TournamentLeaderBoard,
    private: true,
  },
];
export default makeRoutes;
