import styled from 'styled-components/macro';
import { primaryFont } from 'styles/typography.styles';
import { device } from 'utils/helpers/deviceScreen';
export const MakeListItem = styled.li`
  height: 100%;
  width: 100%;
  flex: 1;
  overflow: hidden;
  position: relative;
  :focus-within {
    padding: 0.5rem;
    height: 97.5%;
    width: 97.5%;
  }

  @media ${device.tablet} {
    flex: unset;
    height: unset;
    max-height: ${({ topSectionHeight }) =>
      `calc(100vh - ${parseInt(topSectionHeight, 10) + 120}px)`};
  }
`;

export const MakeBtn = styled.button`
  height: 100%;
  width: 100%;
  cursor: pointer;
`;
export const Article = styled.article`
  color: var(--color-light-charcoal);
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
`;

export const QuizButton = styled.button`
  font-family: ${primaryFont.bold};
  font-size: 1.6rem;
  text-align: center;
  font-weight: bold;
  position: absolute;
  padding: 0.25rem 1rem 0.25rem;
  height: 3rem;
  border-radius: 13.5px;
  display: flex;
  justify-content: center;
  z-index: 3;
  align-items: center;
  left: 5%;
  top: 5%;
  background-color: var(--color-white);
  color: ${({ color }) => color};
  align-self: flex-end;
  cursor: pointer;
  @media ${device.tablet} {
    top: 7.5%;
  }
`;

export const QuizContainer = styled.div`
  position: absolute;
  left: 0;
  z-index: 1;
`;
