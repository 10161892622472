import { API_VERSION } from 'apis/CONSTANTS';
import { api, getData } from 'apis/index';

import { GOOGLE_AUTH, CREATE_ANONYMOUS_USER, GET_ANONYMOUS_USER } from './constants';

export const googleAuth = (code) => {
  const body = { code };

  return api.post(`${API_VERSION}/${GOOGLE_AUTH}`, body).then(getData);
};
export const createAnonymousUser = () => {
  return api.post(`${API_VERSION}/${CREATE_ANONYMOUS_USER()}`).then(getData);
};
export const getAnonymousUser = () => {
  return api.get(`${API_VERSION}/${GET_ANONYMOUS_USER()}`).then(getData);
};
