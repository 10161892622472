import continueIcon from 'assets/icons/continue-status.svg';
import completedIcon from 'assets/icons/completed-status.svg';
import notStartedIcon from 'assets/icons/notStarted-status.svg';

const backgroundColors = {
  completed: 'var(--color-green)',
  'in progress': 'var(--color-orange)',
  default: 'var(--color-blue)',
};

const statusIcons = {
  completed: completedIcon,
  'in progress': continueIcon,
  default: notStartedIcon,
};

const statusValues = {
  completed: 'הסתיים',
  'in progress': 'התחיל',
  default: 'לא התחיל',
};

export const getBackgroundColor = (status) =>
  backgroundColors[status] || backgroundColors['default'];
export const getStatusIcon = (status) => statusIcons[status] || statusIcons['default'];
export const getStatusValue = (status) => statusValues[status] || statusValues['default'];
