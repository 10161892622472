import MakeSkeleton from 'components/SkeletonLoader/SkeletonDiscovery/MakeSkeleton/MakeSkeleton';

import * as S from './DesktopContent/DesktopContent.styles';
import useIsMobile from 'hooks/useIsMobile';

const SkeletonContent = () => {
  const isMobile = useIsMobile();

  const renderSkeletonCardsForDesktop = () => {
    return [...Array(6).keys()].map((num) => {
      return <MakeSkeleton key={num} />;
    });
  };
  if (isMobile)
    return (
      <>
        <MakeSkeleton />
      </>
    );
  if (!isMobile)
    return (
      <S.Main>
        <S.MakeList>{renderSkeletonCardsForDesktop()}</S.MakeList>
      </S.Main>
    );
};
export default SkeletonContent;
