export const UPDATE_TOPIC_INDEX = (trackId) => `topics/track/${trackId}/updateIndex`;
export const CREATE_TOPIC = (trackId) => `topics/track/${trackId}/topic/create`;
export const UPDATE_TOPIC = (topicId) => `topics/topic/${topicId}/update`;
export const DELETE_TOPIC = (trackId, topicId) =>
  `topics/track/${trackId}/topic/${topicId}/delete`;
export const UPLOAD_INFO_IMG = (makeId) => `makes/${makeId}/upload`;
export const GET_TOPICS_BY_TRACK_ID = (trackId, from, type) =>
  `topics/track/${trackId}?from=${from}&type=${type}`;
export const UNPUBLISH_TOPIC = (topicId) => `topics/topic/${topicId}/unPublish`;
export const PUBLISH_TOPIC = (topicId) => `topics/topic/${topicId}/publish`;
export const GET_TOPIC_BY_ID = (topicId) => `topics/topic/${topicId}`;
// router.put('/topic/:topicId/unPublish', topicController.unPublishTopic);
// router.put('/topic/:topicId/publish',  publishTopic);
