import * as S from './IconWithText.style';
const IconWithText = ({ icon, children, alt, ariaHidden = 'true' }) => {
  return (
    <S.Wrapper>
      <S.Icon aria-hidden={ariaHidden} src={icon} alt={alt} />
      <S.Value aria-hidden={ariaHidden}>{children}</S.Value>
    </S.Wrapper>
  );
};

export default IconWithText;
