import PopUpMessage from 'components/PopUpMessage/PopUpMessage';
import { createContext, useContext, useState } from 'react';
import * as Sentry from '@sentry/react';
import OOPS_MSG from 'components/OOPS_MSG/OOPS_MSG';
const ErrorContext = createContext();

const useErrorContext = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error('ErrorContext must be used within a CMSProvider');
  }
  return context;
};

const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  // Function to set the error
  const handleError = (error) => {
    Sentry.captureMessage(`${error?.from} - ` + error?.sentryError, error?.type);
    if (error?.type !== 'warning') setError(error);
  };
  // Function to clear the error
  const clearError = () => {
    setError(null);
  };

  const renderError = () => {
    // switch (error?.type) {
    //   case 'fatal':
    //     return <OOPS_MSG resetErrorCB={clearError} />;
    //   case 'error':
    //     return <PopUpMessage message={error.userError} clearErrorCB={clearError} />;
    //   default:
    //     return null;
    // }
  };
  const value = { handleError, clearError };
  return (
    <ErrorContext.Provider value={value}>
      {error && renderError()}
      {children}
    </ErrorContext.Provider>
  );
};
export { ErrorProvider, useErrorContext };
